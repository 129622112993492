import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoadingSpinner from '../../components/Loader';
import { LoadingScreen } from '../ProductAdmin/Profile';
import * as actions from './actions';
import { groupBy, sortBy } from 'lodash';
import VisitorStats from '../../components/VisitorStats';
import Pagination from '../../components/Pagination';
import Select from 'react-select';
import Immutable from 'seamless-immutable';
import moment from 'moment';
import { Typography } from '@mui/material';

const allOrgTypes = [
  {label: 'Schools', value: 'school'},
  {label: 'Universities', value: 'university'},
  {label: 'Nurseries', value: 'nursery'},
  {label: 'Governments', value: 'government'},
];

const VisitorStatsPage = ({ adminStats, dispatchGetVisitorStats, updatePending, product, location, isAdmin }) => {

  // const maxCompetitorCount = 10;
  // const competitors = product?.alternatives ? Immutable.asMutable(product.alternatives
  //     .map(p => ({label: p.name, value: p.slug}))
  //   ) : [];

  const fetchData = ({from, to}) => () => {
    dispatchGetVisitorStats({
      slug: location.pathname.includes(product?.slug) ? product.slug : '',
      from,
      to,
      // alternatives: competitors?.length ? competitors.map(c => c.value) : undefined,
    });
  }

  const buildMonthOptions = () => {
    const analyticsStartDate = '2022-03-01';
    const startOfFirstMonth = moment(analyticsStartDate).startOf('month');
    const endOfFirstMonth = moment(analyticsStartDate).endOf('month');
    const numberOfMonths = moment().diff(startOfFirstMonth, 'months');
    const monthOptions = [];

    for (let i=numberOfMonths; i >= 0; i--) {
      const prevMonthStart = startOfFirstMonth.clone().add(i, 'months');
      const prevMonthEnd = endOfFirstMonth.clone().add(i, 'months');
      
      monthOptions.push({
        label: prevMonthStart.format("MMMM YYYY"),
        value: prevMonthStart.format("MMMM YYYY"),
        from: prevMonthStart.format("YYYY-MM-DD"),
        to: prevMonthEnd.format("YYYY-MM-DD"),
      });
    }

    return monthOptions;
  }
  const monthOptions = buildMonthOptions();
  const currentMonthOption = monthOptions[0];

  const [countries, setCountries] = useState([]);
  const [orgTypes, setorgTypes] = useState([]);
  const [selectedOrgTypes, setselectedOrgTypes] = useState([]);
  const [selectedCountries, setselectedCountries] = useState([]);
  const [selectedMonth, setselectedMonth] = useState(currentMonthOption);
  // const [selectedCompetitors, setselectedCompetitors] = useState(competitors.slice(0,maxCompetitorCount));

  useEffect(() => {
    const {to, from} = currentMonthOption;
    fetchData({to, from})();
    // fetchData(selectedCompetitors);
    // setselectedCompetitors(selectedCompetitors);
  }, [product]);

  useEffect(() => {
    if (adminStats?.events?.data) {
      const countries = Immutable.asMutable(adminStats.events.data.reduce((countries, event) => {
        if (countries.find(country => country.value === event.country)) {
          return countries;
        }
        return [...countries, {label: event.country, value: event.country}];
      }, []));
      
      const orgTypes = allOrgTypes.filter(type => {
        return !!adminStats.events.data.find(event => event.org?.type === type.value);
      });
  
      setCountries(countries);
      setselectedCountries(sortBy(countries, ['label']));
      setorgTypes(orgTypes);
      setselectedOrgTypes(orgTypes);
    }
  }, [adminStats]);

  const handleDateChange = option => {
    setselectedMonth(option);
    const {from, to} = monthOptions.find(m => m.value === option.value);
    fetchData({from, to})();
  }

  if (!product?.isPro && !product?.buyerIntent) return null;
  if (!adminStats?.events?.data) return <LoadingSpinner />;

  const events = adminStats.events.data.filter(event => {
    return (
      (!selectedOrgTypes || selectedOrgTypes.find(type => event.org?.type === type.value)) &&
      (!selectedCountries || selectedCountries.find(country => event.country === country.value))
    );
  });

  const groupedEvents = groupBy(
    events,
    'user_id'
  );
  const userIds = Object.keys(groupedEvents);

  return (
    <StyledProductAdmin fluid>
      {updatePending && <LoadingScreen><LoadingSpinner /></LoadingScreen>}

      <Typography variant='h2'>Buyer Intent</Typography>
      <Typography>See the accounts actively researching you and your competitors. Try focusing your sales and marketing activities here to reach them before the competition.</Typography>

      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col lg={9}>
                  <h4 className="font-size-18 mb-3">Visitor Stats</h4>

                  <h6>Visitor Type</h6>
                  <Select
                    isMulti
                    options={orgTypes}
                    value={selectedOrgTypes}
                    onChange={options => setselectedOrgTypes(options || [])}
                    className="mb-3"
                  />

                  {/* <h6>Include Competitor Data <span className="font-weight-normal">(select up to {maxCompetitorCount})</span></h6>
                  <Select
                    isMulti
                    options={competitors}
                    value={selectedCompetitors}
                    onChange={options => setselectedCompetitors(options || [])}
                    className="mb-3"
                    isOptionDisabled={() => selectedCompetitors.length >= maxCompetitorCount}
                  /> */}

                  <h6>From</h6>
                  <Select
                    isMulti
                    options={countries}
                    value={selectedCountries}
                    onChange={options => setselectedCountries(options || [])}
                    className="mb-4"
                  />

                  <h6>Month</h6>
                  <Select
                    options={monthOptions}
                    value={selectedMonth}
                    onChange={handleDateChange}
                    className="mb-4"
                  />

                </Col>
              </Row>

              <Row className="mb-2">
                <Col lg={5}>Name</Col>
                <Col lg={2} className="d-none d-lg-block">Last Seen</Col>
                <Col lg={2} className="d-none d-lg-block text-right">Interactions</Col>
              </Row>

              {userIds.map(key => {
                return (
                  <VisitorStats
                    key={key}
                    events={groupedEvents[key]}
                    isAdmin={isAdmin}
                  />
                );
              })}

            </CardBody>
          </Card>

        </Col>
      </Row>

    </StyledProductAdmin>
  );
}

const StyledProductAdmin = styled.div`
  font-weight: normal;
  padding-top: 1rem;
`;

const mapStateToProps = state => {
  const { updatePending, adminStats, product, products } = state.Product;
  return { updatePending, adminStats, product, products };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dispatchGetVisitorStats: actions.getProductVisitorStatsAction,
    },
    dispatch,
  );

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
)(VisitorStatsPage);
