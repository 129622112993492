import React from 'react';
import { EditorState, Modifier } from 'draft-js';
import { Button } from 'reactstrap';

const Placeholders = ({ editorState, onChange }) => {

  const addPlaceholder = (placeholder) => {
    const contentState = Modifier.replaceText(
    editorState.getCurrentContent(),
    editorState.getSelection(),
    placeholder,
    editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  }

  const placeholderOptions = [
    {key: "firstname", value: "{{firstname}}", text: "First Name"},
    // {key: "lastname", value: "{{lastname}}", text: "Last Name"},
    {key: "productName", value: "{{productName}}", text: "Product Name"},
    {key: "organisationName", value: "{{organisationName}}", text: "Organisation Name"},
    {key: "institutionType", value: "{{institutionType}}", text: "Institution Type"},
    // {key: "email", value: "{{email}}", text: "Email"},
  ];

  return (
    <div style={{marginLeft: '0.25rem'}}>
      <h6 className="mt-1">Insert placeholder:</h6>
      {placeholderOptions.map(item => (
        <Button color="primary" outline style={{margin: '0 0.5rem 0.5rem 0'}} onClick={addPlaceholder.bind(this, item.value)} 
          key={item.key}
          className="rdw-dropdownoption-default placeholder-li"
        >{item.text}
        </Button>
      ))}
    </div>
  );
}

export default Placeholders;
