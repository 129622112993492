import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router';

import Layout from "./layout/reducer";
import Login from "./auth/login/reducer";
import App from "./app/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Product from '../pages/ProductAdmin/reducer';
import Reseller from '../pages/ResellerAdmin/reducer';
import Device from '../pages/DeviceAdmin/reducer';
import Brand from '../pages/BrandAdmin/reducer';
import Admin from '../pages/SiteAdmin/reducer';

const rootReducer = history => combineReducers({
  router: connectRouter(history),
  Layout,
  Login,
  Account,
  ForgetPassword,
  Product,
  Reseller,
  Device,
  Brand,
  Admin,
  App,
});

export default rootReducer;
