import { takeLatest, call, put } from "redux-saga/effects";
import { push } from 'react-router-redux'

import { toast } from "react-toastify";

import ApiService from "../../../services/apiService";

import * as actionTypes from "./constants"
import * as actions from "./actions"

export function* adjustLeadCredits(action) {
    try {
        const response = yield call([ApiService, 'newLeadCreditsTransaction'], action.payload)
        // API returns the newly created object. Update the history locally with the new object when dispatching the success action.
        yield put(actions.adjustLeadCreditsSuccess(response))
        // Since we're dealing with real money here, let's disable auto close.
        toast.success("Lead credits updated.", { autoClose: false })
        // Redirect to the history tab.
        yield put(push("#details--history"))
    } catch (error) {
        toast.error("An error occurred.")
        console.error(error)
    } finally {
        yield put(actions.adjustLeadCreditsComplete())
    }
}

export function* getLeadCreditsHistory(action) {
    try {
        const response = yield call([ApiService, 'transactions'], action.payload)
        yield put(actions.getLeadCreditsHistorySuccess(response))
    } catch(error) {
        toast.error("An error occurred.")
        console.error(error)
    } finally {
        yield put(actions.getLeadCreditsHistoryComplete())
    }
}

export default function* watch() {
    yield takeLatest(actionTypes.LEAD_CREDITS_ADJUST, adjustLeadCredits)
    yield takeLatest(actionTypes.LEAD_CREDITS_HISTORY, getLeadCreditsHistory)
}