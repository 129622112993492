import Immutable from 'seamless-immutable';
import * as actionTypes from './constants';

export const initialState = Immutable({
  brands: null,
  brandsPending: false,
  brand: null,
  brandPending: false,
  createPending: false,
});

function brandReducer(state = initialState, action) {
  const emptyBrand = {slug: 'add-listing', name: '+ Add Listing', plan: 'free'};

  switch (action.type) {
    case actionTypes.RESET_STATE:
      return initialState;

    case actionTypes.CONFIG_REQUEST_SUCCESS:
      return state
        .set('config', action.payload.config)
        .set('planConfig', action.payload.planConfig);


    case actionTypes.UPDATE_CONFIG:
      return state.set('updatePending', true);
    case actionTypes.UPDATE_CONFIG_SUCCESS:
      return state.set('config', action.payload);

    case actionTypes.UPDATE_CONFIG_COMPLETE:
      return state.set('updatePending', false);

    case actionTypes.UPDATE_BRAND:
      return state.set('updatePending', true);
    case actionTypes.COMPLETE_UPDATE_BRAND:
      return state.set('updatePending', false);

    case actionTypes.BRAND_REQUEST:
      return state.set('brandsPending', true);
    case actionTypes.BRAND_REQUEST_SUCCESS:
      return state.set('brands', [...action.payload, emptyBrand]);

    case actionTypes.BRAND_REQUEST_COMPLETE:
      return state.set('brandsPending', false);

    case actionTypes.CREATE_BRAND:
      return state.set('createPending', true);
    case actionTypes.CREATE_BRAND_SUCCESS:
      return state.set('brands', state.brands ? [...state.brands.slice(0, state.brands.length-1), action.brand, emptyBrand] : [action.brand, emptyBrand]);
    case actionTypes.CREATE_BRAND_COMPLETE:
      return state.set('createPending', false);
    
    case actionTypes.EXPORT_REVIEWS:
      return state.set('updatePending', true);
    case actionTypes.EXPORT_ALL_REVIEWS:
      return state.set('updatePending', true);
    case actionTypes.EXPORT_ALL_BRANDS:
      return state.set('updatePending', true);
    case actionTypes.EXPORT_COMPLETE:
      return state.set('updatePending', false);

    case actionTypes.INVITES_REQUEST:
      return state.set('invitesPending', true);
    case actionTypes.INVITES_REQUEST_SUCCESS:
      return state.set('invites', action.payload);
    case actionTypes.INVITES_REQUEST_COMPLETE:
      return state.set('invitesPending', false);

    case actionTypes.INVITES_UPLOAD:
      return state.set('invitesPending', true);
    case actionTypes.INVITES_UPLOAD_SUCCESS:
      return state.set('invites', action.payload);
    case actionTypes.INVITES_UPLOAD_COMPLETE:
      return state.set('invitesPending', false);
    
    case actionTypes.REVIEWS_REQUEST:
      return state.set('reviewsPending', true);
    case actionTypes.REVIEWS_REQUEST_SUCCESS:
      return state.set('reviews', action.payload);
    case actionTypes.REVIEWS_REQUEST_COMPLETE:
      return state.set('reviewsPending', false);

    case actionTypes.REVIEW_REPLY:
      return state.set('updatePending', true);
    case actionTypes.REVIEW_REPLY_SUCCESS:
      const reviewIndex = state.reviews.data.findIndex(r => r.TYPE === action.payload.TYPE);
      return state.setIn(['reviews', 'data', reviewIndex], action.payload);
    case actionTypes.REVIEW_REPLY_COMPLETE:
      return state.set('updatePending', false);

    case actionTypes.SELECT_BRAND:
      return state.set('brandPending', true);
    case actionTypes.SELECT_BRAND_SUCCESS:
      return state.set('brand', action.brand);
    case actionTypes.SELECT_BRAND_COMPLETE:
      return state.set('brandPending', false);
   
    default:
      return state;
  }
}

export default brandReducer;
