import React, { useEffect, useState } from "react";
import { Button, Progress } from "reactstrap";
import styled from "styled-components";
import { Container, Col, Row } from "reactstrap";
import checkBox from "../../assets/images/checkBox.svg";
import itemArrow from "../../assets/images/itemArrow.svg";
import { useHistory, Link } from "react-router-dom";
import { profileFields } from "./Profile";
import PremiumFeature from '../../components/PremiumFeature';
import { useMediaQuery } from "@mui/material";

export const listItemData = [
  {
    title: "Claim your profile",
    description: "Take control of your EdTech Impact profile.",
    completed: (product) => true,
    link: (product) => `/products/${product.slug}/profile`,
    show: (product) => true,
  },

  {
    title: "Complete your profile",
    description: "Complete all fields for maximum transparency.",
    completed: (product) => {
      for (const propertyDefinition of profileFields.filter(field => !field.isPremium || product?.isPremium)) {
        const fieldNotCompleted = product && typeof product[propertyDefinition.name] === 'undefined';
        if (fieldNotCompleted && propertyDefinition.required) {
          return false;
        }
      }
      return true;
    },
    link: (product) => `/products/${product.slug}/profile`,
    show: (product) => true,
  },

  {
    title: "Choose Impact Metrics",
    description: "Select up to 5 educational outcomes to measure.",
    completed: (product) => product?.outcomes?.length > 0,
    link: (product) => `/products/${product.slug}/impact-metrics`,
    show: (product) => true,
  },

  {
    title: "Send yourself a test invitation",
    description: "See what your customers will receive in their inbox.",
    completed: (product) => product?.invites?.sentTest,
    link: (product) => `/products/${product.slug}/reviews/test-invite`,
    show: (product) => product?.isPremium,
  },

  {
    title: "Collect manual reviews",
    description: "Email your personal reviews link to your customers.",
    completed: (product) => product?.numOfReviews > 0,
    link: (product) => `/products/${product.slug}/reviews/manual-collection`,
    show: (product) => true,
  },

  {
    title: "Reply to reviews",
    description: "Thank your customers and show you listen to their feedback.",
    completed: (product, reviews) => reviews?.filter((review) => !!review.reply).length > 0,
    link: (product) => `/products/${product.slug}/reviews/your-reviews`,
    show: (product) => true,
  },

  // {
  //   title: "Promote your reviews",
  //   description: "Add a widget to your website and showcase your reviews.",
  //   completed: (product) => false,
  //   link: (product) => `/products/${product.slug}/widgets`,
  // },

  {
    title: "Set up lead generation",
    description: "Choose the type of leads you want, and how you want to receive them.",
    completed: (product) => product?.numOfReviews > 0,
    link: (product) => `/products/${product.slug}/leads/preferences`,
    show: (product) => product?.isPro,
  },
];

export const requiredListItems = product => {
  return listItemData.filter(item => item.show(product));
};

export const notRequiredListItems = product => {
  return listItemData.filter(item => !item.show(product));
};

export const completedItemCount = (product, reviews) => {
  return requiredListItems(product)
    .filter(item => item.completed(product, reviews))
    .length;
};

export const isChecklistCompleted = (product, reviews) => {
  return completedItemCount(product, reviews) >= requiredListItems(product).length;
};

function GetStarted({ product, reviews }) {  
  const { name } = product;
  const hideText = useMediaQuery(theme => theme.breakpoints.down('md'))

  const history = useHistory();

  const clickHandler = (path,product) => {
    if (path) {
      history.push(path(product));
    }
  };

  return (
    <StyledGetStarted fluid className="pb-5">
      <Row className="align-items-center">
        <Col lg={7}>
          <div className="page-title-box">
            <h4 className="font-size-24">Onboarding Checklist</h4>
            <p>
             Make the most of EdTech Impact by following these steps.
            </p>
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={{ size: 8, offset: 0 }}>
          <div>
            <CheckListContainer>
              <div className="px-1">
                <div className="p-1 mt-3">
                  <p className="mt-2 ml-4 para">
                    You completed <b>{completedItemCount(product, reviews)}</b> out of{" "}
                    <b>{requiredListItems(product).length}</b> onboarding steps - Well done!
                  </p>

                  <ProgressBar
                    value={(completedItemCount(product, reviews) / requiredListItems(product).length) * 100}
                  >
                    <div className="box"></div>
                  </ProgressBar>
                </div>
              </div>

              {requiredListItems(product).map(
                ({ title, description, link, completed, show }) => {
                  return (
                    <ListItem
                      key={title}
                      className={`${
                        completed(product, reviews)
                          ? "checkedBackground"
                          : "uncheckedBackground"
                      } ${link(product) ? "cursor-pointer" : ""}`}

                      onClick={() => clickHandler(link,product)}
                    >
                      {!show(product)
                        ?
                          <ItemIcon className="checkBox">
                          </ItemIcon>
                        :
                        completed(product, reviews) ?
                          <ItemIcon className="checkBox">
                            <img width="16" src={checkBox} />
                          </ItemIcon>
                        :
                        <ItemIcon />
                      }

                      <ListText>
                        <div
                          className={`item_title ${
                            completed(product, reviews) ? "completed" : "uncompleted"
                          }`}
                        >
                          {title}
                        </div>
                        <div className="item_description">{description}</div>
                      </ListText>

                      {link(product) && (
                        <i className="item_arrow">
                          <img width="16" height="16" src={itemArrow} />
                        </i>
                      )}
                    </ListItem>
                  );
                }
              )}
            </CheckListContainer>

            {!product?.isPremium &&
              <CheckListContainer className="mt-4" style={{borderTop: 0}}>
                {notRequiredListItems(product).map(
                  ({ title, description }) => {
                    return (
                      <ListItem
                        key={title}
                        className="disabled"
                      >
                        <ItemIcon>
                          <i className="ti-lock"></i>
                        </ItemIcon>

                        <ListText>
                          <div className="item_title disabled">
                            {title}
                          </div>
                          <div className="item_description disabled">{description}</div>
                        </ListText>

                        <PremiumFeature additionalStyle={!hideText ? { minWidth: '120px' } : {}} hideText={hideText} />
                      </ListItem>
                    );
                  }
                )}
                
              </CheckListContainer>
            }

          </div>
        </Col>
      </Row>
    </StyledGetStarted>
  );
}

const StyledGetStarted = styled(Container)`
  position: relative;
  margin-bottom: 3rem;
`;

const CheckListHeader = styled.div`
  display: grid;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 24px;
  margin-left: 0px;
  row-gap: 8px;

  h2 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    margin: 0px;
  }
  span {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.3px;
    margin: 0px;
  }
`;

const CheckListContainer = styled.div`
  background: #fff;
  border: 1px solid #dcdce6;
  position: relative;
  z-index: 3;

  .para {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.3px;
    margin: 15px 0;
  }
`;

const ListItem = styled.div`
  border-top: 1px solid #dcdce6;
  color: #1b1b21;
  padding: 24px 32px;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &.cursor-pointer {
    cursor: pointer;
  }

  .item_arrow {
    margin-left: 1rem;
  }

  &.checkedBackground {
    background-color: #f9f9fa;
  }

  &.uncheckedBackground {
    &:hover {
      background-color: #f9f9fa;

    .item_title {
        color: #0482a2;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;

    a {
      font-size: 12px;
      margin-left: 12px;
    }

    i {
      color: #c5c5d6;
      font-size: 14px;
      line-height: 1.5;
    }

    .disabled {
      display: flex;
      opacity: 0.5;
    }
  } 
`;

const ListText = styled.div`
  width: 100%;

  .item_title {
    color: #111;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: 0.3px;

    &.completed {
      color: #6f6f87;
      text-decoration: line-through;
    }
  }

  .item_description {
    color: #6f6f87;

    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0.3px;
    margin: 0px;
  }
`;

const ItemIcon = styled.div`
  &.checkBox {
    background-color: #0482a2;
    border-color: #0482a2;
  }

  -webkit-box-pack: center;
  border: 1px solid #c5c5d6;
  border-radius: 50%;
  flex-shrink: 0;
  height: 24px;
  display: flex;
  align-self: center;
  justify-content: center;
  margin: 0 24px 0 0;
  width: 24px;
`;

export const ProgressBar = styled.div`
  background-color: #dcdcdc;
  border-radius: 10px;
  margin: 2px 20px 20px 20px;

  .box {
    background-color: #0482a2;
    border-radius: 10px;
    height: 10px;
    position: relative;
    animation: progress 0.7s ease-out 0s 1 forwards;
  }

  ${({value}) => value && `
    @keyframes progress {
      from {
        width: 0%;
      }
      to {
        width: ${value}%;
      }
    }
  `};
`;

export default GetStarted;
