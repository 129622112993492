import React, { useState, useEffect, Suspense, lazy } from 'react';
import {
  Container, Col, Card, CardBody,
} from 'reactstrap';
import { withRouter, Route, Redirect, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoadingSpinner from '../../components/Loader';
import * as actions from './actions';
import * as productActions from '../ProductAdmin/actions';
import * as deviceActions from '../DeviceAdmin/actions';
import Dashboard from '../Dashboard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReviewsIframe from './ReviewsIframe';
import servicesIcon3 from "../../assets/images/services-icon/01.png";
import useAuth from "../Authentication/useAuth";
import _ from 'lodash';
import { withTracking } from 'react-tracking';
import usePrevious from '../../helpers/usePrevious';

const InviteUpload = lazy(() => import('./InviteUpload'));
const Invitations = lazy(() => import('./Invitations'));
const InvitationPreview = lazy(() => import('./InvitationPreview'));
const Profile = lazy(() => import('./Profile'));
const Analytics = lazy(() => import('../ProductAdmin/Analytics'));
const ReviewCollection = lazy(() => import('./ReviewCollection'));
const ReviewCollectionAutomatic = lazy(() => import('./ReviewCollectionAutomatic'));
const ReviewExport = lazy(() => import('./ReviewExport'));
const Admin = lazy(() => import('./Admin'));
const AdminEmails = lazy(() => import('./AdminEmails'));
const Widgets = lazy(() => import('./Widgets'));
const Badges = lazy(() => import('../ProductAdmin/MarketingAssets/Badges'));
const Awards = lazy(() => import('../ProductAdmin/Awards'));
const Evidence = lazy(() => import('./Evidence'));
const ImpactMetrics = lazy(() => import('./ImpactMetrics'));
const Exposure = lazy(() => import('./Exposure'));
const EmailWidgets = lazy(() => import('../ProductAdmin/EmailWidgets'));
const SurveyPreview = lazy(() => import('./SurveyPreview'));
const Reviews = lazy(() => import('./Reviews'));
const Notifications = lazy(() => import('./Notifications'));
const UserManagement = lazy(() => import('../ProductAdmin/UserManagement'));
const ResellerDevices = lazy(() => import('./ResellerDevices'));
const Leads = lazy(() => import('./Leads'));
const LeadsPreferences = lazy(() => import('./LeadsPreferences'));
const LeadsPrices = lazy(() => import('./LeadsPrices'));
const LeadReplyTemplates = lazy(() => import('./LeadReplyTemplates'));
const PurchaseCredits = lazy(() => import('../ProductAdmin/PurchaseCredits'));

const ResellerAdmin = ({
  resellerPending,
  reviewsPending,
  invitesPending,
  leadsPending,
  resellers,
  reseller,
  match,
  dispatchConfigRequest,
  dispatchResellerRequest,
  dispatchInvitesRequest,
  dispatchSelectReseller,
  dispatchReviewsRequest,
  dispatchDeviceRequest,
  dispatchLeadsRequest,
  dispatchSubmit,
  tracking,
}) => {

  const { currentUser } = useAuth();
  const prevReseller = usePrevious(reseller);

  useEffect(() => {
    dispatchConfigRequest();
    dispatchDeviceRequest();
  
    if (!resellers) {
      dispatchResellerRequest();
    }
  }, []);

  useEffect(() => {
    const hasMatchedResellers = resellers?.filter(p => p.resellerMatch).length > 0;

    if (!hasMatchedResellers && (resellers || match.params.slug) && (!prevReseller || match.params.slug !== prevReseller.slug)) {
      const slug = match.params?.slug || reseller?.slug || resellers[0]?.slug;

      if (slug) {
        !resellerPending && dispatchSelectReseller(slug);

        if (slug !== 'add-listing') {
          !reviewsPending && dispatchReviewsRequest({slug});
          !invitesPending && dispatchInvitesRequest({slug});
          !leadsPending && dispatchLeadsRequest({slug});
        }
      }
    }
  }, [match.params.slug, reseller, resellers]);

  useEffect(() => {
    if (reseller && prevReseller?.slug === reseller.slug) {
      tracking.trackEvent({ event: 'update-reseller' });
    }
  }, [reseller]);

  if (!resellers || resellerPending) return <LoadingSpinner />;
    
  if (resellers && resellers.filter(p => p.resellerMatch).length > 0) {
    return (
      <Col md={8}>
        <Card className="mini-stat bg-primary text-white mt-4">
          <CardBody>
            <div className="mb-4">
              <div className="float-left mini-stat-img mr-4">
                <img src={servicesIcon3} alt="" />
              </div>
              <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                You have matching resellers
              </h5>
              <h4 className="font-weight-medium font-size-18">
                Your email address matches our records for {resellers.filter(p => p.resellerMatch).map(p => p.name).join(', ')}.
              </h4>
              <h4 className="font-weight-medium font-size-18">
                Please contact <a className="text-white" href="mailto:hello@edtechimpact.com">hello@edtechimpact.com</a> to be added as an administrator of this reseller listing.
              </h4>
            </div>
          </CardBody>
        </Card>
      </Col>
    )
  }

  if (resellers.length < 2 && !reseller) return <Redirect to="/resellers/add-listing/profile" />;
    
  if (match.params.slug && !resellers.find(p => p.slug === match.params.slug)) {
    return <Redirect to='/' />;
  }
    
  if (!match.params.slug && resellers.length === 2) return <Redirect to={`/resellers/${resellers[0].slug}/profile`} />;
  
  if (resellers.length > 1 && !reseller) return <Redirect to={`/resellers/${match.params.slug || resellers[0].slug}/profile`} />;

  return (
    <StyledResellerAdmin fluid>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
      />

      <Suspense fallback={<LoadingSpinner />}>
        <Switch>
          <AdminRoute
            path="/resellers/:slug/admin"
            exact
            render={() => <Admin isPremium={true} />}
          />
          <AdminRoute
            path="/resellers/:slug/admin-emails"
            exact
            render={() => <AdminEmails isPremium={true} />}
          />

          <Route
            path="/resellers/:slug/dashboard"
            exact
            render={() => <Dashboard isPremium={true} />}
          />
          <Route
            path="/resellers/:slug/analytics"
            exact
            render={() => <Analytics product={reseller} isPremium={true} />}
          />
          <Route
            path="/resellers/:slug/devices"
            exact
            render={() => <ResellerDevices reseller={reseller} isPremium={true} />}
          />
          <Route
            path="/resellers/:slug/reviews/manual-collection"
            exact
            render={() => <ReviewCollection isPremium={true} />}
          />
          <Route
            path="/resellers/:slug/reviews/automatic-collection"
            exact
            render={() => <ReviewCollectionAutomatic isPremium={true} />}
          />
          <Route
            path="/resellers/:slug/reviews/export"
            exact
            render={() => <ReviewExport isPremium={true} />}
          />
          <Route
            path="/resellers/:slug/reviews/survey"
            exact
            render={() => <SurveyPreview reseller={reseller} isPremium={true} />}
          />
          <Route
            path="/resellers/:slug/profile"
            exact
            render={() => <Profile isPremium={true} />}
          />
          <Route
            path="/resellers/:slug/impact-metrics"
            exact
            render={() => <ImpactMetrics isPremium={true} />}
          />
          <Route
            path="/resellers/:slug/exposure"
            exact
            render={() => <Exposure isPremium={true} />}
          />
          <Route
            path="/resellers/:slug/evidence"
            exact
            render={() => <Evidence />}
          />
          <Route
            path="/resellers/:slug/widgets"
            exact
            render={() => <Widgets reseller={reseller} isPremium={true} />}
          />
          <Route
            path="/resellers/:slug/reviews-page"
            exact
            render={() => <ReviewsIframe reseller={reseller} isPremium={true} />}
          />
          <Route
            path="/resellers/:slug/marketing-assets"
            exact
            render={() => <Badges product={reseller} isPremium={true} />}
          />
          <Route
            path="/resellers/:slug/awards"
            exact
            render={() => <Awards product={reseller} isPremium={true} />}
          />
          <Route
            path="/resellers/:slug/email-widgets"
            exact
            render={() => <EmailWidgets product={reseller} isPremium={true} />}
          />
          <Route
            path="/resellers/:slug/reviews/your-reviews"
            exact
            render={() => <Reviews isPremium={true} />}
          />
          <Route
            path="/resellers/:slug/reviews/invitations"
            exact
            render={() => <Invitations isPremium={true} />}
          />
          <Route
            path="/resellers/:slug/reviews/invite-email"
            exact
            render={() => <InvitationPreview isPremium={true} />}
          />
          <Route
            path="/resellers/:slug/reviews/upload-invites"
            exact
            render={() => <InviteUpload isPremium={true} />}
          />
          <Route
            path="/resellers/:slug/admin/notifications"
            exact
            render={() => <Notifications isPremium={true} />}
          />
          <Route
            path="/resellers/:slug/admin/users"
            exact
            render={() => <UserManagement isPremium={true} product={reseller} submit={dispatchSubmit} />}
          />
          <Route
            path="/resellers/:slug/leads"
            exact
            render={() => <Leads isAdmin={currentUser?.isAdmin} isPremium={true} />}
          />
          <Route
            path="/resellers/:slug/leads/preferences"
            exact
            render={() => <LeadsPreferences isPremium={true} />}
          />
          <Route
            path="/resellers/:slug/leads/prices"
            exact
            render={() => <LeadsPrices isPremium={true} />}
          />
          <Route
            path="/resellers/:slug/leads/templates"
            exact
            render={() => <LeadReplyTemplates isPremium={true} />}
          />
          <Route
            path="/resellers/:slug/leads/purchase"
            exact
            render={() => <PurchaseCredits isPremium={true} />}
          />
        </Switch>
      </Suspense>
    </StyledResellerAdmin>
  );
}


const AdminRoute = props => {
  const { currentUser } = useAuth();
  if (!currentUser || !currentUser.isAdmin) return null;
  return <Route {...props} />;
}

const StyledResellerAdmin = styled(Container)`
  position: relative;
  margin-bottom: 3rem;
`;

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dispatchResellerRequest: actions.resellerRequestAction,
      dispatchSelectReseller: actions.selectReseller,
      dispatchToggleFilter: actions.resellersToggleFilterAction,
      dispatchToggleSubject: actions.resellersToggleSubjectAction,
      dispatchToggleFeature: actions.resellersToggleFeatureAction,
      dispatchToggleType: actions.resellersToggleTypeAction,
      dispatchToggleCheckbox: actions.resellersToggleCheckboxAction,
      dispatchSubmit: actions.updateResellerAction,
      dispatchPlanSelect: actions.resellersSelectPlanAction,
      dispatchReviewChange: actions.reviewChange,
      dispatchDetailsChange: actions.widgetDetailsChange,
      dispatchResetState: actions.resetState,
      dispatchReviewsRequest: actions.reviewsRequestAction,
      dispatchConfigRequest: productActions.configRequestAction,
      dispatchDeviceRequest: deviceActions.deviceRequestAction,
      dispatchInvitesRequest: actions.invitesRequestAction,
      dispatchLeadsRequest: actions.leadsRequestAction,
    },
    dispatch,
  );

const mapStateToProps = state => {
  const { reseller, resellers, resellerPending, leadsPending, invitesPending, resellersPending, updatePending, reviewsPending } = state.Reseller;
  const { devices } = state.Device;
  return { devices, reseller, resellers, resellerPending, leadsPending, invitesPending, resellersPending, updatePending, reviewsPending };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
  withTracking(),
)(ResellerAdmin);
