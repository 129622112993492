import { useState } from 'react';

import { CopyToClipboard } from "react-copy-to-clipboard";
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import js from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import a11yLight from 'react-syntax-highlighter/dist/esm/styles/hljs/a11y-light';
import shadesofpurple from 'react-syntax-highlighter/dist/esm/styles/hljs/tomorrow-night-blue';
import { withTracking } from 'react-tracking';

import { Button as MuiButton, Stack } from "@mui/material";
import styled from 'styled-components';

SyntaxHighlighter.registerLanguage('javascript', js);

const EISyntaxHighlighter = ({ text, light, disabled, tracking, additionalButtons }) => {
  const [isCopied, setIsCopied] = useState(false);

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
    tracking.trackEvent({ event: 'copied-code' });
  };

  return (
    <StyledSyntaxHighlighter disabled={disabled}>
      <SyntaxHighlighter language="javascript" style={light ? a11yLight : shadesofpurple}>
        {text}
      </SyntaxHighlighter>

      {!disabled &&
        <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={2} mt={-2} mb={4} fontSize="0.875rem">
          {additionalButtons && additionalButtons.length ? additionalButtons : null}
          <CopyToClipboard text={text} onCopy={onCopyText}>
            <MuiButton color={isCopied ? "success" : "primary"} variant="contained">{isCopied ? 'Copied!' : 'Copy'}</MuiButton>
          </CopyToClipboard>
        </Stack>
      }
    </StyledSyntaxHighlighter>
  );
}

const StyledSyntaxHighlighter = styled.div`
  ${({ disabled }) => disabled && `
    code {
      filter: blur(3px);
    }
  `}
`;

export default withTracking()(EISyntaxHighlighter);
