const Snackbar = (theme, skin) => {
  return {
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          ...(skin === 'bordered' && { boxShadow: 'none' }),
          backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[800],
          border: `1px solid ${theme.palette.primary.light}`
        }
      }
    }
  }
}

export default Snackbar
