import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";

import { Authenticator } from 'aws-amplify-react';
import { withTracking } from 'react-tracking';

import ThemeComponent from "@core/theme/ThemeComponent";

import { authHost } from '../pages/Authentication/Login';
import useAuth from "../pages/Authentication/useAuth";
import { SettingsConsumer, SettingsProvider } from "@core/context/settingsContext";
import { ProductFruits } from 'react-product-fruits';
import { compose } from "redux";
import { connect } from "react-redux";
import moment from 'moment';

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  render,
  basicSignUp,
  location,
  tracking,
  product,
  ...rest
}) => {
  const [authState, setAuthState] = useState('');
  const [loggedSignIn, setLoggedSignIn] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const { supabaseUser, currentUser, supabase } = useAuth()

  useEffect(() => {
    console.log({ authState })
    if (authState === "signIn") {
      const callbackUrl = (window.location.href.includes('/logout') || window.location.href.includes('/login')) ? window.location.origin : window.location.href;
      window.location.href = encodeURI(`${authHost}/auth/login/?callbackUrl=${callbackUrl}`);
    } else if (authState === "signUp" && location?.state?.basicSignUp) {
      window.location.href = encodeURI(`${authHost}/auth/register/?callbackUrl=${window.location.origin}`);
    } else if (authState === "signUp") {
      window.location.href = encodeURI(`${authHost}/auth/register/?callbackUrl=${window.location.origin}`);
    }

    const logSignIn = async () => {
      const excludedPaths = ['/', '/login', '/logout'];

      if (authState === 'signedIn' && !loggedSignIn && isAuthProtected && !excludedPaths.includes(location.pathname)) {
        tracking.trackEvent({
          event: 'login',
          isAdmin: currentUser?.isAdmin,
          user: currentUser?.attributes?.email,
        });
        setLoggedSignIn(true);
      }
    }
    logSignIn();
  }, [authState]);

  useEffect(() => {
    if (supabaseUser) {
      setUserInfo({
        username: supabaseUser.id,
        email: supabaseUser.email,
        firstname: supabaseUser.first_name,
        lastname: supabaseUser.last_name,
        signUpAt: moment(supabaseUser.created_at).format('yyyy-MM-DDTHH:mm:ss'),
        props: {
          productName: product?.name,
          slug: product?.slug,
          plan: product?.plan,
        }

      })
    }
  }, [supabaseUser, product])

  const handleAuthStateChange = state => {
    setAuthState(state);
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        window.scrollTo(0, 0);

        if (isAuthProtected) {
          return (
            <SettingsProvider>
              <SettingsConsumer>
                {({ settings }) => {
                  return (
                    <ThemeComponent settings={settings}>
                      <Authenticator
                        authState={location.state && location.state.authState}
                        hideDefault
                        onStateChange={handleAuthStateChange}
                      >
                        {(authState === "signedIn" && userInfo && supabase) ? (
                          <>
                            <ProductFruits
                              workspaceCode="YhCTF7zC7vBfqgOm"
                              language="en"
                              user={userInfo}
                              debug
                            />
                            <Layout>{render ? render() : <Component {...props} />} </Layout>
                          </>
                        ) : (
                          <></>
                        )}
                      </Authenticator>
                    </ThemeComponent>
                  )
                }}
              </SettingsConsumer>
            </SettingsProvider>
          );
        }

        return (
          <SettingsProvider>
            <SettingsConsumer>
              {({ settings }) => {
                return (
                  <ThemeComponent settings={settings}>
                    <Authenticator hideDefault onStateChange={handleAuthStateChange}>
                      <Component {...props} />
                    </Authenticator>
                  </ThemeComponent>
                )
              }}
            </SettingsConsumer>
          </SettingsProvider>
        );
      }}
    />
  );
};

const mapStateToProps = state => {
  const { product } = state.Product;
  return { product };
};

export default compose(
  connect(
    mapStateToProps,
  ),
  withTracking(),
)(AppRoute);
