import { STORE_PRESELECTED_SUBSCRIPTION, STORE_PRESELECTED_COUPON_CODE, STORE_ANALYTICS_ID  } from './actionTypes';

export const storePreselectedSubscriptionAction = (subscription) => {
    return {
        type: STORE_PRESELECTED_SUBSCRIPTION,
        payload: subscription,
    }
}

export const storePreselectedCouponCodeAction = (couponCode) => {
  return {
      type: STORE_PRESELECTED_COUPON_CODE,
      payload: couponCode,
  }
}

export const storeAnalyticsIdAction = (id) => {
  return {
      type: STORE_ANALYTICS_ID,
      payload: id,
  }
}

