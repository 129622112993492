// @flow
import {
  STORE_PRESELECTED_SUBSCRIPTION,
  STORE_PRESELECTED_COUPON_CODE,
  STORE_ANALYTICS_ID,
} from "./actionTypes";

const INIT_STATE = {
  preselectedSubscription: null,
  preselectedCouponCode: null,
  analyticsId: null,
};

const AppReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case STORE_PRESELECTED_SUBSCRIPTION:
      return {
        ...state,
        preselectedSubscription: action.payload,
      };
    
    case STORE_PRESELECTED_COUPON_CODE:
      return {
        ...state,
        preselectedCouponCode: action.payload,
      };

    case STORE_ANALYTICS_ID:
      return {
        ...state,
        analyticsId: action.payload,
      };
      
    default:
      return state;
  }
};

export default AppReducer;
