import React from 'react';
import styled from 'styled-components';
import nextIcon from '../../assets/images/next-icon.svg';

const Pagination = ({
  hasPreviousPage,
  hasNextPage,
  currentPage,
  totalPages,
  handleClick,
  className,
}) => {

  currentPage = parseInt(currentPage);
  totalPages = parseInt(totalPages);
  
  return (
    <StyledPagination className={className}>
      <PaginationArrow
        onClick={handleClick(currentPage - 1)}
        hide={!hasPreviousPage}
        src={nextIcon}
        alt="Previous"
      />
      {Array.from({ length: totalPages }, (v, k) => k + 1).map(page => {
        const active = page === currentPage;
  
        if (
          active ||
          page === 1 ||
          page === totalPages ||
          (page < currentPage + 4 &&
            (page > currentPage || page > totalPages - 4))
        ) {
          return (
            <React.Fragment key={page}>
              <PageCounter
                onClick={active ? undefined : handleClick(page)}
                active={active}
              >
                {page}
              </PageCounter>
              {page !== totalPages &&
              ((page === 1 && currentPage > 2) || page === currentPage + 3) ? (
                <Divider>/</Divider>
              ) : null}
            </React.Fragment>
          );
        }
        return null;
      })}
      <PaginationArrow
        onClick={handleClick(currentPage + 1)}
        hide={!hasNextPage}
        src={nextIcon}
        alt="Next"
      />
    </StyledPagination>
  );
}

const StyledPagination = styled.div`
  margin-bottom: 2rem;
  text-align: center;
`;

const PaginationArrow = styled.img`
  cursor: pointer;
  height: 2rem;
  width: 2rem;

  &:first-child {
    margin-right: 0.5rem;
    transform: rotate(180deg);
  }

  &:last-child {
    margin-left: 0.5rem;
  }

  ${({ hide }) => hide && `visibility: hidden;`};
`;

const PageCounter = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background: ${({ active, theme }) =>
    active ? '#0482a2' : 'transparent'};
  color: ${({ active, theme }) =>
    active ? '#fff' : '#0482a2'};
  margin: 0 0.25rem;
  ${({ active }) => !active && `cursor: pointer;`};
`;

const Divider = styled.div`
  color: ${({ theme }) => '#0482a2'};
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  width: 2rem;
`;

export default Pagination;
