import * as actionTypes from './constants';

export function updateConfigAction(config) {
  return {
    type: actionTypes.UPDATE_CONFIG,
    payload: config,
  };
}

export function updateConfigSuccessAction(config) {
  return {
    type: actionTypes.UPDATE_CONFIG_SUCCESS,
    payload: config,
  };
}

export function updateConfigCompleteAction() {
  return {
    type: actionTypes.UPDATE_CONFIG_COMPLETE,
  };
}

export function deviceRequestAction(deviceName) {
  return {
    type: actionTypes.DEVICE_REQUEST,
    payload: deviceName,
  };
}

export function deviceRequestCompleteAction() {
  return {
    type: actionTypes.DEVICE_REQUEST_COMPLETE,
  };
}

export function deviceRequestSuccessAction(device) {
  return {
    type: actionTypes.DEVICE_REQUEST_SUCCESS,
    payload: device,
  };
}

export function invitesRequestAction(payload) {
  return {
    type: actionTypes.INVITES_REQUEST,
    payload,
  };
}

export function invitesRequestCompleteAction() {
  return {
    type: actionTypes.INVITES_REQUEST_COMPLETE,
  };
}

export function invitesRequestSuccessAction(invites) {
  return {
    type: actionTypes.INVITES_REQUEST_SUCCESS,
    payload: invites,
  };
}

export function invitesUploadAction(payload) {
  return {
    type: actionTypes.INVITES_UPLOAD,
    payload,
  };
}

export function invitesUploadSuccessAction(payload) {
  return {
    type: actionTypes.INVITES_UPLOAD_SUCCESS,
    payload,
  };
}

export function invitesUploadCompleteAction() {
  return {
    type: actionTypes.INVITES_UPLOAD_COMPLETE,
  };
}

export function reviewsRequestAction(payload) {
  return {
    type: actionTypes.REVIEWS_REQUEST,
    payload,
  };
}

export function reviewsRequestCompleteAction() {
  return {
    type: actionTypes.REVIEWS_REQUEST_COMPLETE,
  };
}

export function reviewsRequestSuccessAction(evidence) {
  return {
    type: actionTypes.REVIEWS_REQUEST_SUCCESS,
    payload: evidence,
  };
}

export function reviewReplyAction(payload) {
  return {
    type: actionTypes.REVIEW_REPLY,
    payload,
  };
}

export function reviewReplyCompleteAction() {
  return {
    type: actionTypes.REVIEW_REPLY_COMPLETE,
  };
}

export function reviewReplySuccessAction(review) {
  return {
    type: actionTypes.REVIEW_REPLY_SUCCESS,
    payload: review,
  };
}

export function schoolSizeChange(payload) {
  return {
    type: actionTypes.SCHOOL_SIZE_CHANGE,
    payload,
  };
}

export function schoolTypeChange(payload) {
  return {
    type: actionTypes.SCHOOL_TYPE_CHANGE,
    payload,
  };
}

export function devicesToggleFilterAction(payload) {
  return {
    type: actionTypes.DEVICES_TOGGLE_FILTER,
    payload,
  };
}

export function devicesToggleSubjectAction(payload) {
  return {
    type: actionTypes.DEVICES_TOGGLE_SUBJECT,
    payload,
  };
}

export function devicesToggleFeatureAction(payload) {
  return {
    type: actionTypes.DEVICES_TOGGLE_FEATURE,
    payload,
  };
}

export function devicesToggleTypeAction(payload) {
  return {
    type: actionTypes.DEVICES_TOGGLE_TYPE,
    payload,
  };
}

export function devicesToggleCheckboxAction(payload) {
  return {
    type: actionTypes.DEVICES_TOGGLE_CHECKBOX,
    payload,
  };
}

export function devicesSelectPlanAction(payload) {
  return {
    type: actionTypes.DEVICES_SELECT_PLAN,
    payload,
  };
}

export function selectDevice(slug) {
  return {
    type: actionTypes.SELECT_DEVICE,
    slug,
  };
}

export function resetState() {
  return {
    type: actionTypes.RESET_STATE,
  };
}

export function selectDeviceSuccess(device) {
  return {
    type: actionTypes.SELECT_DEVICE_SUCCESS,
    device,
  };
}

export function selectDeviceComplete() {
  return {
    type: actionTypes.SELECT_DEVICE_COMPLETE,
  };
}

export function updateDeviceAction({device, screenshots, logo}) {
  return {
    type: actionTypes.UPDATE_DEVICE,
    device,
    screenshots,
    logo,
  };
}

export function completeUpdateDevice(device) {
  return {
    type: actionTypes.COMPLETE_UPDATE_DEVICE,
    device,
  };
}

export function createDeviceAction({device, screenshots, logo}) {
  return {
    type: actionTypes.CREATE_DEVICE,
    device,
    screenshots,
    logo,
  };
}

export function createDeviceSuccessAction({device}) {
  return {
    type: actionTypes.CREATE_DEVICE_SUCCESS,
    device,
  };
}

export function createDeviceCompleteAction(device) {
  return {
    type: actionTypes.CREATE_DEVICE_COMPLETE,
    device,
  };
}

export function devicesEmailsChangeAction(emails) {
  return {
    type: actionTypes.EMAILS_CHANGE,
    emails,
  };
}

export function reviewChange(reviewPreference) {
  return {
    type: actionTypes.REVIEW_CHANGE,
    reviewPreference,
  };
}

export function widgetDetailsChange(detailsPreference) {
  return {
    type: actionTypes.WIDGET_DETAILS_CHANGE,
    detailsPreference,
  };
}

export function exportReviews() {
  return {
    type: actionTypes.EXPORT_REVIEWS,
  };
}

export function exportAllReviews() {
  return {
    type: actionTypes.EXPORT_ALL_REVIEWS,
  };
}

export function exportAllDevices() {
  return {
    type: actionTypes.EXPORT_ALL_DEVICES,
  };
}

export function exportComplete() {
  return {
    type: actionTypes.EXPORT_COMPLETE,
  };
}

export function uploadLogo(payload) {
  return {
    type: actionTypes.UPLOAD_LOGO,
    payload,
  };
}

export function uploadScreenshot(payload) {
  return {
    type: actionTypes.UPLOAD_SCREENSHOT,
    payload,
  };
}

export function uploadEvidence(payload) {
  return {
    type: actionTypes.UPLOAD_EVIDENCE,
    payload,
  };
}

export function deleteEvidence(payload) {
  return {
    type: actionTypes.DELETE_EVIDENCE,
    payload,
  };
}

export function configRequestAction() {
  return {
    type: actionTypes.CONFIG_REQUEST,
  };
}

export function configRequestSuccessAction(config) {
  return {
    type: actionTypes.CONFIG_REQUEST_SUCCESS,
    payload: config,
  };
}

export function configRequestCompleteAction() {
  return {
    type: actionTypes.CONFIG_REQUEST_COMPLETE,
  };
}
