import { all } from 'redux-saga/effects';

//public
import AccountSaga from './auth/register/saga';
import AuthSaga from './auth/login/saga';
import ForgetSaga from './auth/forgetpwd/saga';
import LayoutSaga from './layout/saga';
import ProductSaga from '../pages/ProductAdmin/saga';
import ResellerSaga from '../pages/ResellerAdmin/saga';
import DeviceSaga from '../pages/DeviceAdmin/saga';
import BrandSaga from '../pages/BrandAdmin/saga';
import AdminSaga from '../pages/SiteAdmin/saga';
import BillingSaga from '../pages/ProductAdmin/BillingPage/saga'

export default function* rootSaga() {
    yield all([
        AccountSaga(),
        AuthSaga(),
        ForgetSaga(),
        LayoutSaga(),
        ProductSaga(),
        ResellerSaga(),
        DeviceSaga(),
        BrandSaga(),
        AdminSaga(),
        BillingSaga(),
    ])
}
