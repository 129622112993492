import ApiService from '../services/apiService';

export const camelCaseToWords = string =>
  string
    .replace(/([A-Z]+)/g, ' $1')
    .replace(/([A-Z][a-z])/g, ' $1')
    .toLowerCase();

export const base64URLToFormData = async base64URL => {
  const result = await fetch(base64URL);
  return result.blob();
};

export const URLToFormData = async url => {
  const result = await fetch(url, { mode: 'cors' });
  return result.blob();
};

export const videoToString = video => {
  if (video.provider === 'youtube') {
    return `https://www.youtube.com/embed/${video.id}?rel=0`;
  }

  if (video.provider === 'vimeo') {
    return `https://player.vimeo.com/video/${video.id}?title=0&byline=0&portrait=0`;
  };
}

export const uploadImage = async (image) => {
  try {
    const form = new FormData();
    form.append('file', image);
    const result = await ApiService.postImage({form});
    return result.url;
  } catch (err) {
    throw err;
  } finally {
    // yield put(actions.createProductCompleteAction());
  }
}

export const getArticle = (word) => {
  // Convert the word to lowercase for consistency
  const lowerCaseWord = word.toLowerCase();

  // List of vowels
  const vowels = ['a', 'e', 'i', 'o', 'u'];

  // Special cases for words that start with a vowel sound but not a vowel letter
  const vowelSoundCases = ['honest', 'hour', 'heir', 'herb', 'honor', 'honorable', 'honesty'];

  // Special cases for words that start with a consonant sound but a vowel letter
  const consonantSoundCases = ['unicorn', 'universe', 'unique', 'european', 'one', 'union', 'unilateral', 'useful', 'user', 'ubiquitous', 'university'];

  // Check if the word is in the special cases
  if (vowelSoundCases.includes(lowerCaseWord)) {
    return `An`;
  }
  if (consonantSoundCases.includes(lowerCaseWord)) {
    return `A`;
  }

  // Check if the first letter is a vowel
  if (vowels.includes(lowerCaseWord[0])) {
    return `An`;
  } else {
    return `A`;
  }
}