/**
 * On the billing page, super admins can alter lead credits by providing an amount and a note.
 */
export const LEAD_CREDITS_ADJUST = 'app/ProductAdmin/BillingPage/LEAD_CREDITS_ADJUST_REQUEST'
export const LEAD_CREDITS_ADJUST_SUCCESS = 'app/ProductAdmin/BillingPage/LEAD_CREDITS_ADJUST_REQUEST_SUCCESS'
export const LEAD_CREDITS_ADJUST_COMPLETE = 'app/ProductAdmin/BillingPage/LEAD_CREDITS_ADJUST_REQUEST_COMPLETE'

/**
 * On the billing page, we have a history of lead credit transactions for a product.
 */
export const LEAD_CREDITS_HISTORY = 'app/ProductAdmin/BillingPage/LEAD_CREDITS_HISTORY_REQUEST'
export const LEAD_CREDITS_HISTORY_SUCCESS = 'app/ProductAdmin/BillingPage/LEAD_CREDITS_HISTORY_REQUEST_SUCCESS'
export const LEAD_CREDITS_HISTORY_COMPLETE = 'app/ProductAdmin/BillingPage/LEAD_CREDITS_HISTORY_REQUEST_COMPLETE'