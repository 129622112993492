const breakpoints = () => ({
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1440, // typically QHD/2k
    xxxl: 1692,
    xxxxl: 2160, // typically UHD/4k
  }
})

export default breakpoints
