import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { store, history, persistor } from './store';
import Analytics from './Analytics';
import GA4React from "ga-4-react";
import { PersistGate } from 'redux-persist/integration/react'

const ga4 = new GA4React('G-95R5GQVYY7');

const Root = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <ConnectedRouter history={history}>
          <Analytics>
            <App />
          </Analytics>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
} 

(async () => {
  try {
    await ga4.initialize();
  } catch(error) {
    console.error(error)
  }

  ReactDOM.render(<Root />, document.getElementById('root'));
  serviceWorker.unregister();
})();
