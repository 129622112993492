import React from "react";
import { Redirect } from "react-router-dom";

// Pages Component

// Authentication related pages
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import {RegisterRedirect, RegisterProductRedirect} from "../pages/Authentication/RegisterRedirect";
import Onboarding from "../pages/ProductAdmin/Onboarding";
import ProductAdmin from "../pages/ProductAdmin";
import ResellerAdmin from "../pages/ResellerAdmin";
import DeviceAdmin from "../pages/DeviceAdmin";
import BrandAdmin from "../pages/BrandAdmin";
import Admin from "../pages/ProductAdmin/AdminIndex";
// Extra Pages
import Pages404 from "../pages/ExtraPages/Pages404";
import Pages500 from "../pages/ExtraPages/Pages500";
import PagesMaintenance from "../pages/ExtraPages/PagesMaintenance";
import Registration from "../pages/Authentication/Registration";

const adminRoutes = [
  { path: "/admin/:section?/:subsection?", component: Admin },
];

const authProtectedRoutes = [
  { path: "/logout", component: Logout },
  { path: "/onboarding", component: Onboarding },
  { path: "/products/:slug?/:section?/:subsection?", component: ProductAdmin },
  {
    path: "/resellers/:slug?/:section?/:subsection?",
    component: ResellerAdmin,
  },
  { path: "/devices/:slug?/:section?/:subsection?", component: DeviceAdmin },
  { path: "/brands/:slug?/:section?/:subsection?", component: BrandAdmin },
  { path: "/", exact: true, component: () => <Redirect to="/products" /> },
];

const publicRoutes = [
  { path: "/register-product", component: RegisterProductRedirect },
  { path: "/free-trial", component: Registration },
  { path: "/register", component: RegisterRedirect },
  { path: "/forgot-password/:email?/:code?", component: ForgetPwd },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
  { path: "/pages-Maintenance", component: PagesMaintenance },
];

export { authProtectedRoutes, publicRoutes, adminRoutes };
