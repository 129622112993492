import Immutable from 'seamless-immutable';
import * as actionTypes from './constants';

export const initialState = Immutable({
  devices: null,
  devicesPending: false,
  device: null,
  devicePending: false,
  createPending: false,
});

function deviceReducer(state = initialState, action) {
  const emptyDevice = {slug: 'add-listing', name: '+ Add Listing', plan: 'free'};

  switch (action.type) {
    case actionTypes.RESET_STATE:
      return initialState;

    case actionTypes.CONFIG_REQUEST_SUCCESS:
      return state
        .set('config', action.payload.config)
        .set('planConfig', action.payload.planConfig);


    case actionTypes.UPDATE_CONFIG:
      return state.set('updatePending', true);
    case actionTypes.UPDATE_CONFIG_SUCCESS:
      return state.set('config', action.payload);

    case actionTypes.UPDATE_CONFIG_COMPLETE:
      return state.set('updatePending', false);

    case actionTypes.UPDATE_DEVICE:
      return state.set('updatePending', true);
    case actionTypes.COMPLETE_UPDATE_DEVICE:
      return state.set('updatePending', false);

    case actionTypes.UPLOAD_EVIDENCE:
      return state.set('updatePending', true);

    case actionTypes.DELETE_EVIDENCE:
      return state.set('updatePending', true);

    case actionTypes.DEVICE_REQUEST:
      return state.set('devicesPending', true);
    case actionTypes.DEVICE_REQUEST_SUCCESS:
      return state.set('devices', [...action.payload.filter(d => d.ownItem !== false), emptyDevice]);

    case actionTypes.DEVICE_REQUEST_COMPLETE:
      return state.set('devicesPending', false);

    case actionTypes.CREATE_DEVICE:
      return state.set('createPending', true);
    case actionTypes.CREATE_DEVICE_SUCCESS:
      return state.set('devices', state.devices ? [...state.devices.slice(0, state.devices.length-1), action.device, emptyDevice] : [action.device, emptyDevice]);
    case actionTypes.CREATE_DEVICE_COMPLETE:
      return state.set('createPending', false);
    
    case actionTypes.EXPORT_REVIEWS:
      return state.set('updatePending', true);
    case actionTypes.EXPORT_ALL_REVIEWS:
      return state.set('updatePending', true);
    case actionTypes.EXPORT_ALL_DEVICES:
      return state.set('updatePending', true);
    case actionTypes.EXPORT_COMPLETE:
      return state.set('updatePending', false);

    case actionTypes.INVITES_REQUEST:
      return state.set('invitesPending', true);
    case actionTypes.INVITES_REQUEST_SUCCESS:
      return state.set('invites', action.payload);
    case actionTypes.INVITES_REQUEST_COMPLETE:
      return state.set('invitesPending', false);

    case actionTypes.INVITES_UPLOAD:
      return state.set('invitesPending', true);
    case actionTypes.INVITES_UPLOAD_SUCCESS:
      return state.set('invites', action.payload);
    case actionTypes.INVITES_UPLOAD_COMPLETE:
      return state.set('invitesPending', false);
    
    case actionTypes.REVIEWS_REQUEST:
      return state.set('reviewsPending', true);
    case actionTypes.REVIEWS_REQUEST_SUCCESS:
      return state.set('reviews', action.payload);
    case actionTypes.REVIEWS_REQUEST_COMPLETE:
      return state.set('reviewsPending', false);

    case actionTypes.REVIEW_REPLY:
      return state.set('updatePending', true);
    case actionTypes.REVIEW_REPLY_SUCCESS:
      const reviewIndex = state.reviews.data.findIndex(r => r.TYPE === action.payload.TYPE);
      return state.setIn(['reviews', 'data', reviewIndex], action.payload);
    case actionTypes.REVIEW_REPLY_COMPLETE:
      return state.set('updatePending', false);

    case actionTypes.SELECT_DEVICE:
      return state.set('devicePending', true);
    case actionTypes.SELECT_DEVICE_SUCCESS:
      return state.set('device', action.device);
    case actionTypes.SELECT_DEVICE_COMPLETE:
      return state.set('devicePending', false);
   
    default:
      return state;
  }
}

export default deviceReducer;
