/**
 * @param {number|string} supabaseId
 * @returns {boolean}
 */
const isTestAccount = supabaseId => {
  if (
    [
      5228,
      417,
      338,
      5869,
      6021,
      5737,
      5874,
      350,
      356,
      357,
      6763,
      309,
      321,
      422,
      339,
      431,
      821, // Test Account RS20
      978, // Test Account Ru
      872, // Test Petra
      820, // Test Account RS19
      800, // Test Account RS17
      803, // Test Account RS18
    ].includes(Number(supabaseId))
  ) return true
  return false
}

export default isTestAccount