import Immutable from 'seamless-immutable';
import * as actionTypes from './constants';

export const initialState = Immutable({
  leads: null,
  updatePending: false,
});

function adminReducer(state = initialState, action) {

  switch (action.type) {

    case actionTypes.LEADS_REQUEST:
      return state.set('updatePending', true);
    case actionTypes.LEADS_REQUEST_SUCCESS:
      return state.set('leads', action.leads);
    case actionTypes.LEADS_REQUEST_COMPLETE:
      return state.set('updatePending', false);

    case actionTypes.DELETE_LEAD:
    case actionTypes.REJECT_LEAD:
      return state.set('updatePending', true);
    case actionTypes.DELETE_LEAD_SUCCESS:
    case actionTypes.REJECT_LEAD_SUCCESS:
      return state
        .setIn(['leads', 'data'], state.leads.data.filter(l => l.TYPE !== action.lead.TYPE))
        // Reduce total by 1
        .setIn(['leads', 'total'], state.leads.total - 1 || 0)
        // Re-calculate total pages
        .setIn(['leads', 'totalPages'], Math.ceil(state.leads.data.length / state.leads.perPage));
    case actionTypes.DELETE_LEAD_COMPLETE:
    case actionTypes.REJECT_LEAD_COMPLETE:
      return state.set('updatePending', false);

    case actionTypes.APPROVE_LEAD:
      return state.set('updatePending', true);
    // case actionTypes.APPROVE_LEAD_SUCCESS:
      // return state.set('leads', state.leads.filter(l => l.TYPE !== action.lead.TYPE));
    // case actionTypes.APPROVE_LEAD_COMPLETE:
      // return state.set('updatePending', false);
   
    default:
      return state;
  }
}

export default adminReducer;
// 