import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col, FormGroup, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import SelectSearch from 'react-select-search';
import EISyntaxHighlighter from '../../components/EISyntaxHighlighter/EISyntaxHighlighter';
import { LoadingScreen } from './Profile';
import PremiumFeature from '../../components/PremiumFeature';

const ReviewsIframe = ({ product }) => {

  const { isPro } = product;
  const widgetScript = `<iframe allowtransparency="true" width="1000" height="1900" frame-border="0" src="https://edtechimpact.com/reviews/${product.slug}"></iframe>`;

  return (
    <>
      {!isPro && <div style={{margin: '0.75rem'}}>
        <PremiumFeature />
      </div>}

      <StyledReviewsIframe isPro={isPro}>
        {!isPro && <LoadingScreen />}

        <Row style={{marginTop: '1rem'}}>
          <Col><h4>Reviews Page</h4></Col>
        </Row>
            
        <Row>
          <Col>
            <p style={{marginBottom: '0', color: '#aaa'}}>Copy and paste this in your HTML in the place you want the reviews to appear.</p>
            <EISyntaxHighlighter
              text={widgetScript}
            />
          </Col>
        </Row>
        {product.numOfReviews > 0 &&
          <Row>
            <Col>
              <h6 style={{fontSize: '1.5rem', margin: '2rem 0 0'}}>Preview</h6>
              <p style={{marginBottom: '0', color: '#aaa'}}>This is how the widget will appear on your site.</p>
              <iframe allowtransparency="true" width="1000" height="2000" frameBorder="0" src={`https://edtechimpact.com/reviews/${product.slug}`}></iframe> 
            </Col>
          </Row>
        }
      </StyledReviewsIframe>
    </>
  );
}

export default ReviewsIframe;

const StyledReviewsIframe = styled(Container)`
  position: relative;

  ${({isPro}) => !isPro && `
    code {
      filter: blur(3px);
    }
  `}
`;

const Tabs = styled(Nav)`
  margin-bottom: 1rem;
`;

const Tab = styled(NavItem)`
  cursor: pointer;

  .nav-link.active {
    border-color: #fff;
  }

  &:hover {
    .nav-link {
      border-color: #fff;
    }
  }

  .active {
    border-bottom: 4px solid #FD7252 !important;
    cursor: initial;
    font-weight: bold;
  }
`;
