import * as actionTypes from './constants';

export function updateConfigAction(config) {
  return {
    type: actionTypes.UPDATE_CONFIG,
    payload: config,
  };
}

export function updateConfigSuccessAction(config) {
  return {
    type: actionTypes.UPDATE_CONFIG_SUCCESS,
    payload: config,
  };
}

export function updateConfigCompleteAction() {
  return {
    type: actionTypes.UPDATE_CONFIG_COMPLETE,
  };
}

export function resellerRequestAction(resellerName) {
  return {
    type: actionTypes.RESELLER_REQUEST,
    payload: resellerName,
  };
}

export function resellerRequestCompleteAction() {
  return {
    type: actionTypes.RESELLER_REQUEST_COMPLETE,
  };
}

export function resellerRequestSuccessAction(reseller) {
  return {
    type: actionTypes.RESELLER_REQUEST_SUCCESS,
    payload: reseller,
  };
}

export function invitesRequestAction(payload) {
  return {
    type: actionTypes.INVITES_REQUEST,
    payload,
  };
}

export function invitesRequestCompleteAction() {
  return {
    type: actionTypes.INVITES_REQUEST_COMPLETE,
  };
}

export function invitesRequestSuccessAction(invites) {
  return {
    type: actionTypes.INVITES_REQUEST_SUCCESS,
    payload: invites,
  };
}

export function invitesUploadAction(payload) {
  return {
    type: actionTypes.INVITES_UPLOAD,
    payload,
  };
}

export function invitesUploadSuccessAction(payload) {
  return {
    type: actionTypes.INVITES_UPLOAD_SUCCESS,
    payload,
  };
}

export function invitesUploadCompleteAction() {
  return {
    type: actionTypes.INVITES_UPLOAD_COMPLETE,
  };
}

export function reviewsRequestAction(payload) {
  return {
    type: actionTypes.REVIEWS_REQUEST,
    payload,
  };
}

export function reviewsRequestCompleteAction() {
  return {
    type: actionTypes.REVIEWS_REQUEST_COMPLETE,
  };
}

export function reviewsRequestSuccessAction(reviews) {
  return {
    type: actionTypes.REVIEWS_REQUEST_SUCCESS,
    reviews,
  };
}

export function reviewReplyAction(payload) {
  return {
    type: actionTypes.REVIEW_REPLY,
    payload,
  };
}

export function reviewReplyCompleteAction() {
  return {
    type: actionTypes.REVIEW_REPLY_COMPLETE,
  };
}

export function reviewReplySuccessAction(review) {
  return {
    type: actionTypes.REVIEW_REPLY_SUCCESS,
    payload: review,
  };
}

export function schoolSizeChange(payload) {
  return {
    type: actionTypes.SCHOOL_SIZE_CHANGE,
    payload,
  };
}

export function schoolTypeChange(payload) {
  return {
    type: actionTypes.SCHOOL_TYPE_CHANGE,
    payload,
  };
}

export function resellersToggleFilterAction(payload) {
  return {
    type: actionTypes.RESELLERS_TOGGLE_FILTER,
    payload,
  };
}

export function resellersToggleSubjectAction(payload) {
  return {
    type: actionTypes.RESELLERS_TOGGLE_SUBJECT,
    payload,
  };
}

export function resellersToggleFeatureAction(payload) {
  return {
    type: actionTypes.RESELLERS_TOGGLE_FEATURE,
    payload,
  };
}

export function resellersToggleTypeAction(payload) {
  return {
    type: actionTypes.RESELLERS_TOGGLE_TYPE,
    payload,
  };
}

export function resellersToggleCheckboxAction(payload) {
  return {
    type: actionTypes.RESELLERS_TOGGLE_CHECKBOX,
    payload,
  };
}

export function resellersSelectPlanAction(payload) {
  return {
    type: actionTypes.RESELLERS_SELECT_PLAN,
    payload,
  };
}

export function selectReseller(slug) {
  return {
    type: actionTypes.SELECT_RESELLER,
    slug,
  };
}

export function resetState() {
  return {
    type: actionTypes.RESET_STATE,
  };
}

export function selectResellerSuccess(reseller) {
  return {
    type: actionTypes.SELECT_RESELLER_SUCCESS,
    reseller,
  };
}

export function selectResellerComplete() {
  return {
    type: actionTypes.SELECT_RESELLER_COMPLETE,
  };
}

export function updateResellerAction({reseller, screenshots, logo, product}) {
  return {
    type: actionTypes.UPDATE_RESELLER,
    reseller: reseller || product,
    screenshots,
    logo,
  };
}

export function completeUpdateReseller(reseller) {
  return {
    type: actionTypes.COMPLETE_UPDATE_RESELLER,
    reseller,
  };
}

export function createResellerAction(reseller) {
  return {
    type: actionTypes.CREATE_RESELLER,
    reseller,
  };
}

export function createResellerSuccessAction(reseller) {
  return {
    type: actionTypes.CREATE_RESELLER_SUCCESS,
    reseller,
  };
}

export function createResellerCompleteAction(reseller) {
  return {
    type: actionTypes.CREATE_RESELLER_COMPLETE,
    reseller,
  };
}

export function resellersEmailsChangeAction(emails) {
  return {
    type: actionTypes.EMAILS_CHANGE,
    emails,
  };
}

export function reviewChange(reviewPreference) {
  return {
    type: actionTypes.REVIEW_CHANGE,
    reviewPreference,
  };
}

export function widgetDetailsChange(detailsPreference) {
  return {
    type: actionTypes.WIDGET_DETAILS_CHANGE,
    detailsPreference,
  };
}

export function exportReviews() {
  return {
    type: actionTypes.EXPORT_REVIEWS,
  };
}

export function exportAllReviews() {
  return {
    type: actionTypes.EXPORT_ALL_REVIEWS,
  };
}

export function exportAllResellers() {
  return {
    type: actionTypes.EXPORT_ALL_RESELLERS,
  };
}

export function exportComplete() {
  return {
    type: actionTypes.EXPORT_COMPLETE,
  };
}

export function uploadLogo(payload) {
  return {
    type: actionTypes.UPLOAD_LOGO,
    payload,
  };
}

export function uploadScreenshot(payload) {
  return {
    type: actionTypes.UPLOAD_SCREENSHOT,
    payload,
  };
}

export function uploadEvidence(payload) {
  return {
    type: actionTypes.UPLOAD_EVIDENCE,
    payload,
  };
}

export function deleteEvidence(payload) {
  return {
    type: actionTypes.DELETE_EVIDENCE,
    payload,
  };
}

export function configRequestAction() {
  return {
    type: actionTypes.CONFIG_REQUEST,
  };
}

export function configRequestSuccessAction(config) {
  return {
    type: actionTypes.CONFIG_REQUEST_SUCCESS,
    payload: config,
  };
}

export function configRequestCompleteAction() {
  return {
    type: actionTypes.CONFIG_REQUEST_COMPLETE,
  };
}

export function leadsRequestAction(payload) {
  return {
    type: actionTypes.LEADS_REQUEST,
    payload,
  };
}

export function leadsRequestCompleteAction() {
  return {
    type: actionTypes.LEADS_REQUEST_COMPLETE,
  };
}

export function leadsRequestSuccessAction(evidence) {
  return {
    type: actionTypes.LEADS_REQUEST_SUCCESS,
    payload: evidence,
  };
}

export function setLeadStatusAction({lead, status}) {
  return {
    type: actionTypes.SET_LEAD_STATUS,
    lead,
    status,
  };
}

export function setLeadStatusCompleteAction() {
  return {
    type: actionTypes.SET_LEAD_STATUS_COMPLETE,
  };
}

export function setLeadStatusSuccessAction(lead) {
  return {
    type: actionTypes.SET_LEAD_STATUS_SUCCESS,
    payload: lead,
  };
}

export function leadReplyAction(payload) {
  return {
    type: actionTypes.LEAD_REPLY,
    payload,
  };
}

export function leadReplyCompleteAction() {
  return {
    type: actionTypes.LEAD_REPLY_COMPLETE,
  };
}

export function leadReplySuccessAction(lead) {
  return {
    type: actionTypes.LEAD_REPLY_SUCCESS,
    payload: lead,
  };
}

export function deleteLeadAction(lead) {
  return {
    type: actionTypes.DELETE_LEAD,
    lead,
  };
}

export function deleteLeadSuccessAction(lead) {
  return {
    type: actionTypes.DELETE_LEAD_SUCCESS,
    lead,
  };
}

export function deleteLeadCompleteAction() {
  return {
    type: actionTypes.DELETE_LEAD_COMPLETE,
  };
}
