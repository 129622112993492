import React, {useState, useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import { getCurrentUser } from "./pages/Authentication/useAuth";
import LogRocket from 'logrocket';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { supabase } from 'utils/supabase';

const Analytics = ({children, location, analyticsId}) => {

  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const getUser = async () => {
      const user = await getCurrentUser();
      setCurrentUser(user);
    };
    getUser();
  }, [location]);

  const pathnameParts = location.pathname.split('/');
  const page = pathnameParts[pathnameParts.length - 1];

  if (window.location.href.includes('https://my.edtechimpact.com')) {
    LogRocket.identify(currentUser?.attributes?.email, {
      email: currentUser?.attributes?.email,
      slug: pathnameParts[2],
    });
  }

  const { Track, trackEvent } = useTracking({
    isAdmin: currentUser?.isAdmin,
    user: currentUser?.attributes?.email,
    userId: currentUser?.signInUserSession?.idToken?.payload?.supabaseUserId,
    product: pathnameParts[2],
    page,
  },
  {
    dispatch: data => {
      const sendToLog = async () => {
        if (data.userId) {
          await supabase.rpc('create_user_analytics_event', {
            event: data.event,
            data: {
              ...data,
              page: data.page,
              user: data.user,
              isAdmin: data.isAdmin,
              domain: window.location.host,
            },
            user_id: data.userId,
            product_slug: data.product,
          });
        }
      }

      window.dataLayer.push(data);
      sendToLog();
    }
  }
  );
  
  trackEvent({
    event: 'page-view',
    isAdmin: currentUser?.isAdmin,
    user: currentUser?.attributes?.email,
    product: pathnameParts[2],
    page,
  });
  
  return <Track>{children}</Track>;
}


const mapStateToProps = state => {
  const { analyticsId } = state.App;
  return { analyticsId };
};

export default compose(
  connect(
    mapStateToProps,
  ),
  withRouter,
)(Analytics);
