import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
// Import Routes
import { authProtectedRoutes, publicRoutes, adminRoutes } from "./routes/";
import AppRoute from "./routes/route";
import {
  storePreselectedSubscriptionAction,
  storePreselectedCouponCodeAction,
  storeAnalyticsIdAction,
} from "./store/app/actions";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

import { Amplify } from 'aws-amplify';

// Import scss
import "./assets/scss/theme.scss";

import LogRocket from 'logrocket';
import { withRouter } from 'react-router-dom';
import { API_V2_DEV_URL, API_V2_URL } from "config/settings";

import * as productActions from './pages/ProductAdmin/actions';
import * as resellerActions from './pages/ResellerAdmin/actions';
import * as deviceActions from './pages/DeviceAdmin/actions';
import * as brandActions from './pages/BrandAdmin/actions';
import { authHost } from "pages/Authentication/Login";
import { Box } from "@mui/material";
import LoadingSpinner from './components/Loader';

if (process.env.NODE_ENV === 'production') {
  LogRocket.init('hq4gee/myedtechimpactcom');
}

const cookieDomain = process.env.NODE_ENV === 'development' ? '.localedtechimpact.com' : '.edtechimpact.com'

const oauth = {
  domain: 'auth.edtechimpact.com',
  scope: ['email', 'profile', 'openid', 'phone', 'aws.cognito.signin.user.admin'],
  redirectSignIn: `${window.location.origin}`,
  redirectSignOut: `${window.location.origin}`,
  responseType: 'code',
};

Amplify.configure({
  Auth: {
    oauth: oauth,
    mandatorySignIn: false,
    region: 'eu-west-2',
    userPoolId: 'eu-west-2_xMCI6tGDS',
    identityPoolId: 'eu-west-2:aa1c45b4-9f59-4e8c-9f6f-6af48686ecd5',
    userPoolWebClientId: '790eq79r5surslvbo501h2udje',
    cookieStorage: {
      domain: cookieDomain,
      path: '/',
      secure: process.env.NODE_ENV !== 'development',
      sameSite: 'lax',
    },
  },
  API: {
    endpoints: [
      {
        name: 'ei-api',
        endpoint: API_V2_URL,
        region: 'eu-west-2',
      },
      {
        name: 'ei-api-dev',
        endpoint: API_V2_DEV_URL,
        region: 'eu-west-2',
      },
    ],
  },
});

const App = ({
  history,
  location,
  dispatchPreselectedSubscription,
  dispatchPreselectedCouponCode,
  dispatchStoreAnalyticsId,
  dispatchConfigRequest,
  dispatchProductsRequest,
  dispatchResellersRequest,
  dispatchDevicesRequest,
  dispatchBrandsRequest,
  products,
  productsPending,
  resellers,
  resellersPending,
}) => {

  useEffect(
    function getFundamentalPageData() {
      dispatchConfigRequest()
      dispatchProductsRequest()
      dispatchResellersRequest()
      dispatchDevicesRequest()
      dispatchBrandsRequest()
    },
    []
  );

  useEffect(() => {
    const isAdminRoute = location.pathname.startsWith('/admin')
    const isHomeRoute = location.pathname === '/'

    const hasProducts = !productsPending && products?.length > 1
    const hasResellers = !resellersPending && resellers?.length > 1

    if (!isAdminRoute && !productsPending && products && !resellersPending && resellers) {
      if (isHomeRoute && hasProducts) history.push(`/products/${products[0].slug}/dashboard`)
      else if (isHomeRoute && hasResellers) history.push(`/resellers/${resellers[0].slug}/dashboard`)
      else if (!hasProducts && !hasResellers) window.location.replace(encodeURI(`${authHost}/account/`))
    }
  }, [productsPending, resellersPending])

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const stripePriceId = params.get('subscription');
    const couponCode = params.get('offer');
    const analyticsId = params.get('user');

    if (stripePriceId) {
      dispatchPreselectedSubscription(stripePriceId);
    }

    if (couponCode) {
      dispatchPreselectedCouponCode(couponCode);
    }

    if (analyticsId) {
      dispatchStoreAnalyticsId(analyticsId);
    }
  }, []);

  if (productsPending || resellersPending) return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', height: '100vh', width: '100vw' }}>
      <LoadingSpinner />
    </Box>
  )

  return (
    <Switch>

      {adminRoutes.map((route) => (
        <AppRoute
          path={route.path}
          layout={VerticalLayout}
          component={route.component}
          key={route.path}
          isAuthProtected={true}
          admin
        />
      ))}
      
      {publicRoutes.map((route) => (
        <AppRoute
          path={route.path}
          layout={NonAuthLayout}
          component={route.component}
          key={route.path}
          isAuthProtected={false}
        />
        ))}

      {authProtectedRoutes.map((route) => (
        <AppRoute
          path={route.path}
          layout={VerticalLayout}
          component={route.component}
          key={route.path}
          isAuthProtected={true}
        />
        ))}

    </Switch>
  );
}

const mapStateToProps = state => {
  const { products, productsPending } = state.Product;
  const { resellers, resellersPending } = state.Reseller;
  return { products, productsPending, resellers, resellersPending };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dispatchPreselectedSubscription: storePreselectedSubscriptionAction,
      dispatchPreselectedCouponCode: storePreselectedCouponCodeAction,
      dispatchStoreAnalyticsId: storeAnalyticsIdAction,
      dispatchConfigRequest: productActions.configRequestAction,
      dispatchProductsRequest: productActions.productsRequestAction,
      dispatchResellersRequest: resellerActions.resellerRequestAction,
      dispatchDevicesRequest: deviceActions.deviceRequestAction,
      dispatchBrandsRequest: brandActions.brandRequestAction,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(App));
