// ** MUI Imports
import { deepmerge } from '@mui/utils'
import { ThemeProvider, createTheme } from '@mui/material/styles'

// ** Theme Override Imports
import overrides from './overrides'
import typography from './typography'

// ** Theme
import UserThemeOptions from '../../layouts/UserThemeOptions'
import themeOptions from './ThemeOptions'

const ThemeComponent = props => {
  // ** Props
  const { settings, children } = props

  // ** Merged ThemeOptions of Core and User
  const coreThemeConfig = themeOptions(settings)

  // ** Pass ThemeOptions to CreateTheme Function to create partial theme without component overrides
  let theme = createTheme(coreThemeConfig)

  // ** Deep Merge Component overrides of core and user
  const mergedComponentOverrides = deepmerge({ ...overrides(theme, settings) }, UserThemeOptions()?.components)

  // ** Deep Merge Typography of core and user
  const mergedTypography = deepmerge(typography(theme), UserThemeOptions()?.typography)

  // ** Continue theme creation and pass merged component overrides to CreateTheme function
  theme = createTheme(theme, {
    components: { ...mergedComponentOverrides },
    typography: { ...mergedTypography }
  })

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}

export default ThemeComponent
