import React, { useState, useEffect, Suspense, lazy } from 'react';
import {
  Container, Col, Card, CardBody,
} from 'reactstrap';
import { withRouter, Route, Redirect, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoadingSpinner from '../../components/Loader';
import * as actions from './actions';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import servicesIcon3 from "../../assets/images/services-icon/01.png";
import useAuth from "../../pages/Authentication/useAuth";
import _ from 'lodash';
import { withTracking } from 'react-tracking';
import usePrevious from '../../helpers/usePrevious';

const UserManagement = lazy(() => import('./UserManagement'));
const Profile = lazy(() => import('./Profile'));

const BrandAdmin = ({
  brandPending,
  reviewsPending,
  invitesPending,
  brands,
  brand,
  match,
  dispatchConfigRequest,
  dispatchBrandRequest,
  dispatchInvitesRequest,
  dispatchSelectBrand,
  dispatchReviewsRequest,
  tracking,
}) => {

  const { currentUser } = useAuth();
  const prevBrand = usePrevious(brand);

  useEffect(() => {
    dispatchConfigRequest();
  
    if (!brands) {
      dispatchBrandRequest();
    }
  }, []);

  useEffect(() => {
    const hasMatchedBrands = brands?.filter(p => p.brandMatch).length > 0;

    if (!hasMatchedBrands && (brands || match.params.slug) && (!prevBrand || match.params.slug !== prevBrand.slug)) {
      const slug = match.params?.slug || brand?.slug || brands[0]?.slug;

      if (slug) {
        !brandPending && dispatchSelectBrand(slug);
      }
    }
  }, [match.params.slug, brand, brands]);

  useEffect(() => {
    if (brand && prevBrand?.slug === brand.slug) {
      tracking.trackEvent({ event: 'update-brand' });
    }
  }, [brand]);

  if (!brands || brandPending) return <LoadingSpinner />;
    
  if (brands && brands.filter(p => p.brandMatch).length > 0) {
    return (
      <Col md={8}>
        <Card className="mini-stat bg-primary text-white mt-4">
          <CardBody>
            <div className="mb-4">
              <div className="float-left mini-stat-img mr-4">
                <img src={servicesIcon3} alt="" />
              </div>
              <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                You have matching brands
              </h5>
              <h4 className="font-weight-medium font-size-18">
                Your email address matches our records for {brands.filter(p => p.brandMatch).map(p => p.name).join(', ')}.
              </h4>
              <h4 className="font-weight-medium font-size-18">
                Please contact <a className="text-white" href="mailto:hello@edtechimpact.com">hello@edtechimpact.com</a> to be added as an administrator of this brand listing.
              </h4>
            </div>
          </CardBody>
        </Card>
      </Col>
    )
  }

  if (brands.length < 2 && !brand) return <Redirect to="/brands/add-listing/profile" />;
    
  if (match.params.slug && !brands.find(p => p.slug === match.params.slug)) {
    return <Redirect to='/' />;
  }
    
  if (!match.params.slug && brands.length === 2) return <Redirect to={`/brands/${brands[0].slug}/profile`} />;
  
  if (brands.length > 1 && !brand) return <Redirect to={`/brands/${match.params.slug || brands[0].slug}/profile`} />;

  return (
    <StyledBrandAdmin fluid>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
      />

      <Suspense fallback={<LoadingSpinner />}>
        <Switch>
          <Route
            path="/brands/:slug/profile"
            exact
            render={() => <Profile />}
          />
          <Route
            path="/brands/:slug/admin/users"
            exact
            render={() => <UserManagement />}
          />
        </Switch>
      </Suspense>
    </StyledBrandAdmin>
  );
}

const StyledBrandAdmin = styled(Container)`
  position: relative;
  margin-bottom: 3rem;
`;

const mapStateToProps = state => {
  const { brand, brands, brandPending, invitesPending, brandsPending, updatePending, reviewsPending } = state.Brand;
  return { brand, brands, brandPending, invitesPending, brandsPending, updatePending, reviewsPending };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dispatchBrandRequest: actions.brandRequestAction,
      dispatchSelectBrand: actions.selectBrand,
      dispatchReviewsRequest: actions.reviewsRequestAction,
      dispatchConfigRequest: actions.configRequestAction,
      dispatchInvitesRequest: actions.invitesRequestAction,
    },
    dispatch,
  );

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
  withTracking(),
)(BrandAdmin);
