// Since we're using min-width, this would be a mobile-first approach.
// Breakpoints are the same as those used by Bootstrap by default.
// On 09/01/2019 from https://jsramblings.com/2018/02/04/styled-components-media-queries.html

export const bpWidths = {
  xsm: '320px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1400px',
};

export const bp = {
  xsm: `(min-width: ${bpWidths.sm})`,
  sm: `(min-width: ${bpWidths.sm})`,
  md: `(min-width: ${bpWidths.md})`,
  lg: `(min-width: ${bpWidths.lg})`,
  xl: `(min-width: ${bpWidths.xl})`,
};
