import React, { useState } from "react";
import { Row, Col, Card, CardBody, Alert } from "reactstrap";
import { Auth, Logger, JS } from "aws-amplify";


// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

import Loader from "../../components/Loader";

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// import images
import googleLogo from "../../assets/images/google-logo.png";

import countries from "../../pages/ProductAdmin/data/countries";

function RegistrationForm(props) {
  const {
    handleGoogleLogin,
    handleLoading,
    Loading,
    Error,
    handlevalidSubmit,
    user,
    value,
  } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handlegoogleLogin = () => {
    console.log("google login");
    setLoading(true);
    Auth.federatedSignIn({ provider: "Google" });
  };

  // // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    setLoading(true);

    const attributes = {
      ...values,
      "custom:name": `${values.firstName} ${values.lastName}`,
    };

    delete attributes.firstName;
    delete attributes.lastName;
    delete attributes.password;

    Auth.signUp({
      username: values.email,
      password: values.password,
      attributes,
    })
      .then((user) => signUpSuccess(user))
      .catch((err) => signUpError(err))
      .finally(() => setLoading(false));
  };

  const signUpSuccess = (username) => {
    console.log("Sign Up success");
    changeState("confirmSignUp", username);
  };

  const signUpError = (err) => {
    let message = err.message || err;
    if (message.startsWith("Invalid phone number")) {
      // reference: https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-attributes.html
      message =
        "Phone numbers must follow these formatting rules: A phone number must start with a plus (+) sign, followed immediately by the country code. A phone number can only contain the + sign and digits. You must remove any other characters from a phone number, such as parentheses, spaces, or dashes (-) before submitting the value to the service. For example, a United States-based phone number must follow this format: +14325551212.";
    }
    setError(message);
  };

  const changeState = (state, data) => {
    const { onStateChange } = value;
    if (onStateChange) {
      onStateChange(state, data);
    }
  };
  return (
    <>
      <div className="position-relative">
        <Card className="overflow-hidden">
          <CardBody className="p-4">
            <div className="p-3">
              <h3 className="text-center mb-4">Register for EdTech Impact</h3>

              <AvForm
                disabled={loading}
                className="form-horizontal mt-4"
                onValidSubmit={(event, values) => {
                  console.log(values);
                  handleValidSubmit(event, values);
                }}
              >
                {user && user ? (
                  <Alert color="success">Register User Successfully</Alert>
                ) : null}
                {error ? <Alert color="danger">{error}</Alert> : null}

                <div className="form-group">
                  <AvField
                    name="email"
                    label="Email"
                    className="form-control"
                    placeholder="Enter email"
                    type="email"
                    required
                  />
                </div>
                <div className="form-group">
                  <AvField
                    name="password"
                    label="Password"
                    type="password"
                    required
                    placeholder="Enter Password"
                  />
                </div>
                <div className="form-group">
                  <AvField
                    name="firstName"
                    label="First Name"
                    placeholder="Enter First Name"
                    type="text"
                    required
                  />
                </div>
                <div className="form-group">
                  <AvField
                    name="lastName"
                    label="Last Name"
                    placeholder="Enter last Name"
                    type="text"
                    required
                  />
                </div>
                <div className="form-group">
                  <AvField
                    name="custom:jobTitle"
                    label="Job Title"
                    placeholder="Enter Job Title"
                    type="text"
                    required
                  />
                </div>
                <div className="form-group">
                  <AvField
                    name="custom:phone"
                    label="Phone Number"
                    placeholder="Enter Phone Number"
                    type="text"
                    required
                  />
                </div>
                <div className="form-group">
                  <AvField
                    name="custom:organisation"
                    label="Organisation Name"
                    placeholder="Enter Organaisation Name"
                    type="text"
                    required
                  />
                </div>
                <div className="form-group">
                  <AvField
                    name="custom:productName"
                    label="Product Name"
                    type="text"
                    placeholder="Enter Product Name"
                    required
                  />
                </div>
                <div className="form-group">
                  <AvField
                    name="custom:website"
                    label="Website URL"
                    placeholder="Enter Website url"
                    type="text"
                    required
                  />
                </div>
                <div className="form-group">
                  <AvField
                    name="custom:country"
                    label="Country"
                    type="select"
                    value="United Kingdom"
                    required
                  >
                    {countries.map((c) => (
                      <option key={c.label}>{c.label}</option>
                    ))}
                  </AvField>
                </div>
                <div className="form-group">
                  <AvField
                    name="custom:numberOfCustomers"
                    label="Number Of Customers Globally (Approx)"
                    placeholder="Enter Number of Customers"
                    type="text"
                    required
                  />
                </div>
                <Row className="form-group">
                  <Col className="text-right d-flex justify-content-around align-items-center">
                    {loading ? (
                      <Loader />
                    ) : (
                      <button
                        className="btn btn-primary w-100 waves-effect waves-light"
                        type="submit"
                      >
                        Register
                      </button>
                    )}
                  </Col>
                </Row>
                <Row className="form-group mt-2 mb-0">
                <div className="css-d89191">
                <div className="css-3g2h7c"></div>
                <div className="css-16d8ppv">Or</div>
                <div className="css-3g2h7c"></div>
              </div>
                
              <button
                className="btn btn-outline w-100 waves-effect waves-light"
                onClick={handlegoogleLogin}
              >
                <img src={googleLogo} width="18" className="mr-2" />
                Sign up with Google
              </button>

             
                  <div className="col-12 mt-4">
                    <p className="mb-0">
                      By registering you agree to the{" "}
                      <a
                        href="https://edtechimpact.com/supplier-terms"
                        className="text-primary"
                        target="_blank"
                      >
                        Terms of Use
                      </a>
                      .
                    </p>
                  </div>
                </Row>
              </AvForm>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default RegistrationForm;

// const mapStatetoProps = (state) => {
//   const { user, registrationError, loading } = state.Account;
//   return { user, registrationError, loading };
// };

// export default connect(mapStatetoProps)(RegistrationForm);
