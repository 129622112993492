const countries = [
  {
    label: 'United Kingdom'
  },
  {
    label: 'Guernsey'
  },
  {
    label: 'Jersey'
  },
  {
    label: 'Albania'
  },
  {
    label: 'Algeria'
  },
  {
    label: 'American Samoa'
  },
  {
    label: 'Andorra'
  },
  {
    label: 'Angola'
  },
  {
    label: 'Anguilla'
  },
  {
    label: 'Antarctica'
  },
  {
    label: 'Antigua And Barbuda'
  },
  {
    label: 'Argentina'
  },
  {
    label: 'Armenia'
  },
  {
    label: 'Aruba'
  },
  {
    label: 'Australia'
  },
  {
    label: 'Austria'
  },
  {
    label: 'Azerbaijan'
  },
  {
    label: 'Bahamas'
  },
  {
    label: 'Bahrain'
  },
  {
    label: 'Bangladesh'
  },
  {
    label: 'Barbados'
  },
  {
    label: 'Belarus'
  },
  {
    label: 'Belgium'
  },
  {
    label: 'Belize'
  },
  {
    label: 'Benin'
  },
  {
    label: 'Bermuda'
  },
  {
    label: 'Bhutan'
  },
  {
    label: 'Bolivia'
  },
  {
    label: 'Bosnia And Herzegovina'
  },
  {
    label: 'Botswana'
  },
  {
    label: 'Bouvet Island'
  },
  {
    label: 'Brazil'
  },
  {
    label: 'British Indian Ocean Territory'
  },
  {
    label: 'Brunei Darussalam'
  },
  {
    label: 'Bulgaria'
  },
  {
    label: 'Burkina Faso'
  },
  {
    label: 'Burundi'
  },
  {
    label: 'Cambodia'
  },
  {
    label: 'Cameroon'
  },
  {
    label: 'Canada'
  },
  {
    label: 'Cape Verde'
  },
  {
    label: 'Cayman Islands'
  },
  {
    label: 'Central African Republic'
  },
  {
    label: 'Chad'
  },
  {
    label: 'Chile'
  },
  {
    label: 'China'
  },
  {
    label: 'Christmas Island'
  },
  {
    label: 'Cocos (keeling) Islands'
  },
  {
    label: 'Colombia'
  },
  {
    label: 'Comoros'
  },
  {
    label: 'Congo'
  },
  {
    label: 'Congo, The Democratic Republic Of The'
  },
  {
    label: 'Cook Islands'
  },
  {
    label: 'Costa Rica'
  },
  {
    label: 'Cote D\'ivoire'
  },
  {
    label: 'Croatia'
  },
  {
    label: 'Cuba'
  },
  {
    label: 'Cyprus'
  },
  {
    label: 'Czech Republic'
  },
  {
    label: 'Denmark'
  },
  {
    label: 'Djibouti'
  },
  {
    label: 'Dominica'
  },
  {
    label: 'Dominican Republic'
  },
  {
    label: 'East Timor'
  },
  {
    label: 'Ecuador'
  },
  {
    label: 'Egypt'
  },
  {
    label: 'El Salvador'
  },
  {
    label: 'Equatorial Guinea'
  },
  {
    label: 'Eritrea'
  },
  {
    label: 'Estonia'
  },
  {
    label: 'Ethiopia'
  },
  {
    label: 'Falkland Islands (malvinas)'
  },
  {
    label: 'Faroe Islands'
  },
  {
    label: 'Fiji'
  },
  {
    label: 'Finland'
  },
  {
    label: 'France'
  },
  {
    label: 'French Guiana'
  },
  {
    label: 'French Polynesia'
  },
  {
    label: 'French Southern Territories'
  },
  {
    label: 'Gabon'
  },
  {
    label: 'Gambia'
  },
  {
    label: 'Georgia'
  },
  {
    label: 'Germany'
  },
  {
    label: 'Ghana'
  },
  {
    label: 'Gibraltar'
  },
  {
    label: 'Greece'
  },
  {
    label: 'Greenland'
  },
  {
    label: 'Grenada'
  },
  {
    label: 'Guadeloupe'
  },
  {
    label: 'Guam'
  },
  {
    label: 'Guatemala'
  },
  {
    label: 'Guinea'
  },
  {
    label: 'Guinea-bissau'
  },
  {
    label: 'Guyana'
  },
  {
    label: 'Haiti'
  },
  {
    label: 'Heard Island And Mcdonald Islands'
  },
  {
    label: 'Holy See (vatican City State)'
  },
  {
    label: 'Honduras'
  },
  {
    label: 'Hong Kong'
  },
  {
    label: 'Hungary'
  },
  {
    label: 'Iceland'
  },
  {
    label: 'India'
  },
  {
    label: 'Indonesia'
  },
  {
    label: 'Iran, Islamic Republic Of'
  },
  {
    label: 'Iraq'
  },
  {
    label: 'Ireland'
  },
  {
    label: 'Israel'
  },
  {
    label: 'Italy'
  },
  {
    label: 'Jamaica'
  },
  {
    label: 'Japan'
  },
  {
    label: 'Jordan'
  },
  {
    label: 'Kazakstan'
  },
  {
    label: 'Kenya'
  },
  {
    label: 'Kiribati'
  },
  {
    label: 'Korea, Democratic People\'s Republic Of'
  },
  {
    label: 'Korea, Republic Of'
  },
  {
    label: 'Kosovo'
  },
  {
    label: 'Kuwait'
  },
  {
    label: 'Kyrgyzstan'
  },
  {
    label: 'Lao People\'s Democratic Republic'
  },
  {
    label: 'Latvia'
  },
  {
    label: 'Lebanon'
  },
  {
    label: 'Lesotho'
  },
  {
    label: 'Liberia'
  },
  {
    label: 'Libyan Arab Jamahiriya'
  },
  {
    label: 'Liechtenstein'
  },
  {
    label: 'Lithuania'
  },
  {
    label: 'Luxembourg'
  },
  {
    label: 'Macau'
  },
  {
    label: 'Macedonia, The Former Yugoslav Republic Of'
  },
  {
    label: 'Madagascar'
  },
  {
    label: 'Malawi'
  },
  {
    label: 'Malaysia'
  },
  {
    label: 'Maldives'
  },
  {
    label: 'Mali'
  },
  {
    label: 'Malta'
  },
  {
    label: 'Marshall Islands'
  },
  {
    label: 'Martinique'
  },
  {
    label: 'Mauritania'
  },
  {
    label: 'Mauritius'
  },
  {
    label: 'Mayotte'
  },
  {
    label: 'Mexico'
  },
  {
    label: 'Micronesia, Federated States Of'
  },
  {
    label: 'Moldova, Republic Of'
  },
  {
    label: 'Monaco'
  },
  {
    label: 'Mongolia'
  },
  {
    label: 'Montserrat'
  },
  {
    label: 'Montenegro'
  },
  {
    label: 'Morocco'
  },
  {
    label: 'Mozambique'
  },
  {
    label: 'Myanmar'
  },
  {
    label: 'Namibia'
  },
  {
    label: 'Nauru'
  },
  {
    label: 'Nepal'
  },
  {
    label: 'Netherlands'
  },
  {
    label: 'Netherlands Antilles'
  },
  {
    label: 'New Caledonia'
  },
  {
    label: 'New Zealand'
  },
  {
    label: 'Nicaragua'
  },
  {
    label: 'Niger'
  },
  {
    label: 'Nigeria'
  },
  {
    label: 'Niue'
  },
  {
    label: 'Norfolk Island'
  },
  {
    label: 'Northern Mariana Islands'
  },
  {
    label: 'Norway'
  },
  {
    label: 'Oman'
  },
  {
    label: 'Pakistan'
  },
  {
    label: 'Palau'
  },
  {
    label: 'Palestinian Territory, Occupied'
  },
  {
    label: 'Panama'
  },
  {
    label: 'Papua New Guinea'
  },
  {
    label: 'Paraguay'
  },
  {
    label: 'Peru'
  },
  {
    label: 'Philippines'
  },
  {
    label: 'Pitcairn'
  },
  {
    label: 'Poland'
  },
  {
    label: 'Portugal'
  },
  {
    label: 'Puerto Rico'
  },
  {
    label: 'Qatar'
  },
  {
    label: 'Reunion'
  },
  {
    label: 'Romania'
  },
  {
    label: 'Russian Federation'
  },
  {
    label: 'Rwanda'
  },
  {
    label: 'Saint Helena'
  },
  {
    label: 'Saint Kitts And Nevis'
  },
  {
    label: 'Saint Lucia'
  },
  {
    label: 'Saint Pierre And Miquelon'
  },
  {
    label: 'Saint Vincent And The Grenadines'
  },
  {
    label: 'Samoa'
  },
  {
    label: 'San Marino'
  },
  {
    label: 'Sao Tome And Principe'
  },
  {
    label: 'Saudi Arabia'
  },
  {
    label: 'Senegal'
  },
  {
    label: 'Serbia'
  },
  {
    label: 'Seychelles'
  },
  {
    label: 'Sierra Leone'
  },
  {
    label: 'Singapore'
  },
  {
    label: 'Slovakia'
  },
  {
    label: 'Slovenia'
  },
  {
    label: 'Solomon Islands'
  },
  {
    label: 'Somalia'
  },
  {
    label: 'South Africa'
  },
  {
    label: 'South Georgia And The South Sandwich Islands'
  },
  {
    label: 'Spain'
  },
  {
    label: 'Sri Lanka'
  },
  {
    label: 'Sudan'
  },
  {
    label: 'Suriname'
  },
  {
    label: 'Svalbard And Jan Mayen'
  },
  {
    label: 'Swaziland'
  },
  {
    label: 'Sweden'
  },
  {
    label: 'Switzerland'
  },
  {
    label: 'Syrian Arab Republic'
  },
  {
    label: 'Taiwan, Province Of China'
  },
  {
    label: 'Tajikistan'
  },
  {
    label: 'Tanzania, United Republic Of'
  },
  {
    label: 'Thailand'
  },
  {
    label: 'Togo'
  },
  {
    label: 'Tokelau'
  },
  {
    label: 'Tonga'
  },
  {
    label: 'Trinidad And Tobago'
  },
  {
    label: 'Tunisia'
  },
  {
    label: 'Turkey'
  },
  {
    label: 'Turkmenistan'
  },
  {
    label: 'Turks And Caicos Islands'
  },
  {
    label: 'Tuvalu'
  },
  {
    label: 'Uganda'
  },
  {
    label: 'Ukraine'
  },
  {
    label: 'United Arab Emirates'
  },
  {
    label: 'United States'
  },
  {
    label: 'United States Minor Outlying Islands'
  },
  {
    label: 'Uruguay'
  },
  {
    label: 'Uzbekistan'
  },
  {
    label: 'Vanuatu'
  },
  {
    label: 'Venezuela'
  },
  {
    label: 'Viet Nam'
  },
  {
    label: 'Virgin Islands, British'
  },
  {
    label: 'Virgin Islands, U.s.'
  },
  {
    label: 'Wallis And Futuna'
  },
  {
    label: 'Western Sahara'
  },
  {
    label: 'Yemen'
  },
  {
    label: 'Zambia'
  },
  {
    label: 'Zimbabwe'
  }
];

export default countries;
