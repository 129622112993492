import React, { useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Table,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoadingSpinner from '../../components/Loader';
import { LoadingScreen } from '../ProductAdmin/Profile';
import * as actions from './actions';
import moment from 'moment';
import _ from 'lodash';

const AdminStats = ({ adminStats, dispatchGetAdminStats, updatePending, product }) => {

  useEffect(() => {
    // dispatchGetAdminStats(product.slug);
  }, [product.slug]);

  if (!adminStats?.stats) return <LoadingSpinner />;

  return (
    <StyledProductAdmin fluid>
      {updatePending && <LoadingScreen><LoadingSpinner /></LoadingScreen>}

      <Row className="mt-4">
        <Col xl={12}>
          <Card>
            <CardBody>
              <h4 className="font-size-18 mb-3">Users</h4>
              <Table>
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Created</th>
                    <th>Last Seen</th>
                    <th>Interactions</th>
                  </tr>
                </thead>
                <tbody>
                  {adminStats.stats.users.map(user => (
                    <tr key={user.LastSeen}>
                      <td>{user.Email}</td>
                      <td>{user.UserCreateDate && moment(user.UserCreateDate.value).format("D MMM YYYY, HH:mm")}</td>
                      <td>{user.LastSeen && moment(user.LastSeen.value).format("D MMM YYYY, HH:mm")}</td>
                      <td>{user.interactionCount}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xl={3}>
          <Card>
            <CardBody>
              <h4 className="font-size-18 mb-3">Interaction Summmary</h4>
              <Table>
                <thead>
                  <tr>
                    <th>Event</th>
                    <th>Count</th>
                  </tr>
                </thead>
                <tbody>
                  {adminStats.stats.interactionSummary.map(int => (
                    <tr key={int.event}>
                      <td>{int.event}</td>
                      <td>{int.count}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>

        <Col xl={9}>
          <Card>
            <CardBody>
              <h4 className="font-size-18 mb-3">Interactions (since 20 Jan 2022)</h4>
              <Table>
                <thead>
                  <tr>
                    <th>Time</th>
                    <th>Event</th>
                    <th>Page</th>
                    <th>User</th>
                  </tr>
                </thead>
                <tbody>
                  {adminStats.stats.interactions.map((int, index) => (
                    <tr key={int.event_timestamp} className={index > 0 && moment(int.event_timestamp/1000).format("D") !== moment(adminStats.stats.interactions[index-1].event_timestamp/1000).format("D") ? 'divide' : undefined}>
                      <td>{moment(int.event_timestamp/1000).format("D MMM YYYY, HH:mm")}</td>
                      <td>{int.event}</td>
                      <td>{int.page}</td>
                      <td>{int.user}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </StyledProductAdmin>
  );
}

const StyledProductAdmin = styled.span`
  font-weight: normal;

  tr.divide {
    td {
      border-color: #aaa;
    }
  }

  td {
    word-wrap:break-word;
    white-space: normal;
    max-width: 300px;
  }
`;

const mapStateToProps = state => {
  const { updatePending, adminStats, product } = state.Product;
  return { updatePending, adminStats, product };
};

// const mapDispatchToProps = dispatch =>
//   bindActionCreators(
//     {
//       dispatchGetAdminStats: actions.getProductAdminStatsAction,
//     },
//     dispatch,
//   );

export default compose(
  connect(
    mapStateToProps,
    // mapDispatchToProps,
  ),
  withRouter,
)(AdminStats);
