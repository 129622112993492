import { lighten } from '@mui/material/styles'
import { colors } from "@mui/material"
import eiColors from "../eiTheme"

const EiPalette = (mode, skin, themeColor) => {
  // ** Vars
  // Main color appears to influence text and actions (buttons and links) — but not their at-rest/default states.
  const lightColor = '18,72,116'
  const darkColor = '195,225,233'
  const mainColor = mode === 'light' ? lightColor : darkColor

  const primaryGradient = () => {
    if (mode === 'light') {
      if (themeColor === 'primary') {
        return '#C6E8ED'
      } else if (themeColor === 'secondary') {
        return '#9C9FA4'
      } else if (themeColor === 'success') {
        return '#93DD5C'
      } else if (themeColor === 'error') {
        return '#FF8C90'
      } else if (themeColor === 'warning') {
        return '#FFCF5C'
      } else {
        return '#6ACDFF'
      }
    }
    if (themeColor === 'primary') {
      return '#124874'
    } else if (themeColor === 'secondary') {
      return '#9C9FA4'
    } else if (themeColor === 'success') {
      return '#93DD5C'
    } else if (themeColor === 'error') {
      return '#FF8C90'
    } else if (themeColor === 'warning') {
      return '#FFCF5C'
    } else {
      return '#6ACDFF'
    }
  }

  const defaultBgColor = () => {
    if (skin === 'bordered' && mode === 'light') {
      return '#FFF'
    } else if (skin === 'bordered' && mode === 'dark') {
      return lighten('#000F24', 0.1)
    } else if (mode === 'light') {
      return '#F4F5FA'
    } else return lighten('#000F24', 0.1)
  }

  return {
    customColors: {
      dark: darkColor,
      main: mainColor,
      light: lightColor,
      darkBg: lighten('#000F24', 0.1),
      lightBg: '#CEEEF8',
      primaryGradient: primaryGradient(),
      bodyBg: mode === 'light' ? '#F5F5F5' : lighten('#000F24', 0.1),
      tableHeaderBg: mode === 'light' ? '#EEEEEE' : lighten('#000F24', 0.06)
    },
    ei: {
      ...eiColors,
    },
    common: {
      black: '#000',
      white: '#FFF'
    },
    mode: mode,
    primary: {
      light: lighten(`rgb(${mainColor})`, mode === 'light' ? 0 : 0.5),
      main: '#0482A2',
      dark: '#036882',
      contrastText: '#fff',
      highlight: '#ff492c',
    },
    secondary: {
      light: '#9C9FA4',
      main: '#8A8D93',
      dark: '#777B82',
      contrastText: '#000'
    },
    success: {
      light: '#5DC464',
      main: '#2f9e41',
      dark: '#22873C',
      contrastText: '#FFF'
    },
    error: {
      light: '#E87157',
      main: '#d93625',
      dark: '#BA1D1B',
      contrastText: '#FFF'
    },
    warning: {
      light: '#DBA54B',
      main: '#c47c18',
      dark: '#A86211',
      contrastText: '#FFF'
    },
    info: {
      light: '#e1edf2',
      main: '#349ad4',
      dark: '#0f4165',
      contrastText: '#FFF'
    },
    grey: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#D5D5D5',
      A200: '#AAAAAA',
      A400: '#616161',
      A700: '#303030'
    },
    green: {
      ...colors.green,
      20: "#f2f7f2",
    },
    text: {
      primary: '#5b626b',
      secondary: '#757575',
      disabled: `rgba(${mainColor}, 0.38)`
    },
    divider: `rgba(${mainColor}, 0.12)`,
    background: {
      paper: mode === 'light' ? '#FFF' : lighten('#000F24', 0.115),
      default: defaultBgColor()
    },
    action: {
      active: `rgba(${mainColor}, 0.54)`,
      hover: `rgba(${mainColor}, 0.04)`,
      selected: `rgba(${mainColor}, 0.08)`,
      disabled: `rgba(${mainColor}, 0.3)`,
      disabledBackground: `rgba(${mainColor}, 0.18)`,
      focus: `rgba(${mainColor}, 0.12)`
    }
  }
}

export default EiPalette
