import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, Button } from "reactstrap";
import SimpleBar from "simplebar-react";

const HelpDropdown = ({ product, baseLink }) => {
  const [menu, setMenu] = useState(false);

  const toggle = () => {
    setMenu(!menu);
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          id="page-header-notifications-dropdown"
          tag="button"
        >
          <i className="ti-help-alt d-block"></i>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg p-0" right onClick={() => setMenu(false)}>

          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h5 className="m-0 font-size-16"> Need Help? </h5>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "300px" }}>

            <a href="https://edtechimpact.com/knowledge-hub" target="_blank" className="text-reset notification-item">
              <div className="media d-flex align-items-center">
                <div className="avatar-xs mr-3">
                  <span className="avatar-title bg-info rounded-circle font-size-16">
                    <i className="ti-info-alt"></i>
                  </span>
                </div>
                <div className="media-body">
                  <h6>Knowledge Hub</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">Strategies to help EdTech sellers succeed online</p>
                  </div>
                </div>
              </div>
            </a>

            <a href="https://edtechimpact.com/pricing" target="_blank" className="text-reset notification-item">
              <div className="media d-flex align-items-center">
                <div className="avatar-xs mr-3">
                  <span className="avatar-title bg-success rounded-circle font-size-16">
                    <i className="ti-rocket"></i>
                  </span>
                </div>
                <div className="media-body">
                  <h6>Plans and Pricing</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1"></p>
                  </div>
                </div>
              </div>
            </a>

            <a href="mailto:hello@edtechimpact.com" target="_blank" className="text-reset notification-item">
              <div className="media d-flex align-items-center">
                <div className="avatar-xs mr-3">
                  <span className="avatar-title bg-primary rounded-circle font-size-16">
                    <i className="ti-email"></i>
                  </span>
                </div>
                <div className="media-body">
                  <h6>Email</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">hello@edtechimpact.com</p>
                  </div>
                </div>
              </div>
            </a>

          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
}

export default HelpDropdown;
