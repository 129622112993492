import { Tooltip, Typography } from "@mui/material";

import moment from "moment";

import { formatDate } from "utils/date";

export default function DateTimeTooltip({ value, typographySx = {} }) {
  return (
    <Tooltip
      title={formatDate({ value: value, local: false, format: "longdate" })}
      placement="right"
      arrow
      sx={{ verticalAlign: "middle", padding: "2px" }}
    >
      <Typography
        variant="body2"
        component="time"
        datetime={moment(value).utc().toISOString()}
        style={{ textDecoration: "1px dotted underline", ...typographySx }}
      >
        {formatDate({ value: value, local: true, format: "datetime" })}
      </Typography>
    </Tooltip>
  )
}