export default {
  white: '#FFFFFF',
  darkGrey: '#6f6f6f',
  grey: '#a5a5a5',
  lightGrey: '#F5F5F5',
  navyBlue: '#124874',
  cgBlue: '#0482A2',
  seaBlue: '#B1D8E2',
  midBlack: '#989898',
  nearBlack: '#3C3C3C',
  red: '#931700',
  yellow: '#EFAC36',
  green: '#3A721D',
  orange: '#FF492C',
  green: '#DCEFCC',
  darkGreen: '#A7CE88',
  newOrange: '#F46B27',
  newGrey: '#595959'
};
