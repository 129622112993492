import React, { Component , useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Alert,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoadingSpinner from '../../components/Loader';
import reducer from './reducer';
import * as actions from './actions';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import countries from './data/countries';
import { AvForm, AvField } from "availity-reactstrap-validation";

export class ProductAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      jobTitle: '',
      organisation: '',
      phone: '',
      website: '',
      name: '',
      country: 'United Kingdom',
      numberOfCustomers: '',
      EIdemonstration: 'Yes',
    };
  }

  handleValidSubmit = () => (event, values) => {
    const product = {
      ...values,
      'name': `${values.firstName} ${values.lastName}`,
      'EIdemonstration': values['EIdemonstration'] ? 'yes' : 'no',
    };

    delete product.firstName;
    delete product.lastName;

    this.props.dispatchCreateProduct({product});
  }

  render() {
    const { match, products, product, pending, plans, reviewPreference, detailsPreference } = this.props;

    return (
      <Container>
        <Row>
          <Col lg={{size: 6, offset: 3}}>
            <h2 style={{marginTop: '2rem', textAlign: 'center'}}>Get started with your free account</h2>
            <p style={{textAlign: 'center', marginBottom: '1rem'}}>By signing up you agree to receive email communication about our service.</p>
          </Col>
        </Row>
        <Row>
          <Col lg={{size: 6, offset: 3}}>
            <Card className="overflow-hidden">
              <CardBody className="p-4">
                <div className="p-3">
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={this.handleValidSubmit()}
                  >
                    {this.state.error ? (
                      <Alert color="danger">
                        {this.state.error}
                      </Alert>
                    ) : null}
        
                    <div className="form-group">
                      <AvField
                        name="firstName"
                        label="First Name"
                        type="text"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <AvField
                        name="lastName"
                        label="Last Name"
                        type="text"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <AvField
                        name="jobTitle"
                        label="Job Title"
                        type="text"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <AvField
                        name="phone"
                        label="Phone Number"
                        type="text"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <AvField
                        name="organisation"
                        label="Organisation Name"
                        type="text"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <AvField
                        name="name"
                        label="Product Name"
                        type="text"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <AvField
                        name="website"
                        label="Website URL"
                        type="text"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <AvField
                        name="country"
                        label="Country"
                        type="select"
                        value="United Kingdom"
                        required
                      >
                        {countries.map(c => <option key={c.label}>{c.label}</option>)}
                      </AvField>
                    </div>
                    <div className="form-group">
                      <AvField
                        name="numberOfCustomers"
                        label="Number Of Customers Globally (Approx)"
                        type="text"
                        required
                      />
                    </div>
                    
                    <div className="form-group">
                      <AvField
                        type="checkbox"
                        name="EIdemonstration"
                        label="I would also like a free personal demo of EdTech Impact's paid plans."
                      />
                    </div>
        
                    <Row className="form-group">
                      <div className="col-12 text-right">
                        <button
                          className="btn btn-primary w-md waves-effect waves-light"
                          type="submit"
                        >
                          Register
                        </button>
                      </div>
                    </Row>
                  </AvForm>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const { updatePending, product } = state.Product;
  return { updatePending, product };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dispatchCreateProduct: actions.createProductAction,
    },
    dispatch,
  );

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
)(ProductAdmin);
