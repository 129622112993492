import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import SelectSearch from 'react-select-search';
import useAuth from "../../pages/Authentication/useAuth";

const SelectType = ({ match, history, resellers, products }) => {

  const { currentUser } = useAuth();

  if (!history.location || !currentUser || resellers?.length < 2) return null;

  const selectType = type => {
    if (match.params.slug === 'add-listing') {
      history.push(`/${type}/add-listing/profile`);

    } else {
      history.push(`/${type}`);
    }
  }

  const selection = [
    products?.length > 1 &&{name: 'Products', value: 'products'},
    resellers?.length > 1 && {name: 'Resellers', value: 'resellers'},
    resellers?.length > 1 && {name: 'Devices', value: 'devices'},
    currentUser?.isAdmin && {name: 'Brands', value: 'brands'},
  ];

  return (
    <SelectSearch
      options={selection.filter(item => !!item)}
      onChange={selectType}
      value={history.location.pathname.split('/')[1]}
    />
  )
}

const mapStateToProps = state => {
  const { products } = state.Product;
  const { resellers } = state.Reseller;
  const { devices } = state.Device;
  return { devices, resellers, products };
};

export default compose(
  connect(
    mapStateToProps,
  ),
  withRouter,
)(SelectType);
