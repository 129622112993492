/* eslint-disable no-param-reassign */
import React from 'react';
import StarRatingComponent from 'react-star-rating-component';
import Star from '../Star';
import styled from 'styled-components';

const Rating = ({
  name,
  value,
  style,
  className,
  size=20,
}) => {

  const displayValue = value > 4.5 ? 5 : value;
  return (
  <StyledRating style={style} className={`${className} `}>
    <StarRatingComponent
      name={name}
      value={displayValue}
      renderStarIcon={(index, val) =>  <Star type={index <= val ? 'full' : 'empty'} width={size} />}
      renderStarIconHalf={() => <Star type="half" width={size} />}
    />
  </StyledRating>
  );
}

const StyledRating = styled.div`
  label {
    margin-bottom: 0;
    line-height: 1;
  }
`;

Rating.defaultProps = {
  value: 0,
  onStarClick: () => {},
  onStarHover: () => {},
  onStarHoverOut: () => {},
  editing: false,
  style: { fontSize: '1.5rem' },
  className: '',
};

export default Rating;
