import { takeLatest, call, put, delay } from 'redux-saga/effects';
import * as actionTypes from './constants';
import * as actions from './actions';
import ApiService from '../../services/apiService';
import { toast } from "react-toastify";

const scrollToTop = () => {
  document.body.scrollTop = document.documentElement.scrollTop = 0;
}

export function* leadsRequest(action) {
  try {
    const { page, perPage } = action
    const leads = yield call([ApiService, 'getUnapprovedLeads'], { page, perPage });
    yield put(actions.leadsRequestSuccessAction(leads));
  } catch (err) {
    throw err;
  } finally {
    yield put(actions.leadsRequestCompleteAction());
  }
}

export function* deleteLead(action) {
  try {
    yield call([ApiService, 'deleteLead'], { TYPE: action.lead.TYPE });
    yield put(actions.deleteLeadSuccessAction(action.lead));
    toast.success("Deleted");
  } catch (err) {
    toast.error(err?.description || "Error");
  } finally {
    yield put(actions.deleteLeadCompleteAction());
  }
}

export function* rejectLead(action) {
  try {
    yield call([ApiService, 'deleteLead'], { TYPE: action.lead.TYPE, rejection: true },);
    yield put(actions.rejectLeadSuccessAction(action.lead));
    toast.success("Success");
  } catch (err) {
    toast.error(err?.description || "Error");
  } finally {
    yield put(actions.rejectLeadCompleteAction());
  }
}

export function* approveLead(action) {
  try {
    const lead = yield call([ApiService, 'approveLead'], action.lead, action.withMessage, action.leadValue, action.numberOfCredits, action.selectedAlternatives);
    yield put(actions.approveLeadSuccessAction(lead));
    toast.success("Approved");
    yield delay(1000)
    yield put(actions.leadsRequestAction());
  } catch (err) {
    toast.error(err?.description || "Error");
  } finally {
    yield put(actions.approveLeadCompleteAction());
  }
}

export function* getProductVisitorStats(action) {
  scrollToTop();
  try {
    const events = yield call([ApiService, 'getAnalyticsEvents'], action.payload);
    yield put(actions.getProductVisitorStatsSuccessAction(events));
  } catch (err) {
    toast.error(err?.description || "Error");
  } finally {
    yield put(actions.getProductVisitorStatsCompleteAction());
  }
}

export default function* watch() {
  yield takeLatest(actionTypes.GET_PRODUCT_VISITOR_STATS, getProductVisitorStats);
  yield takeLatest(actionTypes.LEADS_REQUEST, leadsRequest);
  yield takeLatest(actionTypes.DELETE_LEAD, deleteLead);
  yield takeLatest(actionTypes.REJECT_LEAD, rejectLead);
  yield takeLatest(actionTypes.APPROVE_LEAD, approveLead);
}
