import { createSelector } from 'reselect';

/**
 * Direct selector to the productAdmin state domain 
 */
export const root = state => state.Product;

/**
 * Other specific selectors
 */

export const selectProduct = createSelector(root, substate => substate.product);
export const selectPending = createSelector(root, substate => substate.pending);
export const selectOutcomes = createSelector(
  root,
  substate => substate.outcomes,
);
export const selectSubjects = createSelector(
  root,
  substate => substate.subject,
);
export const selectTypes = createSelector(
  root,
  substate => substate.type,
);

export const selectPlans = createSelector(root, substate => substate.plans);

export const productSlug = createSelector(
  selectProduct,
  product => product.slug,
);

export const selectProductEmails = createSelector(
  root,
  substate => substate.emails,
);

export const selectReviewPreference = createSelector(
  root,
  substate => substate.reviewPreference,
);

export const selectProducts = createSelector(
  root,
  substate => substate.products,
);

export const selectWidgetDetailsPreference = createSelector(
  root,
  substate => substate.widgetDetailsPreference,
);

export const selectPlansForApi = createSelector(selectPlans, plans =>
  plans.filter(({ selected }) => selected),
);

export const selectedOutcomesForApi = createSelector(selectOutcomes, outcomes =>
  outcomes.filter(({ selected }) => selected).map(({ id }) => ({ id })),
);

export const selectedSubjectsForApi = createSelector(selectSubjects, subjects =>
  subjects.filter(({ selected }) => selected).map(({ key, title }) => ({ id: key, type: title })),
);

export const selectedTypesForApi = createSelector(selectTypes, types =>
  types.filter(({ selected }) => selected).map(({ key, title }) => ({ id: key, type: title })),
);
