import styled from "styled-components";
import {
  Row,
  Col,
  Badge,
  ListGroupItem,
  ListGroup,
  List,
  Card,
  CardBody,
} from "reactstrap";
import RegistrationForm from "components/RegisterationCommonForm/RegistrationForm";

// image import
import logo from "../../assets/images/WISE programs_Accelerator high res.png";
import cardImage from "../../assets/images/Vikki-Garner.jpeg";

const listItems = [
  {
    title: "Build Trust",
    list: [
      "Collect reviews and ratings",
      "Respond to your customers",
      "Earn awards and recognition",
    ],
  },
  {
    title: "Gain Visibility",
    list: [
      "Rank higher in categories",
      "Increase Google search results",
      "Attract in-market buyers",
    ],
  },
  {
    title: "Capture Demand",
    list: [
      "Boost website traffic",
      "Leverage buyer intent data",
      "Generate qualified lead",
    ],
  },
];

const Registration = (props) => {
  return (
    <div>
      <Header className="custom-container">
        <img width={210} height={100} src={logo}></img>
      </Header>

      <HeroSection className="custom-container">
        <div className="content-container">
          <h1 className="heading">Start hiring today with a free trial</h1>
          <p className="sub-heading">
            Try EdTech Impact and access a targeted audience of 300k in-market
            buyers with this special time-limited offer exclusively for the WISE
            EdTech community. Prefer a guided demo? Request one
          </p>
        </div>
      </HeroSection>

      <Main>
        <svg
          className="styles__wave___2jPFV"
          viewBox="0 0 2208 242"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1630.1 -4.75345e-05C1765.29 0.00675548 1945.61 22.6524 2208 84.1071V242H0V217.195C127.823 212.39 215.928 179.393 306.371 145.52C404.14 108.903 504.642 71.2629 661 67.1072C744.391 67.1072 820.091 79.6844 891.918 91.6179C1012.16 111.596 1121.56 129.771 1238 84.1073C1240.9 82.9699 1243.79 81.8335 1246.68 80.6989C1352.45 39.1725 1452.19 0.00889955 1629.97 -4.75345e-05C1630.02 -4.95788e-05 1630.06 -4.95789e-05 1630.1 -4.75345e-05Z"
            fill="#0C546C"
          ></path>
        </svg>

        <div className="list-container">
          <div className="custom-container">
            <Row>
              <Col>
                <div style={{ marginTop: "20px" }}>
                  <p>
                    Offer details: 50% off the Starter and Pro subscription for
                    the first 3 months
                  </p>

                  <ListGroup className="list">
                    {listItems.map(({ title, list }, index) => (
                      <ListGroupItem key={index}>
                        <div className="d-flex align-items-center ">
                          <Badge color="primary" pill className="badge">
                            ○
                          </Badge>

                          {title}
                        </div>

                        {list.map((item, index) => {
                          return (
                            <List key={index}>
                              <li>{item}</li>
                            </List>
                          );
                        })}
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                  <Card className="contentCard">
                    <CardBody>
                      <div className="cardMainDiv">
                        <div>
                          <img src={cardImage} className="image" />
                        </div>

                        <div>
                          <p className="content">
                            "A valuable marketing tool that has provided us with
                            a new way for schools and colleges to connect with
                            our platform. We've generated new leads and sales
                            through our profile page.”
                          </p>

                          <p className="content">
                            — Vikki Garner| Head of Partnerships,Unifrog
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Col>

              <Col>
                <div className="form-container">
                  <RegistrationForm value={props} />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Main>

      <Footer>
        <div className="custom-container">
          <p className="conditionText">
            Terms & conditions -The offer is valid until 31.07.2022 A single
            coupon refers to a single EdTech product listed under specific
            category. For inquiries, please contact - hello@edtechimpact.com
          </p>
          <div className="horizontal-line" />

          <p className="text-container">© 2022 Crafted with by EdTech Impact</p>
        </div>
      </Footer>
    </div>
  );
};

const Header = styled.div`
  width: 100%;
  padding: 16px;
  font-size: 20px;
  font-weight: bold;

  &.custom-container {
    width: 100%;
    max-width: 1218px;
    margin: 0 auto;
  }
`;

const HeroSection = styled.section`
  padding: 54px 0;
  display: flex;
  justify-content: flex-start;

  .content-container {
    max-width: 41%;
  }

  .heading {
    font-size: 4rem;
    font-weight: 700;
    color: #00756a;
    line-height: 3.9rem;
  }

  .sub-heading {
    font-size: 1.2rem;
    line-height: 2rem;
  }

  &.custom-container {
    width: 100%;
    max-width: 1218px;
    margin: 0 auto;
    padding: 0 16px;
  }

  @media only screen and (max-width: 768px) {
    .content-container {
      max-width: 100%;
      padding: 3rem 0;
    }

    .heading {
      font-size: 3rem;
      line-height: 3rem;
      margin-bottom: 1rem;
    }

    .sub-heading {
      font-size: 1rem;
      line-height: 1.6rem;
    }
  }
`;

const Main = styled.main`
  .list-container {
    background-color: #0c546c;
    color: #fff;
    padding-bottom: 0.5rem;

    p {
      font-size: 1.4rem;
      font-weight: 500;
    }

    .custom-container {
      width: 100%;
      max-width: 1218px;
      margin: 0 auto;
      padding: 0 16px;
    }

    .form-container {
      width: 100%;
      /* height: 80rem; */
      background-color: #fff;
      margin-top: -29rem;
      box-shadow: 0 5px 29px 3px rgb(0 0 0 / 8%);
      border-radius: 0.28rem;
      color: #5b626b;
      margin-bottom: 20px;
    }

    .list {
      .badge {
        margin-right: 3px;
      }
      .list-group-item {
        background: transparent;
        border: none;

        div {
          font-size: 1.4rem;
        }

        li {
          font-size: 1.2rem;
          margin-left: 1.6rem;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .row {
      flex-direction: column-reverse;
    }

    .list-container {
      .form-container {
        margin-top: 1rem;
      }
    }

    .form-container {
      margin-bottom: 2rem;
    }
  }

  .contentCard {
    margin-top: 35px;

    .cardMainDiv {
      display: flex;
      justify-content: center;

      .image {
        width: 150px;
        height: 170px;
      }
      .content {
        padding-left: 10px;
        color: black;
        font-size: 18px;
        font-weight: normal;
      }
    }
  }

  @media only screen and (max-width: 968px) {
    .list-container {
      .form-container {
        margin-bottom: 4rem;
      }
    }
    .contentCard {
      margin-top: 10px;

      .cardMainDiv {
        display: flex;
        justify-content: center;

        .image {
          width: 150px;
          height: 170px;
        }
        .content {
          padding-left: 10px;
          color: black;
          font-size: 16px;
          font-weight: normal;
        }
      }
    }
  }
  @media only screen and (max-width: 500px) {
    .cardMainDiv {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .list-container {
      .list {
        .badge {
          display: none;
        }
        .headingText {
          font-size: 12px;
        }
        .list-group-item {
          li {
            font-size: 12px;
            margin-left: 0;
          }
        }
      }
    }
  }
`;

const Footer = styled.footer`
  background-color: #0d485c;
  padding: 2rem 0;

  .custom-container {
    width: 100%;
    max-width: 1218px;
    margin: 0 auto;
    padding: 0 16px;
  }

  .conditionText {
    color: white;
    font-size: 16x;
    text-align: center;
  }

  .horizontal-line {
    height: 2px;
    background-color: #fff;
    margin-bottom: 0.8rem;
  }

  .text-container {
    color: #fff;
    text-align: center;
  }
`;

export default Registration;
