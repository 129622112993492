import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoadingSpinner from '../../components/Loader';
import { LoadingScreen } from '../ProductAdmin/Profile';
import * as actions from './actions';
import { Stack } from '@mui/material';
import useAuth from '../../pages/Authentication/useAuth';
import { DataGridPro, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid-pro'

const EventsPage = ({ updatePending }) => {
  const { supabase } = useAuth()
  const [attendees, setAttendees] = useState()

  useEffect(() => {
    getAttendees(1);
  }, []);

  const getAttendees = async eventId => {
    const { data } = await supabase
      .from('user_events')
      .select(`
        *,
        users!user_events_user_id_fkey (
          id,
          email
        )
      `)
      .eq('event_id', eventId)
      .order('created_at', 'desc')
    
    setAttendees(data)
  }

  if (!attendees) return <LoadingSpinner />;

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 100,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 100,
    },
  ]

  const rows = attendees.map((attendee => {
    const { id, users, data } = attendee
    const row = {
      id: id,
      userId: users.id,
      email: users.email,
    }

    if (data) {
      Object.keys(data).forEach(key => {
        row[key] = data[key]?.join(',')

        const column = columns.find(col => col.field === key)

        if (!column) {
          columns.push({
            field: key,
            headerName: key,
            flex: 80,
          })
        }
      })
    }
    return row
  }))

  return (
    <StyledProductAdmin fluid>
      {updatePending && <LoadingScreen><LoadingSpinner /></LoadingScreen>}

      <Stack spacing={4}>
        <DataGridPro
          rows={rows}
          columns={columns}
          disableRowSelectionOnClick
          slots={{toolbar: () => (
            <GridToolbarContainer>
              <GridToolbarFilterButton />
              <GridToolbarExport />
            </GridToolbarContainer>
          )}}
        />
      </Stack>
    </StyledProductAdmin>
  );
}

const StyledProductAdmin = styled.div`
  font-weight: normal;
  padding-top: 1rem;
`;

const mapStateToProps = state => {
  const { updatePending, product } = state.Product;
  return { updatePending, product };
};

export default compose(
  connect(
    mapStateToProps,
  ),
  withRouter,
)(EventsPage);
