import * as actionTypes from './constants';

export function leadsRequestAction(payload = { page: 1, perPage: 20 }) {
  return {
    type: actionTypes.LEADS_REQUEST,
    page: payload.page ?? 1,
    perPage: payload.perPage ?? 20,
  };
}

export function leadsRequestSuccessAction(leads) {
  return {
    type: actionTypes.LEADS_REQUEST_SUCCESS,
    leads,
  };
}

export function leadsRequestCompleteAction() {
  return {
    type: actionTypes.LEADS_REQUEST_COMPLETE,
  };
}

export function rejectLeadAction(lead) {
  return {
    type: actionTypes.REJECT_LEAD,
    lead,
  };
}

export function rejectLeadSuccessAction(lead) {
  return {
    type: actionTypes.REJECT_LEAD_SUCCESS,
    lead,
  };
}

export function rejectLeadCompleteAction() {
  return {
    type: actionTypes.REJECT_LEAD_COMPLETE,
  };
}

export function deleteLeadAction(lead) {
  return {
    type: actionTypes.DELETE_LEAD,
    lead,
  };
}

export function deleteLeadSuccessAction(lead) {
  return {
    type: actionTypes.DELETE_LEAD_SUCCESS,
    lead,
  };
}

export function deleteLeadCompleteAction() {
  return {
    type: actionTypes.DELETE_LEAD_COMPLETE,
  };
}

export function approveLeadAction(lead, withMessage, leadValue, numberOfCredits, selectedAlternatives) {
  return {
    type: actionTypes.APPROVE_LEAD,
    lead,
    withMessage,
    leadValue,
    numberOfCredits,
    selectedAlternatives,
  };
}

export function approveLeadSuccessAction(lead) {
  return {
    type: actionTypes.APPROVE_LEAD_SUCCESS,
    lead,
  };
}

export function approveLeadCompleteAction() {
  return {
    type: actionTypes.APPROVE_LEAD_COMPLETE,
  };
}

export function getProductAdminStatsAction(slug) {
  return {
    type: actionTypes.GET_PRODUCT_ADMIN_STATS,
    slug,
  };
}

export function getProductAdminStatsSuccessAction(payload) {
  return {
    type: actionTypes.GET_PRODUCT_ADMIN_STATS_SUCCESS,
    payload,
  };
}

export function getProductAdminStatsCompleteAction() {
  return {
    type: actionTypes.GET_PRODUCT_ADMIN_STATS_COMPLETE,
  };
}

export function getProductVisitorStatsAction(payload) {
  return {
    type: actionTypes.GET_PRODUCT_VISITOR_STATS,
    payload,
  };
}

export function getProductVisitorStatsSuccessAction(payload) {
  return {
    type: actionTypes.GET_PRODUCT_VISITOR_STATS_SUCCESS,
    payload,
  };
}

export function getProductVisitorStatsCompleteAction() {
  return {
    type: actionTypes.GET_PRODUCT_VISITOR_STATS_COMPLETE,
  };
}
