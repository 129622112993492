import LanguageIcon from '@mui/icons-material/Language';
import { Tooltip } from '@mui/material';

const RegionsTooltip = ({ regions }) => {
  if (!regions || !regions?.length) return null

  return (
    <Tooltip
      title={
        <>
          {[...regions]
            .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
            .map((region, index) => (
              <span key={index}>
                {region}
                {index < regions.length - 1 && <br />}
              </span>
            ))
          }
        </>
      }
      arrow
    >
      <LanguageIcon fontSize="small" />
    </Tooltip>
  )
}

export default RegionsTooltip