import { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { authHost } from './Login';
import LoadingSpinner from '../../components/Loader';

const Logout = () => {
  useEffect(() => {
    Auth.signOut();
    window.productFruits?.services?.destroy()
    window.location.href = encodeURI(`${authHost}/auth/logout/`);
  }, [])

  return <LoadingSpinner />;
}

export default Logout
