import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import { authHost } from 'pages/Authentication/Login';

const SelectProduct = ({ products, type, match, history }) => {

  if (!products || products.length < 1) return null;

  const selectProduct = slug => {
    if (slug === 'add-listing') {
      if (type === 'products') {
        window.location.href = `${authHost}/account/product-onboarding/`
      } else {
        history.push(`/${type}/add-listing/profile`);
      }

    } else {
      history.push(`/${type}/${slug}/${match.params.section || 'dashboard'}/${match.params.subsection || ''}`);
    }
  }

  const productSelection = products
    .filter(product => !product.productMatch || !product.name)
    .map(p => ({name: p.name, value: p.slug}));

  return (
    <SelectSearch
      options={productSelection}
      onChange={selectProduct}
      search
      filterOptions={fuzzySearch}
      value={match.params.slug}
    />
  )
}

export default compose(
  withRouter,
)(SelectProduct);
