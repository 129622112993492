export const UPDATE_CONFIG = 'app/ResellerAdmin/UPDATE_CONFIG';
export const UPDATE_CONFIG_SUCCESS = 'app/ResellerAdmin/UPDATE_CONFIG_SUCCESS';
export const UPDATE_CONFIG_COMPLETE = 'app/ResellerAdmin/UPDATE_CONFIG_COMPLETE';

export const RESELLER_REQUEST = 'app/ResellerAdmin/RESELLER_REQUEST';
export const RESELLER_REQUEST_COMPLETE = 'app/ResellerAdmin/RESELLER_REQUEST_COMPLETE';
export const RESELLER_REQUEST_SUCCESS = 'app/ResellerAdmin/RESELLER_REQUEST_SUCCESS';

export const LEADS_REQUEST = 'app/ResellerAdmin/LEADS_REQUEST';
export const LEADS_REQUEST_COMPLETE = 'app/ResellerAdmin/LEADS_REQUEST_COMPLETE';
export const LEADS_REQUEST_SUCCESS = 'app/ResellerAdmin/LEADS_REQUEST_SUCCESS';

export const LEAD_REPLY = 'app/ResellerAdmin/LEAD_REPLY';
export const LEAD_REPLY_COMPLETE = 'app/ResellerAdmin/LEAD_REPLY_COMPLETE';
export const LEAD_REPLY_SUCCESS = 'app/ResellerAdmin/LEAD_REPLY_SUCCESS';

export const SET_LEAD_STATUS = 'app/ResellerAdmin/SET_LEAD_STATUS';
export const SET_LEAD_STATUS_SUCCESS = 'app/ResellerAdmin/SET_LEAD_STATUS_SUCCESS';
export const SET_LEAD_STATUS_COMPLETE = 'app/ResellerAdmin/SET_LEAD_STATUS_COMPLETE';

export const DELETE_LEAD = 'app/ResellerAdmin/DELETE_LEAD';
export const DELETE_LEAD_SUCCESS = 'app/ResellerAdmin/DELETE_LEAD_SUCCESS';
export const DELETE_LEAD_COMPLETE = 'app/ResellerAdmin/DELETE_LEAD_COMPLETE';

export const CONFIG_REQUEST = 'app/ResellerAdmin/CONFIG_REQUEST';
export const CONFIG_REQUEST_COMPLETE = 'app/ResellerAdmin/CONFIG_REQUEST_COMPLETE';
export const CONFIG_REQUEST_SUCCESS = 'app/ResellerAdmin/CONFIG_REQUEST_SUCCESS';

export const CREATE_RESELLER = 'app/ResellerAdmin/CREATE_RESELLER';
export const CREATE_RESELLER_COMPLETE = 'app/ResellerAdmin/CREATE_RESELLER_COMPLETE';
export const CREATE_RESELLER_SUCCESS = 'app/ResellerAdmin/CREATE_RESELLER_SUCCESS';

export const INVITES_REQUEST = 'app/ResellerAdmin/INVITES_REQUEST';
export const INVITES_REQUEST_COMPLETE = 'app/ResellerAdmin/INVITES_REQUEST_COMPLETE';
export const INVITES_REQUEST_SUCCESS = 'app/ResellerAdmin/INVITES_REQUEST_SUCCESS';

export const INVITES_UPLOAD = 'app/ResellerAdmin/INVITES_UPLOAD';
export const INVITES_UPLOAD_COMPLETE = 'app/ResellerAdmin/INVITES_UPLOAD_COMPLETE';
export const INVITES_UPLOAD_SUCCESS = 'app/ResellerAdmin/INVITES_UPLOAD_SUCCESS';

export const REVIEWS_REQUEST = 'app/ResellerAdmin/REVIEWS_REQUEST';
export const REVIEWS_REQUEST_COMPLETE = 'app/ResellerAdmin/REVIEWS_REQUEST_COMPLETE';
export const REVIEWS_REQUEST_SUCCESS = 'app/ResellerAdmin/REVIEWS_REQUEST_SUCCESS';

export const REVIEW_REPLY = 'app/ResellerAdmin/REVIEW_REPLY';
export const REVIEW_REPLY_COMPLETE = 'app/ResellerAdmin/REVIEW_REPLY_COMPLETE';
export const REVIEW_REPLY_SUCCESS = 'app/ResellerAdmin/REVIEW_REPLY_SUCCESS';

export const EVIDENCE_REQUEST = 'app/ResellerAdmin/EVIDENCE_REQUEST';
export const EVIDENCE_REQUEST_COMPLETE =
  'app/ResellerAdmin/EVIDENCE_REQUEST_COMPLETE';
export const EVIDENCE_REQUEST_SUCCESS = 'app/ResellerAdmin/EVIDENCE_REQUEST_SUCCESS';

export const EVIDENCE_TABLE_REQUEST = 'app/ResellerAdmin/EVIDENCE_TABLE_REQUEST';
export const EVIDENCE_TABLE_REQUEST_COMPLETE =
  'app/ResellerAdmin/EVIDENCE_TABLE_REQUEST_COMPLETE';
export const EVIDENCE_TABLE_REQUEST_SUCCESS =
  'app/ResellerAdmin/EVIDENCE_TABLE_REQUEST_SUCCESS';

export const SCHOOL_TYPE_CHANGE = 'app/ResellerAdmin/SCHOOL_TYPE_CHANGE';
export const SCHOOL_SIZE_CHANGE = 'app/ResellerAdmin/SCHOOL_SIZE_CHANGE';
export const RESET_STATE = 'app/ResellerAdmin/RESET_STATE';

export const RESELLERS_TOGGLE_FILTER = 'app/ResellerAdmin/RESELLERS_TOGGLE_FILTER';
export const RESELLERS_TOGGLE_SUBJECT = 'app/ResellerAdmin/RESELLERS_TOGGLE_SUBJECT';
export const RESELLERS_TOGGLE_FEATURE = 'app/ResellerAdmin/RESELLERS_TOGGLE_FEATURE';
export const RESELLERS_TOGGLE_TYPE = 'app/ResellerAdmin/RESELLERS_TOGGLE_TYPE';

export const SELECT_RESELLER = 'app/ResellerAdmin/SELECT_RESELLER';
export const SELECT_RESELLER_SUCCESS = 'app/ResellerAdmin/SELECT_RESELLER_SUCCESS';
export const SELECT_RESELLER_COMPLETE = 'app/ResellerAdmin/SELECT_RESELLER_COMPLETE';

export const RESELLERS_TOGGLE_CHECKBOX =
  'app/ResellerAdmin/RESELLERS_TOGGLE_CHECKBOX';
export const UPDATE_RESELLER = 'app/ResellerAdmin/UPDATE_RESELLER';
export const COMPLETE_UPDATE_RESELLER = 'app/ResellerAdmin/COMPLETE_UPDATE_RESELLER';
export const RESELLERS_SELECT_PLAN = 'app/ResellerAdmin/SELECT_PLAN';
export const EMAILS_CHANGE = 'app/ResellerAdmin/EMAILS_CHANGE';
export const REVIEW_CHANGE = 'app/ResellerAdmin/REVIEW_CHANGE';
export const WIDGET_DETAILS_CHANGE = 'app/ResellerAdmin/WIDGET_DETAILS_CHANGE';
export const EXPORT_REVIEWS = 'app/ResellerAdmin/EXPORT_REVIEWS';
export const EXPORT_ALL_REVIEWS = 'app/ResellerAdmin/EXPORT_ALL_REVIEWS';
export const EXPORT_ALL_RESELLERS = 'app/ResellerAdmin/EXPORT_ALL_RESELLERS';
export const EXPORT_COMPLETE = 'app/ResellerAdmin/EXPORT_COMPLETE';
export const UPLOAD_LOGO = 'app/ResellerAdmin/UPLOAD_LOGO';
export const UPLOAD_SCREENSHOT = 'app/ResellerAdmin/UPLOAD_SCREENSHOT';
export const UPLOAD_EVIDENCE = 'app/ResellerAdmin/UPLOAD_EVIDENCE';
export const DELETE_EVIDENCE = 'app/ResellerAdmin/DELETE_EVIDENCE';

export const schoolTypes = [
  { id: 1, title: 'Primary', value: 'Primary' },
  { id: 2, title: 'Secondary', value: 'Secondary' },
  { id: 3, title: 'International', value: 'International' },
];

export const schoolSizes = [
  {
    id: 1,
    title: '100 students or less',
    value: {
      min: 0,
      max: 100,
    },
  },
  {
    id: 2,
    title: '101-500 students',
    value: {
      min: 101,
      max: 500,
    },
  },
  {
    id: 3,
    title: '501-1000 students',
    value: {
      min: 501,
      max: 1000,
    },
  },
  {
    id: 4,
    title: '1000+ students',
    value: {
      min: 1001,
      max: 100000,
    },
  },
];
