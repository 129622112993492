// ** To use core palette, uncomment the below import
// import corePalette from 'src/@core/theme/palette'
import eiPalette from '../@ei/theme/palette'

// ** To use mode (light/dark), skin(default/bordered/semi-dark), direction(ltr/rtl), etc. for conditional styles, uncomment below line
import { useSettings } from '../@core/hooks/useSettings'

const UserThemeOptions = () => {
  // ** To use mode (light/dark), skin(default/bordered/semi-dark), direction(ltr/rtl), etc. for conditional styles, uncomment below line
  const { settings } = useSettings()

  // ** To use mode (light/dark), skin(default/bordered/semi-dark), direction(ltr/rtl), etc. for conditional styles, uncomment below line
  const { mode, skin, themeColor } = settings

  // ** To use core palette, uncomment the below line
  const palette = eiPalette(mode, skin, themeColor)

  return {
    palette,
    components: {
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            marginBottom: '0rem'
          }
        }
      },
    }
  }
}

export default UserThemeOptions
