import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import styled from 'styled-components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { bp } from '../../utils/mq';
import { Button } from 'reactstrap';

// Import other Dropdown
import LanguageDropdown from "../../components/LanguageDropdown";
import HelpDropdown from "../../components/HelpDropdown";
import ProfileMenu from "../../components/ProfileMenu";
import SelectType from "../../pages/Shared/SelectType";
import SelectProduct from "../../pages/Shared/SelectProduct";
import useFitText from "use-fit-text";
import useAuth from "../../pages/Authentication/useAuth";

const TopBar = ({ product: p, products: ps, reseller, resellers, device, devices, brand, brands, history, toggleMenuCallback, toggleRightSidebar }) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const { fontSize, ref } = useFitText();
  const { currentUser } = useAuth();
  const type = history.location.pathname.split('/')[1];
  const products = type === 'products' ? ps : type === 'resellers' ? resellers : type === 'brands' ? brands : devices;
  const product = type === 'products' ? p : type === 'resellers' ? reseller : type === 'brands' ? brand : device;

  const baseLink = `/${type}/${product?.slug}`;

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  }

  /**
   * Toggle sidebar
   */
  const toggleMenu = () => {
    toggleMenuCallback();
  }

  /**
   * Toggles the sidebar
   */
  const toggleRightbar = () => {
    toggleRightSidebar();
  }

  /**
   * Toggle full screen
   */
  const toggleFullscreen = () => {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  return (
    <React.Fragment>
      <StyledHeader id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex" style={{ fontSize: '1.4rem' }}>
            <div ref={ref} style={{ fontSize, height: 70, width: 240 }} className="navbar-brand-box">
              <Link to={`${baseLink}/dashboard`}>
                <img style={{ width: '35px', marginRight: '8px', borderRadius: '6px' }} src={product && product.logo && product.logo.url_78} />
                {product && product.name}
              </Link>
            </div>
            <button
              type="button"
              onClick={toggleMenu}
              className="btn btn-sm px-3 font-size-24 header-item waves-effect"
              id="vertical-menu-btn"
            >
              <i className="mdi mdi-menu"></i>
            </button>

            <div className="d-flex align-items-center mr-2 select-type">
              <SelectType />
            </div>

            {currentUser && products &&
              <div className="d-flex align-items-center select-product">
                <SelectProduct
                  type={type}
                  products={products.filter(p => currentUser.isAdmin || p.ownItem || p.slug === 'add-listing')}
                />
              </div>
            }
          </div>

          <div className="d-flex align-items-center">
            {product && !product.isPremium &&
              <a href="https://edtechimpact.com/pricing" target="_blank">
                <Button color="primary" className="d-none d-xl-flex mr-2">
                  Upgrade Plan
                </Button>
              </a>
            }

            <div className="dropdown d-none d-lg-inline-block">
              <button
                type="button"
                className="btn header-item noti-icon waves-effect"
                onClick={toggleFullscreen}
                data-toggle="fullscreen"
              >
                <i className="mdi mdi-fullscreen"></i>
              </button>
            </div>

            <HelpDropdown product={product} baseLink={baseLink} />

            <ProfileMenu />

          </div>
        </div>
      </StyledHeader>
    </React.Fragment>
  );
}

const StyledHeader = styled.header`
  .select-type > .select-search {
    width: 9rem;

    @media ${bp.sm} {
      width: 9rem;
    }
  }
  .select-product > .select-search {
    width: 10rem;

    @media ${bp.sm} {
      width: 25rem;
    }
  }

  .select-search:not(.has-focus) {
    .select-search__input {
      font-size: 1.1rem;
      font-weight: bold;
    }
  }
`;

const mapStateToProps = state => {
  const { product, products } = state.Product;
  const { reseller, resellers } = state.Reseller;
  const { device, devices } = state.Device;
  const { brand, brands } = state.Brand;
  return { product, products, reseller, resellers, device, devices, brand, brands };
};

export default compose(
  connect(
    mapStateToProps,
  ),
  withRouter,
)(TopBar);
