import * as actionTypes from './constants';

export function updateConfigAction(config) {
  return {
    type: actionTypes.UPDATE_CONFIG,
    payload: config,
  };
}

export function updateConfigSuccessAction(config) {
  return {
    type: actionTypes.UPDATE_CONFIG_SUCCESS,
    payload: config,
  };
}

export function updateConfigCompleteAction() {
  return {
    type: actionTypes.UPDATE_CONFIG_COMPLETE,
  };
}

export function brandRequestAction(brandName) {
  return {
    type: actionTypes.BRAND_REQUEST,
    payload: brandName,
  };
}

export function brandRequestCompleteAction() {
  return {
    type: actionTypes.BRAND_REQUEST_COMPLETE,
  };
}

export function brandRequestSuccessAction(brand) {
  return {
    type: actionTypes.BRAND_REQUEST_SUCCESS,
    payload: brand,
  };
}

export function invitesRequestAction(payload) {
  return {
    type: actionTypes.INVITES_REQUEST,
    payload,
  };
}

export function invitesRequestCompleteAction() {
  return {
    type: actionTypes.INVITES_REQUEST_COMPLETE,
  };
}

export function invitesRequestSuccessAction(invites) {
  return {
    type: actionTypes.INVITES_REQUEST_SUCCESS,
    payload: invites,
  };
}

export function invitesUploadAction(payload) {
  return {
    type: actionTypes.INVITES_UPLOAD,
    payload,
  };
}

export function invitesUploadSuccessAction(payload) {
  return {
    type: actionTypes.INVITES_UPLOAD_SUCCESS,
    payload,
  };
}

export function invitesUploadCompleteAction() {
  return {
    type: actionTypes.INVITES_UPLOAD_COMPLETE,
  };
}

export function reviewsRequestAction(payload) {
  return {
    type: actionTypes.REVIEWS_REQUEST,
    payload,
  };
}

export function reviewsRequestCompleteAction() {
  return {
    type: actionTypes.REVIEWS_REQUEST_COMPLETE,
  };
}

export function reviewsRequestSuccessAction(evidence) {
  return {
    type: actionTypes.REVIEWS_REQUEST_SUCCESS,
    payload: evidence,
  };
}

export function reviewReplyAction(payload) {
  return {
    type: actionTypes.REVIEW_REPLY,
    payload,
  };
}

export function reviewReplyCompleteAction() {
  return {
    type: actionTypes.REVIEW_REPLY_COMPLETE,
  };
}

export function reviewReplySuccessAction(review) {
  return {
    type: actionTypes.REVIEW_REPLY_SUCCESS,
    payload: review,
  };
}

export function schoolSizeChange(payload) {
  return {
    type: actionTypes.SCHOOL_SIZE_CHANGE,
    payload,
  };
}

export function schoolTypeChange(payload) {
  return {
    type: actionTypes.SCHOOL_TYPE_CHANGE,
    payload,
  };
}

export function brandsToggleFilterAction(payload) {
  return {
    type: actionTypes.BRANDS_TOGGLE_FILTER,
    payload,
  };
}

export function brandsToggleSubjectAction(payload) {
  return {
    type: actionTypes.BRANDS_TOGGLE_SUBJECT,
    payload,
  };
}

export function brandsToggleFeatureAction(payload) {
  return {
    type: actionTypes.BRANDS_TOGGLE_FEATURE,
    payload,
  };
}

export function brandsToggleTypeAction(payload) {
  return {
    type: actionTypes.BRANDS_TOGGLE_TYPE,
    payload,
  };
}

export function brandsToggleCheckboxAction(payload) {
  return {
    type: actionTypes.BRANDS_TOGGLE_CHECKBOX,
    payload,
  };
}

export function brandsSelectPlanAction(payload) {
  return {
    type: actionTypes.BRANDS_SELECT_PLAN,
    payload,
  };
}

export function selectBrand(slug) {
  return {
    type: actionTypes.SELECT_BRAND,
    slug,
  };
}

export function resetState() {
  return {
    type: actionTypes.RESET_STATE,
  };
}

export function selectBrandSuccess(brand) {
  return {
    type: actionTypes.SELECT_BRAND_SUCCESS,
    brand,
  };
}

export function selectBrandComplete() {
  return {
    type: actionTypes.SELECT_BRAND_COMPLETE,
  };
}

export function updateBrandAction({brand, screenshots, logo}) {
  return {
    type: actionTypes.UPDATE_BRAND,
    brand,
    screenshots,
    logo,
  };
}

export function completeUpdateBrand(brand) {
  return {
    type: actionTypes.COMPLETE_UPDATE_BRAND,
    brand,
  };
}

export function createBrandAction(brand) {
  return {
    type: actionTypes.CREATE_BRAND,
    brand,
  };
}

export function createBrandSuccessAction(brand) {
  return {
    type: actionTypes.CREATE_BRAND_SUCCESS,
    brand,
  };
}

export function createBrandCompleteAction(brand) {
  return {
    type: actionTypes.CREATE_BRAND_COMPLETE,
    brand,
  };
}

export function brandsEmailsChangeAction(emails) {
  return {
    type: actionTypes.EMAILS_CHANGE,
    emails,
  };
}

export function reviewChange(reviewPreference) {
  return {
    type: actionTypes.REVIEW_CHANGE,
    reviewPreference,
  };
}

export function widgetDetailsChange(detailsPreference) {
  return {
    type: actionTypes.WIDGET_DETAILS_CHANGE,
    detailsPreference,
  };
}

export function exportReviews() {
  return {
    type: actionTypes.EXPORT_REVIEWS,
  };
}

export function exportAllReviews() {
  return {
    type: actionTypes.EXPORT_ALL_REVIEWS,
  };
}

export function exportAllBrands() {
  return {
    type: actionTypes.EXPORT_ALL_BRANDS,
  };
}

export function exportComplete() {
  return {
    type: actionTypes.EXPORT_COMPLETE,
  };
}

export function uploadLogo(payload) {
  return {
    type: actionTypes.UPLOAD_LOGO,
    payload,
  };
}

export function uploadScreenshot(payload) {
  return {
    type: actionTypes.UPLOAD_SCREENSHOT,
    payload,
  };
}

export function configRequestAction() {
  return {
    type: actionTypes.CONFIG_REQUEST,
  };
}

export function configRequestSuccessAction(config) {
  return {
    type: actionTypes.CONFIG_REQUEST_SUCCESS,
    payload: config,
  };
}

export function configRequestCompleteAction() {
  return {
    type: actionTypes.CONFIG_REQUEST_COMPLETE,
  };
}
