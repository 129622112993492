import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import _ from 'lodash';
import { Collapse, Row, Col } from 'reactstrap';
import { capitalize } from './ProductLead';

const VisitorStats = ({ events, showTime, isAdmin }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [flag, setflag] = useState();
  const [latestEvent, setlatestEvent] = useState();

  useEffect(() => {
    const latestEvent = events?.length && events[0];

    if (latestEvent) {
      setlatestEvent(latestEvent);
      let { countrycode, countryregion } = latestEvent;
      countrycode = countrycode === 'GB' && countryregion ? `${countrycode}-${countryregion}` : countrycode;

      if (countrycode) {
        import(`svg-country-flags/svg/${countrycode.toLowerCase()}.svg`).then(setflag);
      }
    }
  }, [events]);

  if (!latestEvent?.org) return null;
  if (!isAdmin && latestEvent.org.data?.URN === 103110) return null; // Forest School: Used for testing

  const { org, country } = latestEvent;
  const eventsGroupedByDate = _.groupBy(events, 'date');
  const dates = Object.keys(eventsGroupedByDate);
  const orgName = org.data ? `${org.data.name || org.data.value}` : org.type;

  return (
    <>
      <Header isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <Col lg={5} className="d-inline-flex align-items-center">
          {orgName && orgName.split(',')[0]} {flag && <img src={flag.default} alt={country} />}
        </Col>
        <Col lg={2} className="d-none d-lg-block">{moment(latestEvent.timestamp).format(showTime ? "D MMM YYYY, HH:mm" : "D MMM YYYY")}</Col>
        <Col lg={2} className="d-none d-lg-block text-right">{events.length}</Col>
      </Header>

      <Body isOpen={isOpen} className="row">

        <Col lg={5}>
          <p className="org-details">
            <strong>
              {org.source === 'google' ? orgName : `${orgName}, ${country}`}
              {org.data?.id &&
                <sup className="font-weight-normal ml-2">
                  {org.source === 'edtech-impact' &&
                    <a href={`https://edtechimpact.com/schools/${org.data.id}`} target="_blank">
                      EdTech Impact ->
                    </a>
                  }
                  {org.source === 'google' &&
                    <span className="text-info">Google</span>
                  }
                </sup>
              }
            </strong>
          </p>

          {org.data &&
            <SchoolCharacteristics>
              {org.data['PhaseOfEducation (name)'] && org.data['PhaseOfEducation (name)'] !== 'Not applicable' && (
                <li>
                  Phase: {org.data['PhaseOfEducation (name)']}
                </li>
              )}

              {org.data.StatutoryLowAge &&
                <li>
                  Age: {org.data.StatutoryLowAge}-{org.data.StatutoryHighAge}
                </li>
              }

              {org.data.NumberOfPupils &&
                <li>
                  Pupils: {org.data.NumberOfPupils}
                </li>
              }

              {org.data.PercentageFSM && (
                <li>
                  FSM:{' '}
                  {Math.round(org.data.PercentageFSM)}%
                </li>
              )}

              {org.data.EALPercentage && (
                <li>
                  ESL: {Math.round(org.data.EALPercentage)}
                  %
                </li>
              )}

              {org.data.Progress8_2018 && (
                <li>
                  Progress 8: {org.data.Progress8_2018}
                </li>
              )}

              {org.data.MISSupplier && (
                <li>
                  MIS Supplier: {org.data.MISSupplier}
                </li>
              )}
            </SchoolCharacteristics>
          }
        </Col>

        <Col lg={7}>
          {dates.map(key => {
            const dateGroup = eventsGroupedByDate[key];
            const eventsGroupedByPage = _.groupBy(dateGroup, 'page');
            const pages = Object.keys(eventsGroupedByPage);

            return pages.map((key, index) => {
              const eventGroup = eventsGroupedByPage[key];
              const eventCount = eventGroup.length;
              const event = eventGroup[0];
              const pageViewCount = eventGroup.filter(event => event.event === 'view.page').length;
              const interactions = eventGroup.filter(event => event.event.includes('.product.'));

              let openingPhrase = '';
              let closingPhrase = '';
              let linkToPage = true;
              const baseSlug = event.page.split('/')[1];
              const slug = event.page.split('/')[2] || event.page.split('/')[1] || event.page.split('/')[0];

              const hyphenatedToWords = string => capitalize(string.split('-').join(' ')).replaceAll('%20',' ');
              let slugInWords = slug ? hyphenatedToWords(slug).replaceAll('Vs','vs') : 'Homepage';

              switch (baseSlug) {
                case 'verify-review':
                  return null;
                case 'review':
                  openingPhrase = 'Viewed a review';
                  break;
                case 'products':
                  openingPhrase = 'Viewed';
                  closingPhrase = 'profile';
                  break;
                case 'verify-your-review':
                  openingPhrase = 'Submitted review for';
                  linkToPage = false;
                  break;
                case 'categories':
                  openingPhrase = 'Viewed the';
                  closingPhrase = 'category';
                  if (event.page.split('/')[3]) {
                    slugInWords = `${slugInWords} (${capitalize(event.page.split('/')[3].split('-').join(' '))})`;
                  }
                  break;
                case 'subjects':
                  openingPhrase = 'Viewed the';
                  closingPhrase = 'subject page';
                  break;
                case 'compare':
                  openingPhrase = 'Compared';
                  break;
                case 'search':
                  openingPhrase = 'Searched for';
                  break;
                case '':
                  openingPhrase = 'Viewed the';
                  break;
                default:
                  openingPhrase = 'Viewed';
                  break;
              }

              return (
                <div className='d-flex'>
                  <div style={{flex: '0 0 6rem'}}>
                    <strong>{moment(event.timestamp).format(showTime ? "D MMM YYYY, HH:mm" : "D MMM YYYY")}</strong>
                  </div>
                  <div>
                    <div>
                      {openingPhrase} {linkToPage ? <a href={`https://edtechimpact.com${event.page}`} target="_blank">
                        {slugInWords}
                      </a> : slugInWords} {closingPhrase}
                      {pageViewCount > 1 ? <> - <strong>{pageViewCount} times</strong></> : ``}
                    </div>

                    <ul>
                      {interactions.map(event => {
                        const [action, category='', detail=''] = event.event.split('.');

                        if (category === 'evidence') {
                          return (
                            <li>
                              Viewed evidence: "{event.value}"
                            </li>
                          );
                        }

                        return (
                          <li>
                            {capitalize(`${action}ed`)} {hyphenatedToWords(detail)}
                          </li>
                        );
                      })}
                    </ul>

                    {/* {interactionCount > 0 &&
                      <div>
                        Interacted with {linkToPage ? <a href={`https://edtechimpact.com${event.page}`} target="_blank">
                          {slugInWords}
                        </a> : slugInWords} {closingPhrase}
                        <> - <strong>{interactionCount} {interactionCount > 1 ? 'times' : 'time'}</strong></>
                      </div>
                    } */}
                  </div>
                </div>
              );
            })
          })}
        </Col>
      </Body>
    </>
  );
}

const Header = styled(Row)`
  font-weight: normal;
  cursor: pointer;
    
  [class*="col"] {
    word-wrap: break-word;
    white-space: normal;
    font-weight: bold;
    font-size: 1rem;
    text-transform: capitalize;
    margin-bottom: 0.75rem;
  }

  // ${({isOpen}) => isOpen && `
  //   border-top: 1px solid;

  //   [class*="col"] {
  //     margin-top: 0.75rem;
  //   }
  // `}

  img {
    width: 22px;
    border: 1px solid #ddd;
    margin-left: 0.5rem;
  }
`;

const Body = styled(Collapse)`
  font-weight: normal;
  
  .org-details {
    text-transform: capitalize;
  }

  ul {
    margin: 0;
    padding-left: 1rem;
  }

  p {
    word-wrap:break-word;
    white-space: normal;
    border: 0;
    margin: 0.15rem 0;
  }
  
  ${({isOpen}) => isOpen && `
    border-bottom: 1px solid;
    margin-bottom: 1rem;

    [class*="col"] {
      margin-bottom: 1rem;
    }
  `}
`;

const SchoolCharacteristics = styled.ul``;

export default withRouter(VisitorStats);
