import React, { Component , Suspense, lazy } from 'react';
import {
  Container, Col, Card, CardBody,
} from 'reactstrap';
import { withRouter, Route, Redirect, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoadingSpinner from '../../../components/Loader';
import * as actions from '../actions';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useAuth from "../../Authentication/useAuth";
import VisitorStats from '../../SiteAdmin/VisitorStats';
import VendorEngagement from '../../SiteAdmin/VendorEngagement';
import Events from '../../SiteAdmin/Events';
import Notifications from 'pages/SiteAdmin/Notifications';

const EmailAdmin = lazy(() => import('../../SiteAdmin/EmailAdmin'));
const CategoriesAdmin = lazy(() => import('../../SiteAdmin/CategoriesAdmin'));
const CategoriesAdminLevel1 = lazy(() => import('../../SiteAdmin/CategoriesAdminLevel1'));
const CategoriesAdminLevel2 = lazy(() => import('../../SiteAdmin/CategoriesAdminLevel2'));
const ClickMap = lazy(() => import('../../SiteAdmin/ClickMap'));
const SiteAdmin = lazy(() => import('../../SiteAdmin/SiteAdmin'));
const LeadsAdmin = lazy(() => import('../../SiteAdmin/LeadsAdmin'));

export class Admin extends Component {

  componentDidMount = () => {
    const { config, dispatchConfigRequest } = this.props;
    !config && dispatchConfigRequest();
  };

  render() {
    const { config } = this.props;
    if (!config) return <LoadingSpinner />;

    return (
      <StyledSiteAdmin fluid>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover
        />

        <Suspense fallback={<LoadingSpinner />}>
          <Switch>
            <ProtectedRoute
              path="/admin/categories"
              exact
              render={() => <CategoriesAdmin />}
            />

            <ProtectedRoute
              path="/admin/categories-level-1"
              exact
              render={() => <CategoriesAdminLevel1 />}
            />
            <ProtectedRoute
              path="/admin/categories-level-2"
              exact
              render={() => <CategoriesAdminLevel2 />}
            />
            <ProtectedRoute
              path="/admin/click-map"
              exact
              render={() => <ClickMap />}
            />
            <ProtectedRoute
              path="/admin/leads"
              exact
              render={() => <LeadsAdmin />}
            />
            <ProtectedRoute
              path="/admin/emails"
              exact
              render={() => <EmailAdmin />}
            />
            <ProtectedRoute
              path="/admin"
              exact
              render={() => <SiteAdmin />}
            />
            <ProtectedRoute
              path="/admin/visitor-stats"
              exact
              render={() => <VisitorStats isAdmin />}
            />
            <ProtectedRoute
              path="/admin/accounts"
              exact
              render={() => <VendorEngagement isAdmin />}
            />
            <ProtectedRoute
              path="/admin/notifications"
              exact
              render={() => <Notifications isAdmin />}
            />
            <ProtectedRoute
              path="/admin/events"
              exact
              render={() => <Events isAdmin />}
            />
          </Switch>
        </Suspense>
      </StyledSiteAdmin>
    );
  }
}


const ProtectedRoute = props => {
  const { currentUser } = useAuth();
  if (!currentUser || !currentUser.isAdmin) return null;
  return <Route {...props} />;
}

const StyledSiteAdmin = styled(Container)`
  position: relative;
  margin-bottom: 3rem;
`;

const mapStateToProps = state => {
  const { config, product, products, updatePending } = state.Product;
  return { config, product, products, updatePending };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dispatchConfigRequest: actions.configRequestAction,
    },
    dispatch,
  );

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
)(Admin);
