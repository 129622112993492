import React, { Component } from "react";
import SettingMenu from "../Shared/SettingMenu";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap";

class FormRepeater extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      rows1: []
    };
  }
  handleAddRow = () => {
    const item = {
      name: ""
    };
    this.setState({
      rows: [...this.state.rows, item]
    });
  };

  handleAddRowNested = () => {
    const item1 = {
      name1: ""
    };
    this.setState({
      rows1: [...this.state.rows1, item1]
    });
  };
  handleRemoveRow = (e, idx) => {
    document.getElementById("addr" + idx).style.display = "none";
  };
  handleRemoveRowNested = (e, idx) => {
    document.getElementById("nested" + idx).style.display = "none";
  };

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row>
            <div className="col-12">
              <Card>
                <CardBody>
                  <h4 className="card-title">Example</h4>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr id="addr0" key="">
                        <td>
                          <Form
                            className="repeater"
                            encType="multipart/form-data"
                          >
                            <div data-repeater-list="group-a">
                              <Row data-repeater-item>
                                <Col lg="2" className="form-group">
                                  <Label for="name">School name &amp; country</Label>
                                  <Input
                                    type="text"
                                    id="name"
                                    name="untyped-input"
                                  />
                                </Col>

                                <Col lg="2" className="form-group">
                                  <Label for="subject">Date</Label>
                                  <Input type="text" id="subject" />
                                </Col>

                                <Col lg="2" className="form-group">
                                  <Label for="resume">File</Label>
                                  <Input type="file" id="resume" />
                                </Col>

                                <Col
                                  lg="2"
                                  className="form-group align-self-center"
                                >
                                  <Button
                                    onClick={this.handleRemoveRow}
                                    color="primary"
                                    className="mt-3"
                                    style={{ width: "100%" }}
                                  >
                                    {" "}
                                    Delete{" "}
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </Form>
                        </td>
                      </tr>

                      {this.state.rows.map((item, idx) => (
                        <tr id={"addr" + idx} key={idx}>
                          <td>
                            <Form
                              className="repeater"
                              encType="multipart/form-data"
                            >
                              <div data-repeater-list="group-a">
                                <Row data-repeater-item>
                                  <Col lg="2" className="form-group">
                                    <Label for="name">Name {idx + 1}</Label>
                                    <Input
                                      type="text"
                                      id="name"
                                      name="untyped-input"
                                    />
                                  </Col>

                                  <Col lg="2" className="form-group">
                                    <Label for="email">Email</Label>
                                    <Input type="email" id="email" />
                                  </Col>

                                  <Col lg="2" className="form-group">
                                    <Label for="subject">Subject</Label>
                                    <Input type="text" id="subject" />
                                  </Col>

                                  <Col lg="2" className="form-group">
                                    <Label for="resume">Resume</Label>
                                    <Input type="file" id="resume" />
                                  </Col>

                                  <Col lg="2" className="form-group">
                                    <Label for="message">Message</Label>
                                    <Input type="textarea" id="message" />
                                  </Col>
                                  <Col
                                    lg="2"
                                    className="form-group align-self-center"
                                  >
                                    <Button
                                      onClick={e =>
                                        this.handleRemoveRow(e, idx)
                                      }
                                      color="primary"
                                      className="mt-3"
                                      style={{ width: "100%" }}
                                    >
                                      {" "}
                                      Delete{" "}
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </Form>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Button onClick={this.handleAddRow} color="success">
                    Add{" "}
                  </Button>{" "}
                </CardBody>
              </Card>
            </div>
          </Row>
          </div>
      </React.Fragment>
    );
  }
}

export default FormRepeater;
