export const LEADS_REQUEST = 'app/SiteAdmin/LEADS_REQUEST';
export const LEADS_REQUEST_SUCCESS = 'app/SiteAdmin/LEADS_REQUEST_SUCCESS';
export const LEADS_REQUEST_COMPLETE = 'app/SiteAdmin/LEADS_REQUEST_COMPLETE';

export const DELETE_LEAD = 'app/SiteAdmin/DELETE_LEAD';
export const DELETE_LEAD_SUCCESS = 'app/SiteAdmin/DELETE_LEAD_SUCCESS';
export const DELETE_LEAD_COMPLETE = 'app/SiteAdmin/DELETE_LEAD_COMPLETE';

export const REJECT_LEAD = 'app/SiteAdmin/REJECT_LEAD';
export const REJECT_LEAD_SUCCESS = 'app/SiteAdmin/REJECT_LEAD_SUCCESS';
export const REJECT_LEAD_COMPLETE = 'app/SiteAdmin/REJECT_LEAD_COMPLETE';

export const APPROVE_LEAD = 'app/SiteAdmin/APPROVE_LEAD';
export const APPROVE_LEAD_SUCCESS = 'app/SiteAdmin/APPROVE_LEAD_SUCCESS';
export const APPROVE_LEAD_COMPLETE = 'app/SiteAdmin/APPROVE_LEAD_COMPLETE';

export const GET_PRODUCT_ADMIN_STATS = 'app/SiteAdmin/GET_PRODUCT_ADMIN_STATS';
export const GET_PRODUCT_ADMIN_STATS_SUCCESS = 'app/SiteAdmin/GET_PRODUCT_ADMIN_STATS_SUCCESS';
export const GET_PRODUCT_ADMIN_STATS_COMPLETE = 'app/SiteAdmin/GET_PRODUCT_ADMIN_STATS_COMPLETE';

export const GET_PRODUCT_VISITOR_STATS = 'app/SiteAdmin/GET_PRODUCT_VISITOR_STATS';
export const GET_PRODUCT_VISITOR_STATS_SUCCESS = 'app/SiteAdmin/GET_PRODUCT_VISITOR_STATS_SUCCESS';
export const GET_PRODUCT_VISITOR_STATS_COMPLETE = 'app/SiteAdmin/GET_PRODUCT_VISITOR_STATS_COMPLETE';
