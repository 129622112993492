import moment from 'moment'
import _ from "lodash"

/**
 * @param {string} value
 * @param {boolean} local
 * @param {boolean} friendly
 * @param {boolean} friendlyAbsolute Do not limit to last 24 hours or next 24 hours for friendly date. Instead, assume we _always_ want friendly dates.
 * @param {'date'|'datetime'|'time'|'shortdate'|'longdate'} format
 */
export const formatDate = ({ value, local = false, friendly = false, friendlyAbsolute = false, format = 'date', dateFirst = false, }) => {
  // local by default
  const formatted = (d) => {
    let dateFormat
    if (format === 'shortdate') {
      dateFormat = 'D MMM YY'
    } else if (format === 'date') {
      dateFormat = 'D MMM YYYY'
    } else if (format === 'datetime') {
      dateFormat = 'D MMM YYYY h:mm a'
    } else if (format === 'time') {
      dateFormat = `h:mm a`
    } else if (format === 'longdate') {
      // at 3pm on 12 July 2023
      dateFormat = '[at] h:mma [on] D MMMM YYYY'
    }
    return d.format(dateFormat)
  }
  
  const TWENTY_FOUR_HOURS_AGO = moment().subtract(24, 'hours')
  const TWENTY_FOUR_HOURS_LATER = moment().add(24, 'hours')
  const NOW = moment()

  // ! moment implicitly parses the date to the local timezone.
  let date = moment(value)
  if (!local) {
    // moment mutates the original object!
    date.utc()
    TWENTY_FOUR_HOURS_AGO.utc()
    TWENTY_FOUR_HOURS_LATER.utc()
  }

  if (friendly) {
    if (friendlyAbsolute) {
      if (date < NOW) {
        return date.from(NOW)
      }
      else if (date >= NOW) {
        return date.to(NOW)
      }
    }
    if (date.isBetween(TWENTY_FOUR_HOURS_AGO, TWENTY_FOUR_HOURS_LATER)) {
      // Date is between now and 24 hours ago.
      if (date > TWENTY_FOUR_HOURS_AGO && date < NOW) {
        return date.from(NOW)
      }
      // Date is between now and 24 hours later.
      else if (date < TWENTY_FOUR_HOURS_LATER && date >= NOW) {
        return date.to(NOW)
      }
    } else {
      return formatted(date)
    }
  }
  else {
    let dateFormat
    if (format === 'shortdate') {
      dateFormat = 'D MMM YY'
    } else if (format === 'date') {
      dateFormat = 'D MMM YYYY'
    } else if (format === 'datetime') {
      dateFormat = 'D MMM YYYY h:mma'
    } else if (format === 'time') {
      dateFormat = `h:mm a`
    } else if (format === 'longdate') {
      if (dateFirst) {
        // on 12 July 2023 at 3pm
        dateFormat = '[on] D MMMM YYYY [at] h:mma'
      } else {
        // at 3pm on 12 July 2023
        dateFormat = '[at] h:mma [on] D MMMM YYYY'
      }
    } else {
      dateFormat = format
    }
    if (!local) {
      dateFormat += ' [GMT]'
    }
    return date.format(dateFormat)
  }
}

/**
 * Sorts ascending i.e. older items to newer items.
 * @param {*} arr 
 * @param {*} datetimeKey 
 * @returns 
 */
export const sortByDate = (arr = [], datetimeKey = 'datetime') => {
  if (!arr.length) return []
  return _.cloneDeep(arr)
    .sort((a, b) => {
      if (moment(a[datetimeKey]) > moment(b[datetimeKey])) {
        return 1
      } else if (moment(a[datetimeKey]) < moment(b[datetimeKey])) {
        return -1
      } else return 0
    })
}

export const thisYear = new Date().getFullYear();