import * as actionTypes from './constants';

export function adjustLeadCredits(data) {
  return {
    type: actionTypes.LEAD_CREDITS_ADJUST,
    payload: data,
  };
}

export function adjustLeadCreditsSuccess(newLeadCredit) {
  return {
    type: actionTypes.LEAD_CREDITS_ADJUST_SUCCESS,
    payload: newLeadCredit,
  };
}

export function adjustLeadCreditsComplete() {
  return {
    type: actionTypes.LEAD_CREDITS_ADJUST_COMPLETE,
  };
}

export function getLeadCreditsHistory(queryParameters) {
  return {
    type: actionTypes.LEAD_CREDITS_HISTORY,
    payload: queryParameters,
  };
}

export function getLeadCreditsHistorySuccess(transactions) {
  return {
    type: actionTypes.LEAD_CREDITS_HISTORY_SUCCESS,
    payload: transactions,
  };
}

export function getLeadCreditsHistoryComplete() {
  return {
    type: actionTypes.LEAD_CREDITS_HISTORY_COMPLETE,
  };
}