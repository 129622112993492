import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import _ from 'lodash';
import moment from 'moment';

import { Box, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from "@mui/material/styles"

import { Delete } from '@mui/icons-material';
import { formatDate } from 'utils/date';
import useAuth, { getCurrentUser } from '../../pages/Authentication/useAuth';
import LoadingSpinner from '../../components/Loader';
import { LoadingScreen } from '../ProductAdmin/Profile';

const Notifications = ({ updatePending, isAdmin }) => {
  const { supabase } = useAuth()
  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    if (!supabase) return

    getNotifications();
  }, [supabase]);

  const getNotifications = async () => {
    const { data } = await supabase
      .from('internal-notifications')
      .select()
      .is('deleted_by', null)
      .eq('category', 'CUSTOMER_SUCCESS')
      .order("created_at", { ascending: false })

    if (data) {
      const groupedData = _.groupBy(data, (d) => moment(d.created_at).format("D MMM YYYY"))
      setNotifications(groupedData)
    }
  }

  const deleteNotification = async id => {
    const currentUser = await getCurrentUser()

    await supabase
      .from('internal-notifications')
      .update({ deleted_by: currentUser?.signInUserSession?.idToken?.payload?.supabaseUserId, })
      .eq('id', id)

    await getNotifications()
  }

  if (!isAdmin) return null

  return (
    <StyledProductAdmin fluid>
      {updatePending && <LoadingScreen><LoadingSpinner /></LoadingScreen>}

      <Typography variant="h2">Notifications</Typography>
      <Stack spacing={8}>
        {Object.keys(notifications).map(day => {
          return (
            <Box key={day}>
              <Typography
                component="h3"
                variant="h4"
              >{formatDate({ value: day, format: "D MMM YYYY, dddd" })}</Typography>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} >
                  <TableHead>
                    <TableRow>
                      <TableCell width="10%">Time</TableCell>
                      <TableCell width="75%">Message</TableCell>
                      <TableCell width="15%">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {notifications[day].map(note => {
                      return (
                        <StyledTableRow key={note.id}>
                          <TableCell>
                            <Typography>{formatDate({ value: note.created_at, format: "time" })}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              dangerouslySetInnerHTML={{
                                __html: `${note.message}`
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton
                              color="error"
                              aria-label="delete"
                              onClick={async () => await deleteNotification(note.id)}
                            ><Delete /></IconButton>

                          </TableCell>
                        </StyledTableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )
        })}
      </Stack>
    </StyledProductAdmin >
  );
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledProductAdmin = styled("div")`
  font-weight: normal;
  padding-top: 1rem;
`;

export default withRouter(Notifications);
