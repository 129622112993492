import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";

const ContactDetailsDropdown = ({email, phone, className=''}) => {
  const [menu, setMenu] = useState(false);

  const toggle = () => {
    setMenu(!menu);
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className={`dropdown d-inline-block ${className}`}
        tag="li"
      >
        <DropdownToggle
          className="btn btn-outline noti-icon waves-effect d-flex align-items-center height-auto"
          id="page-header-notifications-dropdown"
          tag="button"
        >
          <i className="ti-email d-inline-block font-size-18"></i>
          <span className="ml-2 font-size-14">View Contact Details</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg p-0" bottom right>
          <SimpleBar>
  
            <div href={`mailto:${email}`} target="_blank" className="text-reset notification-item">
              <div className="media d-flex align-items-center">
                <div className="avatar-xs mr-3">
                  <span className="avatar-title bg-primary rounded-circle font-size-16">
                    <i className="ti-email"></i>
                  </span>
                </div>
                <div className="media-body">
                  <h6>Email</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">{email}</p>
                  </div>
                </div>
              </div>
            </div>

            {phone &&
              <div href={`tel:${phone}`} target="_blank" className="text-reset notification-item">
                <div className="media d-flex align-items-center">
                  <div className="avatar-xs mr-3">
                    <span className="avatar-title bg-primary rounded-circle font-size-16">
                      <i className="ti-user"></i>
                    </span>
                  </div>
                  <div className="media-body">
                    <h6>Phone</h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">{phone}</p>
                    </div>
                  </div>
                </div>
              </div>
            }

          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
}

export default ContactDetailsDropdown;
