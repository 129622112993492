import { styled } from '@mui/material/styles';
import { Tooltip, Link, SvgIcon, tooltipClasses } from '@mui/material';
import { Lock } from 'mdi-material-ui';

import { useTracking } from 'react-tracking';

export default function PremiumFeature({
  headline = 'Premium Feature',
  title = 'This feature requires a subscription.',
  href = 'https://edtechimpact.com/pricing',
  placement = 'right',
  hideText = false,
  additionalStyle = {}
}) {
  const { trackEvent } = useTracking();

  return (
    <BootstrapTooltip disableFocusListener placement={placement} title={title}>
      <PremiumLink href={href} target='_blank' sx={hideText ? { padding: '3px !important', ...additionalStyle } : { padding: '3px 6px !important', ...additionalStyle }} onClick={() => trackEvent({ event: "click.internal.acquisition.premium-feature" })}>
        <SvgIcon component={Lock} fontSize='inherit' color='inherit' /> {hideText ? null : headline}
      </PremiumLink>
    </BootstrapTooltip>
  );
}

const PremiumLink = styled(Link)`
  background-color: #0482a2 !important;
  color: white !important;
  border-radius: .25rem;
  font-weight: normal;
  font-size: .8rem;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  
  transition: background-color .25s ease-in;

  display: inline-flex;
  flex-direction: row;
  gap: .25rem;
  align-items: center;

  &:hover {
    transition: background-color .25s ease-out;
    background-color: #024657 !important;
    color: white !important;
  }
`;

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));