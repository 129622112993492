import React, { Component , useState } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import Immutable from 'seamless-immutable';
import classnames from 'classnames';
import { withRouter, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertFromHTML, convertToHTML } from 'draft-convert';
import Loader from '../../components/Loader';
import axios from 'axios';
import reducer from './reducer';
import * as actions from './actions';
import SelectSearch from 'react-select-search';
import { videoToString } from '../../utils/helpers';
import RUG, { DropArea, DragArea } from 'react-upload-gallery'
import 'react-upload-gallery/dist/style.css' // or scss
import FormRepeater from '../Forms/FormRepeater';
import slugify from 'slugify';
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import countries from '../ProductAdmin/data/countries';
import PremiumFeature from '../../components/PremiumFeature'
import { withTracking } from 'react-tracking';
import { toast } from "react-toastify";
import { Alert } from '@mui/material';

const wysiwygFields = ['supportDetails', 'availableToParentsDetails', 'story', 'usage', 'training', 'trialSetUp', 'techRequirements', 'additionalPricingInformation'];
const initialState = {
  tags: [],
  heroFeatures: [],
  activeTab: '1',
  demoLink: '',
  story: '',
  shortDescription: '',
  website: '',
  dataPrivacy: '',
  specialOffer: '',
  techRequirements: '',
  requirements: [
    { title: 'Available Offline', selected: false },
    { title: 'Internet - Low Bandwidth', selected: false },
    { title: 'Internet - High Bandwidth', selected: false },
    { title: 'Desktop - Mac', selected: false },
    { title: 'Desktop - Windows', selected: false },
    { title: 'Desktop - Chromebook', selected: false },
    { title: 'Desktop - Linux', selected: false },
    { title: 'Mobile - iPhone', selected: false },
    { title: 'Mobile - iPad', selected: false },
    { title: 'Mobile - Windows', selected: false },
    { title: 'Mobile - Android', selected: false },
    { title: 'Radio', selected: false },
    { title: 'Television', selected: false },
  ],
  localisations: [
    { title: 'United Kingdom', selected: false },
  ],
  techSupport: [
    {
      title: 'Email/Help Desk',
      selected: false,
    },
    {
      title: 'FAQs/Forum',
      selected: false,
    },
    {
      title: 'Knowledge Base',
      selected: false,
    },
    {
      title: 'Phone Support',
      selected: false,
    },
    {
      title: '24/7 Live Rep',
      selected: false,
    },
  ],
  terms: '',
  training: '',
  trainingSelection: [
    { title: 'In Person', selected: false },
    { title: 'Live Online', selected: false },
    { title: 'Webinars', selected: false },
    { title: 'Documentation', selected: false },
    { title: 'Videos', selected: false },
  ],
  trialSetUp: '',
  usage: '',
  logo: null,
  images: null,
  purchaseModels: [
    {
      key: 'Outright Purchase',
      title: 'Outright Purchase',
      selected: false,
    },
    {
      key: 'Operating Lease',
      title: 'Operating Lease',
      selected: false,
    },
    {
      key: 'Parental Contributions',
      title: 'Parental Contributions',
      selected: false,
    },
    {
      key: 'GiftAid',
      title: 'GiftAid',
      selected: false,
    },
  ],
  ageRange: [
    {
      key: '0-4',
      title: '0-4 years old',
      selected: false,
    },
    {
      key: '5-7',
      title: '5-7 years old',
      selected: false,
    },
    {
      key: '8-10',
      title: '8-10 years old',
      selected: false,
    },
    {
      key: '11-13',
      title: '11-13 years old',
      selected: false,
    },
    {
      key: '14-16',
      title: '14-16 years old',
      selected: false,
    },
    {
      key: '17-18',
      title: '17-18 years old',
      selected: false,
    },
    {
      key: '19+',
      title: '19 and older',
      selected: false,
    },
  ],
  supervision: false,
  additionalPricingInformation: '',
  video: '',
  name: '',
  priceStartingFrom: {},
  pricingPage: '',
  twitter: '',
  linkedIn: '',
};

const currentYear = (new Date()).getFullYear();
const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));

export class ResellerAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount = () => {
    const { reseller } = this.props;

    if (reseller) {
      this.setStateFromProps(reseller);
    }
  };

  componentWillReceiveProps(nextProps) {
    const { reseller } = nextProps;

    if (!this.props.reseller && reseller || (reseller && reseller.slug !== this.props.reseller.slug)) {
      // this.props.dispatchResetState();
      this.setStateFromProps(reseller);
    }
  }

  setStateFromProps = reseller => {
    
    this.setState(initialState, () => {
      
      Object.keys(reseller).forEach(property => {

        if (['purchaseModels'].includes(property)) {
          const purchaseModels = reseller.purchaseModels.map(model => {
            if (['Monthly Subscription', 'Annual Subscription'].includes(model)) return 'Paid Subscription';
            return model;
          })

          const newProperty = this.state[property].map(x => {
            if (purchaseModels && purchaseModels.includes(x.key)) return {...x, selected: true};
            return {...x, selected: false};
          });
          this.setState({[property]: newProperty});

        } else if (['requirements', 'localisations', 'trainingSelection'].includes(property)) {
          const newProperty = this.state[property].map(x => {
            if (reseller[property] && reseller[property].includes(x.title)) return {...x, selected: true};
            return {...x, selected: false};
          });
          this.setState({[property]: newProperty});
    
        } else if (['ageRange'].includes(property)) {

          const newProperty = this.state[property].map(x => {
            if (reseller[property] && reseller[property].includes(x.key)) return {...x, selected: true};
            return {...x, selected: false};
          });
          this.setState({[property]: newProperty});

        } else if (wysiwygFields.includes(property)) {
          this.setState({[property]: EditorState.createWithContent(convertFromHTML(reseller[property]))});

        } else if (property === 'techSupport') {
          const techSupport = this.state.techSupport.map(item => {
            const support = Array.isArray(reseller[property]) ? reseller[property] : reseller[property].split(',');
            if (support.map(item => item.trim()).includes(item.title)) return {...item, selected: true};
            return {...item, selected: false};
          });
          this.setState({techSupport});

        } else if (['true', 'false', true, false].includes(reseller[property])) {
          this.setState({[property]: ['true', true].includes(reseller[property]) ? 'Yes' : 'No'});

        } else if (property === 'CTAButtonText') {
            this.setState({[property]: reseller[property].toLowerCase()});

        } else if (property === 'video') {
          this.setState({video: videoToString(reseller.video)});

        } else if (['logo'].includes(property)) {
          this.setState({[property]: [reseller[property].url_126]});

        } else if (['heroFeatures', 'tags'].includes(property)) {
          this.setState({[property]: Immutable.asMutable(reseller[property])});

        } else {
          this.setState({[property]: reseller[property]});
        }
      });

      if (!reseller.images) this.setState({images: []});
      if (!reseller.logo) this.setState({logo: []});
      if (this.props.location.pathname.includes('/add-listing/')) this.setState({name: ''});
    });
  }

  handleCheckboxClick = (property, key, keyProperty='key') => () => {
    const newProperty = this.state[property].map(x => {
      if (x[keyProperty] === key) return {...x, selected: !x.selected};
      return x;
    });
    this.setState({[property]: newProperty});
  }
 
  processResellerForSubmit = reseller => {
    const profileFields = [
      "name",
      "story",
      "shortDescription",
      "dataPrivacy",
      "terms",
      "purchaseModels",
      "website",
      "homepage",
      "twitter",
      "linkedIn",
      "CTAButtonText",
      "demoLink",
      "video",
      "additionalPricingInformation",
      "techRequirements",
      "techSupport",
      "training",
      "trainingSelection",
      "trialSetUp",
      "specialOffer",
      "priceStartingFrom",
      "pricingPage",
      "subjects",
      "requirements",
      "availableToParents",
      "initiator",
      "accessibility",
      "localisations",
      "heroFeatures",
      "founded",
      "country",
      "gdpr",
      "supportDetails",
      "availableToParentsDetails",
      "tags",
    ];

    for (const property in reseller) {
      if (!profileFields.includes(property)) {
        delete reseller[property];
      
      } else if (property === 'priceStartingFrom' && (!reseller[property].currency || !reseller[property].amount || !reseller[property].period)) {
        delete reseller.priceStartingFrom;

      } else if (reseller[property] && wysiwygFields.includes(property)) {
        let html = convertToHTML(reseller[property].getCurrentContent());
        if (html === '<p></p>') { html = ''};
        reseller[property] = html;

      } else if (['ageRange', 'purchaseModels', 'subjects'].includes(property)) {
        reseller[property] = reseller[property].filter(x => x.selected).map(x => x.key);

      } else if (['techSupport', 'requirements', 'localisations', 'trainingSelection'].includes(property)) {
        reseller[property] = reseller[property].filter(x => x.selected).map(x => x.title);

      } else if (!['numOfReviews'].includes(property) && ['Yes', 'No'].includes(reseller[property])) {
        reseller[property] = reseller[property] === 'Yes';
      }
    }
    return reseller;
  }

  handleSubmit = () => async () => {
    const reseller = {...this.state};
    const { logoImages, screenshots } = reseller;
    
    const logo = (logoImages[0] && logoImages[0].file) ? logoImages[0] : null;
    const images = screenshots[0] &&
      (screenshots.reduce((hasChanges, image) => (hasChanges || !!image.file), false))
      || (reseller.images && (screenshots.length < reseller.images.length)) ? screenshots : null;

    const processedReseller = this.processResellerForSubmit(reseller);

    if (!processedReseller) {
      toast.error("Error. Fields must not contain links.");
      return;
    }

    if (this.props.location.pathname.includes('/add-listing/')) {
      this.props.dispatchCreateReseller(processedReseller);
      this.props.tracking.trackEvent({event: 'create-reseller'});

    } else {
      this.props.dispatchSubmit({
        reseller: processedReseller,
        screenshots: images,
        logo,
      });
    }
  };

  renderTechSupport = () =>
    this.state.techSupport.map(({ title, selected }) => {
      const key = slugify(title);
      return (
        <FormGroup check key={key}>
          <Input
            id={key}
            type="checkbox"
            checked={selected}
            onChange={this.handleCheckboxClick('techSupport', title, 'title')}
          />
          <Label check for={key}>
            <LabelText>{title}</LabelText>
          </Label>
        </FormGroup>
    )});

  renderRequirements = () =>
    this.state.requirements.map(({ title, selected }) => {
      const key = slugify(title);
      return (
        <FormGroup check key={key}>
          <Input
            id={key}
            type="checkbox"
            checked={selected}
            onChange={this.handleCheckboxClick('requirements', title, 'title')}
          />
          <Label check for={key}>
            <LabelText>{title}</LabelText>
          </Label>
        </FormGroup>
      );
    });

  renderTrainingSelection = () =>
    this.state.trainingSelection.map(({ title, selected }) => {
      const key = slugify(title);
      return (
        <FormGroup check key={key}>
          <Input
            id={key}
            type="checkbox"
            checked={selected}
            onChange={this.handleCheckboxClick('trainingSelection', title, 'title')}
          />
          <Label check for={key}>
            <LabelText>{title}</LabelText>
          </Label>
        </FormGroup>
      );
    });

  renderLocalisations = () =>
    this.state.localisations.map(({ title, selected }) => {
      const key = slugify(title);
      return (
        <FormGroup check key={key}>
          <Input
            id={key}
            type="checkbox"
            checked={selected}
            onChange={this.handleCheckboxClick('localisations', title, 'title')}
          />
          <Label check for={key}>
            <LabelText>{title}</LabelText>
          </Label>
        </FormGroup>
      );
    });

  renderPurchaseModels = () =>
    this.state.purchaseModels.map(({ key, title, selected }) => (
      <FormGroup check key={key}>
        <Input
          id={key}
          type="checkbox"
          checked={selected}
          onChange={this.handleCheckboxClick('purchaseModels', key)}
        />
        <Label check for={key}>
          <LabelText>{title}</LabelText>
        </Label>
      </FormGroup>
    ));

  renderAges = () =>
    this.state.ageRange.map(({ key, title, selected }) => (
      <FormGroup check key={key}>
        <Input
          id={key}
          type="checkbox"
          checked={selected}
          onChange={this.handleCheckboxClick('ageRange', key)}
        />
        <Label check for={key}>
          <LabelText>{title}</LabelText>
        </Label>
      </FormGroup>
    ));

  renderCheckbox = ({ key, title }) => (
    <FormGroup check key={key}>
      <Input
        id={key}
        type="checkbox"
        checked={this.state[key]}
        onChange={() => this.setState({[key]: !this.state[key]})}
      />
      <Label check for={key}>
        <LabelText>{title}</LabelText>
      </Label>
    </FormGroup>
  );

  handleReviewChange = () => (event) => {
    this.props.dispatchReviewChange(event.target.value);
  }

  handleDetailsChange = () => (event) => {
    this.props.dispatchDetailsChange(event.target.value);
  }

  handleFieldChange = field => value => {
    this.setState({[field]: value});
  }

  render() {
    const { match, resellers, reseller, createPending, updatePending, reviewPreference, detailsPreference } = this.props;
    const {
      demoLink,
      story,
      shortDescription,
      website,
      homepage,
      twitter,
      linkedIn,
      dataPrivacy,
      specialOffer,
      techRequirements,
      terms,
      training,
      trialSetUp,
      CTAButtonText,
      supervision,
      additionalPricingInformation,
      video,
      plan,
      logo,
      images,
      name,
      priceStartingFrom,
      pricingPage,
      availableToParents,
      initiator,
      accessibility,
      heroFeatures,
      founded,
      country,
      gdpr,
      supportDetails,
      availableToParentsDetails,
      tags,
    } = this.state;

    const isPremium = this.props.reseller.plan && this.props.reseller.plan !== 'free';

    return (
      <StyledResellerAdmin fluid>
        {(updatePending || createPending) && <LoadingScreen><Loader /></LoadingScreen>}

        <Row className="align-items-center">
          <Col lg={7}>
            <div className="page-title-box">
              <h4 className="font-size-18">Reseller Details</h4>
              <p>
              </p>
            </div>
          </Col>
        </Row>
         
              <Question
                title="Reseller Name"
                description="The name of the reseller."
                Input={() => <Input required value={name} onChange={(e) => this.setState({name: e.target.value})} type="text" />}
              />

              {logo &&
                <Question
                  title="Logo"
                  description="Please use a SQUARE image (130x130px is perfect)."
                  Input={() => (
                    <RUG
                      autoUpload={false}
                      onChange={(images) => {
                        this.setState({ logoImages: images }) // save current component
                      }}
                      rules={{
                        limit: 1,
                        size: 2048,
                      }}
                      initialState={logo.length ? [{source: logo[0], name: ''}] : undefined}
                      header={({ openDialogue }) => (
                        <DropArea>
                          {(isDrag) => (
                            <DropZone onClick={openDialogue}>
                              Drag or click to select file
                            </DropZone>
                          )}
                        </DropArea>
                      )}
                    />
                  )}
                />
              }

              <Question
                title="Company Website"
                description="Your company homepage e.g. edtechimpact.com"
                Input={() => <Input value={homepage} onChange={(e) => this.setState({homepage: e.target.value})} type="text" placeholder="https://" />}
              />

              <Question
                title="Twitter"
                description="Your twitter handle e.g. @EdTechImpact"
                Input={() => <Input value={twitter} onChange={(e) => this.setState({twitter: e.target.value})} type="text" placeholder="https://" />}
              />

              <Question
                title="LinkedIn"
                description="Your linkedIn page e.g. linkedin.com/company/edtechimpact"
                Input={() => <Input value={linkedIn} onChange={(e) => this.setState({linkedIn: e.target.value})} type="text" placeholder="https://" />}
              />

              <Question
                title="Short description"
                description="A one-line elevator pitch that sells your reseller."
                Input={() => <Input value={shortDescription} onChange={(e) => this.setState({shortDescription: e.target.value})} style={{height: '4rem'}} type="textarea" />}
              />
              
              <Question
                title="Full Description"
                description="Imagine you are speaking to someone that isn’t familiar with your reseller at all. Be specific and concise. Use paragraphs."
                Input={() => <Editor
                  editorState={story}
                  onEditorStateChange={story => this.setState({story})}
                  toolbar={{
                    options: ['inline', 'history'],
                    inline: {
                      options: ['bold', 'italic', 'underline'],
                    },
                  }}
                />}
              />

              {images &&
                <Question
                  title="Images"
                  description="Choose up to 5 images of the device"
                  Input={() => (
                    <RUG
                      autoUpload={false}
                      onChange={(images) => {
                        this.setState({ screenshots: images }) // save current component
                      }}
                      rules={{
                        limit: 5,
                        size: 2048,
                      }}
                      accept={['jpg', 'jpeg', 'png', 'gif']}
                      initialState={[...images].map(image => ({source: image, name: ''}))}
                      header={({ openDialogue }) => (
                        <DropArea>
                          {(isDrag) => (
                            <DropZone onClick={openDialogue}>
                              Drag or click to select file
                            </DropZone>
                          )}
                        </DropArea>
                      )}
                    />
                  )}
                />
              }

              <Question
                title="Video"
                description="Please paste the URL of your favourite YouTube or Vimeo video here."
                Input={() => <Input value={video} onChange={(e) => this.setState({video: e.target.value})} type="text" placeholder="https://" />}
              />

              <Question
                title="Reseller Capabilities"
                description="List all of your capabilities."
                Input={() => <ReactTagInput 
                  placeholder="Type and press enter"
                  tags={heroFeatures} 
                  maxTags={10}
                  editable={false}
                  readOnly={false}
                  removeOnBackspace={true}
                  onChange={(heroFeatures) => this.setState({heroFeatures})}
                />}
              />

            <Question
              title="Localisations"
              description="Select all localisations that your resellers supports."
              Input={this.renderLocalisations}
            />

            <Question
              title="Tags"
              description="Add up to 20 keywords that prospective customers would use to search for your product."
              Input={() => <ReactTagInput 
                placeholder="Type and press enter"
                tags={tags} 
                maxTags={20}
                editable={false}
                readOnly={false}
                removeOnBackspace={true}
                onChange={(tags) => this.setState({tags})}
              />}
            />

            <Row className="align-items-center">
              <Col sm={6}>
                <div className="page-title-box">
                  <h4 className="font-size-18">Policies and Compliance</h4>
                </div>
              </Col>
            </Row>

             <Question
                title="Privacy Policy"
                description="Please link to the web page that displays your privacy policy."
                Input={() => <Input value={dataPrivacy} onChange={(e) => this.setState({dataPrivacy: e.target.value})} type="text" placeholder="https://" />}
              />

              <Question
                title="Terms &amp; Conditions"
                description="Please link to the web page that displays your terms and conditions."
                Input={() => <Input value={terms} onChange={(e) => this.setState({terms: e.target.value})} type="text" placeholder="https://" />}
              />

            <Row className="align-items-center">
              <Col sm={6}>
                <div className="page-title-box">
                  <h4 className="font-size-18">Set Up and Support</h4>
                </div>
              </Col>
            </Row>

              <Question
                title="Training"
                description="What training do you provide to users?"
                Input={() => <>
                  {this.renderTrainingSelection()}
                  <p style={{color: '#aaa', margin: '1rem 0 0.25rem'}}>Is there anything specific you want to add here?</p>
                  <Editor
                    editorState={training}
                    onEditorStateChange={training => this.setState({training})}
                    toolbar={{options: []}}
                  />
                  </>}
              />

              <Question
                title="Support"
                description="What ongoing support do you provide to users?"
                Input={() => <>
                  {this.renderTechSupport()}
                  <p style={{color: '#aaa', margin: '1rem 0 0.25rem'}}>Is there anything specific you want to add here?</p>
                  <Editor
                    editorState={supportDetails}
                    onEditorStateChange={supportDetails => this.setState({supportDetails})}
                    toolbar={{options: []}}
                  />
                  </>}
              />

            <Row className="align-items-center">
              <Col sm={6}>
                <div className="page-title-box">
                  <h4 className="font-size-18">Pricing</h4>
                </div>
              </Col>
            </Row>

              <Question
                title="Purchase Models"
                description={`What purchase model(s) do you provide?`}
                Input={this.renderPurchaseModels}
              />

              <Question
                title="Price Starting From"
                description="What is the lowest price available?"
                Input={() => (<>
                  <PriceStartingFrom>
                    <SelectSearch
                      options={[{name: '', value: ''}, {name: '£', value: '£'}, {name: '$', value: '$'}, {name: '€', value: '€'}]}
                      onChange={currency => this.setState({priceStartingFrom: {...priceStartingFrom, currency}})}
                      value={priceStartingFrom.currency}
                    />
                    <Input
                      type="number"
                      onChange={(e) => this.setState({priceStartingFrom: {...priceStartingFrom, amount: e.target.value}})}
                      value={priceStartingFrom.amount}
                    />
                    <SelectSearch
                      options={[{name: '', value: ''}, {name: 'per pupil', value: 'per-pupil'}, {name: 'per month', value: 'month'}, {name: 'per year', value: 'year'}, {name: 'one-off fee', value: 'one-off'}]}
                      onChange={period => this.setState({priceStartingFrom: {...priceStartingFrom, period}})}
                      value={priceStartingFrom.period}
                    />
                  </PriceStartingFrom>
                  <p style={{color: '#aaa', margin: '1rem 0 0.25rem'}}>What additional information can you share that prospective customers are usually looking for? Do you have any group pricing? Can you share specific pricing plans?</p>
                  <Editor
                    editorState={additionalPricingInformation}
                    onEditorStateChange={additionalPricingInformation => this.setState({additionalPricingInformation})}
                    toolbar={{options: []}}
                  />
                  </>
                )}
              />

              <Row className="align-items-center">
                <Col sm={6}>
                  <div className="page-title-box">
                    <h4 className="font-size-18">Lead Generation</h4>
                  </div>
                </Col>
              </Row>

              <Question
                title="Lead Capture Page"
                description="Where do you want to send prospective customers? Tip: Think carefully about whether this page has been optimised for conversion i.e. does it naturally follow on from your CTA (book demo / free trial)? Is there a form to capture their details?"
                Input={() => <Input value={demoLink} onChange={(e) => this.setState({demoLink: e.target.value})} type="text" placeholder="https://" />}
              />

              {/* <Question
                title="“Visit Website” Button"
                description={`Where can prospective customers go to find out more information? Tip: Add a UTM link to the URL so you can track conversions. Here’s a <a href="https://ga-dev-tools.appspot.com/campaign-url-builder/" target="_blank">useful tool</a> to help you add tracking.`}
                Input={() => <Input value={website} onChange={(e) => this.setState({website: e.target.value})} type="text" placeholder="https://" />}
              /> */}

              <Row>
                <Col>
                  <Button onClick={this.handleSubmit()} color="primary">
                    Save and Publish
                  </Button>
                </Col>
              </Row>

      </StyledResellerAdmin>
    );
  }
}

export const Question = ({title, description, tip, disabled, Input}) => (
  <Row>
    <Col lg={{size: 8, offset: 0}}>
      {disabled && <div style={{marginBottom: '0.25rem'}}>
        <PremiumFeature />
      </div>}
      <Card style={{position: 'relative'}}>
        <CardBody>
          <QuestionTitle>{title}</QuestionTitle>
          {description && <QuestionDescription dangerouslySetInnerHTML={{__html: description}} />}
          {tip && <Alert severity='info'>{tip}</Alert>}
          {Input()}
        </CardBody>
        {disabled && <div style={{position: 'absolute', zIndex: 1, inset: 0, backgroundColor: 'rgba(255,255,255,0.6)'}} />}
      </Card>
    </Col>
  </Row>
);

const PriceStartingFrom = styled.div`
  display: flex;

  .select-search {
    flex: 0 0 130px;

    &:first-child {
      flex: 0 0 80px;
      margin-right: 10px;
    }
  }

  input {
    flex: 0 0 110px;
    margin-right: 10px;
  }
`;

const DropZone = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #aaa;
  width: 100%;
  min-height: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  padding: 0.5rem;
  cursor: pointer;
`;

const Tabs = styled(Nav)`
  margin-bottom: 1rem;
`;

const Tab = styled(NavItem)`
  cursor: pointer;

  .nav-link.active {
    border-color: #fff;
  }

  &:hover {
    .nav-link {
      border-color: #fff;
    }
  }

  .active {
    border-bottom: 4px solid #FD7252 !important;
    cursor: initial;
    font-weight: bold;
  }
`;

export const QuestionTitle = styled.h5`
  margin-bottom: 0.5rem;
`;

export const QuestionDescription = styled.p`
  margin-bottom: 0.5rem;
  margin-top: -0.25rem;
  color: #aaa;
`;

export const LoadingScreen = styled.div`
  position: absolute;
  inset: 0;
  background-color: rgba(255,255,255,0.6);
  z-index: 999;
`;

const StyledResellerAdmin = styled(Container)`
  position: relative;
  margin-bottom: 3rem;

  .form-check {
    display: inline-block;
    width: 12rem;
  }

  .rug .rug-items.__card {
    min-height: 0;
    grid-template-columns: repeat(auto-fill, minmax(126px, 1fr));
  }

  .rug-card {
    width: 126px;
    height: 126px;
    background: transparent;
    border: 1px solid #ccc;
  }

  .rug-card .rug-card-name {
    background: transparent;
  }

  .rug-card .rug-card-upload-button {
    display: none;
  }

  .thumb {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 100px;
    height: 100px;
    padding: 4px;
    box-sizing: border-box;
  };
  
  .thumbInner {
    display: flex;
    minWidth: 0;
    overflow: hidden;
  };
  
  .img {
    display: block;
    width: auto;
    height: 100%;
  };
`;

const OptionContainer = styled.div`
  min-width: 200px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  display: inline-block;
  line-height: 0.8rem;
  border: solid 1px #0482a2;
`;

const Select = styled.div`
  min-width: 198px;
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  padding: 0.5rem 1rem;
  justify-content: space-between;
`;

const SelectTitle = styled.div`
  min-width: 200px;
  max-width: 200px;
  box-sizing: border-box;
  justify-content: space-between;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  line-height: 0.8rem;
  border: solid 1px #0482a2;
  padding: 0.5rem 1rem;
`;

const OptionBox = styled.div`
  position: absolute;
  z-index: 2;
  border: solid 1px #0482a2;
  margin: -2px 0 0 -1px;
  padding: 0.5rem 0;
  background-color: #fff;
`;

const Option = styled.div`
  min-width: 198px;
  box-sizing: border-box;
  background-color: white;
  cursor: pointer;
  padding: 0.75rem 1rem;
  text-align: left;

  :hover {
    background-color: #0482a2;
    color: white;
  }
`;

const LabelText = styled.span`font-weight: normal;
`;

const mapStateToProps = state => {
  const { updatePending, createPending, reseller } = state.Reseller;
  return { updatePending, createPending, reseller };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dispatchResellerRequest: actions.resellerRequestAction,
      dispatchSelectReseller: actions.selectReseller,
      dispatchToggleFilter: actions.resellersToggleFilterAction,
      dispatchToggleSubject: actions.resellersToggleSubjectAction,
      dispatchToggleFeature: actions.resellersToggleFeatureAction,
      dispatchToggleType: actions.resellersToggleTypeAction,
      dispatchToggleCheckbox: actions.resellersToggleCheckboxAction,
      dispatchSubmit: actions.updateResellerAction,
      dispatchPlanSelect: actions.resellersSelectPlanAction,
      dispatchReviewChange: actions.reviewChange,
      dispatchDetailsChange: actions.widgetDetailsChange,
      dispatchResetState: actions.resetState,
      dispatchUploadLogo: actions.uploadLogo,
      dispatchUploadScreenshot: actions.uploadScreenshot,
      dispatchCreateReseller: actions.createResellerAction,
    },
    dispatch,
  );

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
  withTracking(),
)(ResellerAdmin);
