import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { authHost } from './Login';

export const RegisterRedirect = () => {
  return (
    <Redirect
      to={{
        pathname: "/",
        state: { authState: 'signUp', basicSignUp: true }
      }}
    />
  );
}

export const RegisterProductRedirect = () => {
  useEffect(() => {
    window.location.href = `${authHost}/account/`
  }, [])
  return null
}
