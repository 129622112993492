import React from 'react';
import { transparentize } from 'polished';

const Star = ({color='#0482A2', type='full', width='20'}) => {
  const emptyColor = transparentize(0.8, color);

  if (type === 'full') {
    return <svg style={{width}} viewBox="0 0 92.74 88.2">
      <g>
        <polygon fill={color} points="46.37 0 58.12 32.57 92.74 33.69 65.39 54.94 75.03 88.2 46.37 68.75 17.71 88.2 27.35 54.94 0 33.69 34.61 32.57 46.37 0"/>
      </g>
    </svg>;
  }

  if (type === 'half') {
    return <svg style={{width}} viewBox="0 0 92.74 88.2">
      <g>
        <polygon fill={emptyColor} points="46.37 0 34.61 32.57 0 33.69 27.35 54.94 17.71 88.2 46.37 68.75 75.03 88.2 65.39 54.94 92.74 33.69 58.12 32.57 46.37 0"/>
        <polyline fill={color} points="46.4 0 34.63 32.57 0.03 33.69 27.38 54.93 17.73 88.19 46.4 68.75"/>
      </g>
    </svg>;
  }

  return <svg style={{width}} viewBox="0 0 92.74 88.2">
    <g>
      <polygon fill={emptyColor} points="46.37 0 34.61 32.57 0 33.69 27.35 54.94 17.71 88.2 46.37 68.75 75.03 88.2 65.39 54.94 92.74 33.69 58.12 32.57 46.37 0"/>
    </g>
  </svg>;
}

export default Star;
