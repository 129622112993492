const webBaseUrl = '';

export const WEB_URL = webBaseUrl;

// ! Rudimentary toggle for switching between dev and prod endpoints
export const IS_PRODUCTION = true

// export const API_URL = `https://16pw6j76sg.execute-api.eu-west-2.amazonaws.com/dev`;
export const API_URL = `https://uazf65u7le.execute-api.eu-west-2.amazonaws.com/staging`;

// Production API
// export const API_V2_URL = `https://fzi9k92b8f.execute-api.eu-west-2.amazonaws.com/production`
export const API_V2_URL = `https://api.edtechimpact.com`;
export const API_V2_DEV_URL = `https://8oi4uyd8n3.execute-api.eu-west-2.amazonaws.com/dev`;

export const WORDPRESS_API_URL = `https://public-api.wordpress.com/wp/v2/sites/edtechimpact687438683.wpcomstaging.com`;

export const EAF_API = 'https://tool.kokoa.io/api';
export const EAF_API_KEY = '55beb3af17484e9288d7de608ce53bc0';

export const MEDIA_URL = 'https://media.edtechimpact.com';

export const PRICING_PAGE = 'https://edtechimpact.com/pricing';

export const STRIPE_TEST_KEY = 'pk_test_51IzgCpIuTbkOnEpnWlnieZUD15WanrZaJHLa1sB3FZnrZ9UpUggBDqTqFDgglYHasGEuaGwuZThg9QYQyGXLk6yi003EGs30Eh';
export const STRIPE_LIVE_KEY = 'pk_live_51IzgCpIuTbkOnEpnCJfdzir8TUvdqHT3L7R8HzN9GHFKPqkbeDGgRivPQlM9ZmE1vdgkref8xSOXslGUVU4J5GfO00BSbX4X2m';
export const STRIPE_TEST_MODE = false

export const VAT_RATE = 0.2;
