import { Box, CircularProgress } from '@mui/material'

const VARIANTS = {
  TINY: 'tiny',
  SMALL: 'small',
  FULL: 'full'
}

const Spinner = ({ variant = VARIANTS.SMALL }) => {
  return (
    <Box
      sx={{
        height: variant === VARIANTS.TINY ? '10vh' : variant === VARIANTS.SMALL ? '20vh' : '100vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <CircularProgress disableShrink />
    </Box>
  )
}

export default Spinner
