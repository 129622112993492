const Typography = theme => {
  const bodyFontFamily = "'Roboto', sans-serif",
    headingsFontFamily = '"Sarabun", sans-serif';

  return {
    fontFamily: bodyFontFamily,
    fontSize: theme.typography.fontSize,
    h1: {
      fontFamily: headingsFontFamily,
      fontWeight: 600,
      fontSize: theme.typography.fontSize * 2.5,
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(2),
      lineHeight: 1.2,
    },
    h2: {
      fontFamily: headingsFontFamily,
      fontWeight: 600,
      fontSize: theme.typography.fontSize * 2,
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(2),
      lineHeight: 1.2,
    },
    h3: {
      fontFamily: headingsFontFamily,
      fontWeight: 600,
      fontSize: theme.typography.fontSize * 1.75,
      letterSpacing: 0,
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(2),
      lineHeight: 1.2,
    },
    h4: {
      fontFamily: headingsFontFamily,
      fontWeight: 600,
      fontSize: theme.typography.fontSize * 1.5,
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(2),
      lineHeight: 1.2,
    },
    h5: {
      fontFamily: headingsFontFamily,
      fontWeight: 600,
      fontSize: theme.typography.fontSize * 1.25,
      letterSpacing: 0,
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(2),
      lineHeight: 1.2,
    },
    h6: {
      fontFamily: headingsFontFamily,
      fontWeight: 600,
      fontSize: theme.typography.fontSize,
      letterSpacing: 0,
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(2),
      lineHeight: 1.2,
    },
    subtitle1: {
      letterSpacing: '0.15px',
      color: theme.palette.text.primary
    },
    subtitle2: {
      letterSpacing: '0.1px',
      color: theme.palette.text.secondary
    },
    body1: {
      letterSpacing: 0,
      fontFamily: bodyFontFamily,
      fontSize: theme.typography.fontSize,
      color: theme.palette.text.primary,
      lineHeight: 1.7,
      marginBottom: theme.spacing(4)
    },
    body2: {
      marginBottom: theme.spacing(4),
      marginTop: -(theme.spacing(1)),
      lineHeight: 1.7,
      letterSpacing: 0,
      fontSize: theme.typography.fontSize,
      color: theme.palette.grey['600']
    },
    button: {
      letterSpacing: '0.3px',
      color: theme.palette.text.primary
    },
    caption: {
      letterSpacing: '0.4px',
      color: theme.palette.text.secondary
    },
    overline: {
      letterSpacing: '1px',
      color: theme.palette.text.secondary
    }
  }
}

export default Typography
