export const UPDATE_CONFIG = 'app/BrandAdmin/UPDATE_CONFIG';
export const UPDATE_CONFIG_SUCCESS = 'app/BrandAdmin/UPDATE_CONFIG_SUCCESS';
export const UPDATE_CONFIG_COMPLETE = 'app/BrandAdmin/UPDATE_CONFIG_COMPLETE';

export const BRAND_REQUEST = 'app/BrandAdmin/BRAND_REQUEST';
export const BRAND_REQUEST_COMPLETE = 'app/BrandAdmin/BRAND_REQUEST_COMPLETE';
export const BRAND_REQUEST_SUCCESS = 'app/BrandAdmin/BRAND_REQUEST_SUCCESS';

export const CONFIG_REQUEST = 'app/BrandAdmin/CONFIG_REQUEST';
export const CONFIG_REQUEST_COMPLETE = 'app/BrandAdmin/CONFIG_REQUEST_COMPLETE';
export const CONFIG_REQUEST_SUCCESS = 'app/BrandAdmin/CONFIG_REQUEST_SUCCESS';

export const CREATE_BRAND = 'app/BrandAdmin/CREATE_BRAND';
export const CREATE_BRAND_COMPLETE = 'app/BrandAdmin/CREATE_BRAND_COMPLETE';
export const CREATE_BRAND_SUCCESS = 'app/BrandAdmin/CREATE_BRAND_SUCCESS';

export const INVITES_REQUEST = 'app/BrandAdmin/INVITES_REQUEST';
export const INVITES_REQUEST_COMPLETE = 'app/BrandAdmin/INVITES_REQUEST_COMPLETE';
export const INVITES_REQUEST_SUCCESS = 'app/BrandAdmin/INVITES_REQUEST_SUCCESS';

export const INVITES_UPLOAD = 'app/BrandAdmin/INVITES_UPLOAD';
export const INVITES_UPLOAD_COMPLETE = 'app/BrandAdmin/INVITES_UPLOAD_COMPLETE';
export const INVITES_UPLOAD_SUCCESS = 'app/BrandAdmin/INVITES_UPLOAD_SUCCESS';

export const REVIEWS_REQUEST = 'app/BrandAdmin/REVIEWS_REQUEST';
export const REVIEWS_REQUEST_COMPLETE = 'app/BrandAdmin/REVIEWS_REQUEST_COMPLETE';
export const REVIEWS_REQUEST_SUCCESS = 'app/BrandAdmin/REVIEWS_REQUEST_SUCCESS';

export const REVIEW_REPLY = 'app/BrandAdmin/REVIEW_REPLY';
export const REVIEW_REPLY_COMPLETE = 'app/BrandAdmin/REVIEW_REPLY_COMPLETE';
export const REVIEW_REPLY_SUCCESS = 'app/BrandAdmin/REVIEW_REPLY_SUCCESS';

export const EVIDENCE_REQUEST = 'app/BrandAdmin/EVIDENCE_REQUEST';
export const EVIDENCE_REQUEST_COMPLETE =
  'app/BrandAdmin/EVIDENCE_REQUEST_COMPLETE';
export const EVIDENCE_REQUEST_SUCCESS = 'app/BrandAdmin/EVIDENCE_REQUEST_SUCCESS';

export const EVIDENCE_TABLE_REQUEST = 'app/BrandAdmin/EVIDENCE_TABLE_REQUEST';
export const EVIDENCE_TABLE_REQUEST_COMPLETE =
  'app/BrandAdmin/EVIDENCE_TABLE_REQUEST_COMPLETE';
export const EVIDENCE_TABLE_REQUEST_SUCCESS =
  'app/BrandAdmin/EVIDENCE_TABLE_REQUEST_SUCCESS';

export const SCHOOL_TYPE_CHANGE = 'app/BrandAdmin/SCHOOL_TYPE_CHANGE';
export const SCHOOL_SIZE_CHANGE = 'app/BrandAdmin/SCHOOL_SIZE_CHANGE';
export const RESET_STATE = 'app/BrandAdmin/RESET_STATE';

export const BRANDS_TOGGLE_FILTER = 'app/BrandAdmin/BRANDS_TOGGLE_FILTER';
export const BRANDS_TOGGLE_SUBJECT = 'app/BrandAdmin/BRANDS_TOGGLE_SUBJECT';
export const BRANDS_TOGGLE_FEATURE = 'app/BrandAdmin/BRANDS_TOGGLE_FEATURE';
export const BRANDS_TOGGLE_TYPE = 'app/BrandAdmin/BRANDS_TOGGLE_TYPE';

export const SELECT_BRAND = 'app/BrandAdmin/SELECT_BRAND';
export const SELECT_BRAND_SUCCESS = 'app/BrandAdmin/SELECT_BRAND_SUCCESS';
export const SELECT_BRAND_COMPLETE = 'app/BrandAdmin/SELECT_BRAND_COMPLETE';

export const BRANDS_TOGGLE_CHECKBOX =
  'app/BrandAdmin/BRANDS_TOGGLE_CHECKBOX';
export const UPDATE_BRAND = 'app/BrandAdmin/UPDATE_BRAND';
export const COMPLETE_UPDATE_BRAND = 'app/BrandAdmin/COMPLETE_UPDATE_BRAND';
export const BRANDS_SELECT_PLAN = 'app/BrandAdmin/SELECT_PLAN';
export const EMAILS_CHANGE = 'app/BrandAdmin/EMAILS_CHANGE';
export const REVIEW_CHANGE = 'app/BrandAdmin/REVIEW_CHANGE';
export const WIDGET_DETAILS_CHANGE = 'app/BrandAdmin/WIDGET_DETAILS_CHANGE';
export const EXPORT_REVIEWS = 'app/BrandAdmin/EXPORT_REVIEWS';
export const EXPORT_ALL_REVIEWS = 'app/BrandAdmin/EXPORT_ALL_REVIEWS';
export const EXPORT_ALL_BRANDS = 'app/BrandAdmin/EXPORT_ALL_BRANDS';
export const EXPORT_COMPLETE = 'app/BrandAdmin/EXPORT_COMPLETE';
export const UPLOAD_LOGO = 'app/BrandAdmin/UPLOAD_LOGO';
export const UPLOAD_SCREENSHOT = 'app/BrandAdmin/UPLOAD_SCREENSHOT';

export const schoolTypes = [
  { id: 1, title: 'Primary', value: 'Primary' },
  { id: 2, title: 'Secondary', value: 'Secondary' },
  { id: 3, title: 'International', value: 'International' },
];

export const schoolSizes = [
  {
    id: 1,
    title: '100 students or less',
    value: {
      min: 0,
      max: 100,
    },
  },
  {
    id: 2,
    title: '101-500 students',
    value: {
      min: 101,
      max: 500,
    },
  },
  {
    id: 3,
    title: '501-1000 students',
    value: {
      min: 501,
      max: 1000,
    },
  },
  {
    id: 4,
    title: '1000+ students',
    value: {
      min: 1001,
      max: 100000,
    },
  },
];
