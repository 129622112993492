import colors from '@ei/theme/colors';
import { transparentize, darken, lighten } from 'polished';

let accentMid = colors.cgBlue;
let tableBackground = '#F7FAFB';
let primaryHighlight = '#FF492C';

if (typeof window !== "undefined") {
  if (document.referrer.includes('gesseducation.com')) {
    accentMid = '#2F1D4B';
    tableBackground = '#F6F3FA';

  } else if (document.referrer.includes('ypo.co.uk')) {
    accentMid = '#543996';
    tableBackground = '#C9BEE3';

  } else if (document.referrer.includes('schoolmanagementplus.com')) {
    accentMid = '#283b8f';
    tableBackground = '#F7FAFB';
    primaryHighlight = '#d2ad53';
  }
}

const eiTheme = {
  primaryDark: colors.nearBlack,
  primaryMidDark: colors.midBlack,
  primaryGrey: colors.darkGrey,
  primaryMid: colors.grey,
  primaryLight: colors.lightGrey,
  primaryWhite: colors.white,
  accentDark: colors.navyBlue,
  accentMid: accentMid,
  accentMidLight: transparentize(0.4, colors.cgBlue),
  accentLight: transparentize(0.8, colors.cgBlue),
  accentLighter: transparentize(0.8, colors.seaBlue),
  green: colors.green,
  yellow: colors.yellow,
  red: colors.red,
  tableBackground,
  orange: primaryHighlight,
  darkOrange: darken(0.12, primaryHighlight),
  lightOrange: lighten(0.18, primaryHighlight),
  lighterOrange: lighten(0.38, primaryHighlight),
  primaryHighlight,
  white: '#fff',
  lightBlue: '#edf4fc',
  lighterBlue: lighten(0.55, '#315E8D'),
  darkBlue: '#315E8D',
  darkerBlue: darken(0.12, '#315E8D'),
};

export default eiTheme;
