import { forwardRef, useEffect, useState } from 'react';

import { DataGridPro, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import currency from 'currency.js';
import { uniqBy } from 'lodash';
import moment from 'moment';
import Immutable from 'seamless-immutable';

import { Close as CrossIcon, Check as TickIcon } from '@mui/icons-material';
import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Popover, Radio, RadioGroup, Select, Stack } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import useAuth from '../pages/Authentication/useAuth';
import Spinner from '@ei/components/spinner';

const width = 19
const height = 20

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

const TrafficWeekContent = forwardRef((props, ref) => {
  const { item, index } = props;

  return (
    <g
      {...props}
      ref={ref}
      transform={`translate(${width * index} ,0)`}
    >
      <rect
        x={0}
        y={0}
        width={width}
        height={height}
        style={{
          fill: '#095256',
          fillOpacity: item.profileViews / 100,
          stroke: '#eee',
          strokeWidth: 1,
        }}
      />

      {item.profileViews > 0 &&
        <text
          x={width / 2}
          y={height / 2}
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize="9"
          fill={item.profileViews > 50 ? "#CEDCDD" : "#095256"}
        >
          {item.profileViews}
        </text>
      }

      {/* {hasMarketingEmail &&
        <rect
          x={0}
          y={height - 3}
          width={width}
          height={3}
          style={{
            fill: '#FFA500',
          }}
        />
      }

      {hasTransactionalEmail &&
        <rect
          x={0}
          y={height - (hasMarketingEmail ? 6 : 3)}
          width={width}
          height={3}
          style={{
            fill: '#ADD8E6',
          }}
        />
      } */}
    </g>
  )
})

const WeekContent = forwardRef((props, ref) => {
  const { item, index } = props;

  let hasTransactionalEmail = false
  let hasMarketingEmail = false

  if (item.emails) {
    item.emails.forEach(email => {
      const tag = email.tags?.[0]

      if (tag?.includes('manual')) {
        hasMarketingEmail = true
      } else {
        hasTransactionalEmail = true
      }
    })
  }

  return (
    <g
      {...props}
      ref={ref}
      transform={`translate(${width * index} ,0)`}
    >
      <rect
        x={0}
        y={0}
        width={width}
        height={height}
        style={{
          fill: '#095256',
          fillOpacity: item.engagementScore / 10,
          stroke: '#eee',
          strokeWidth: 1,
        }}
      />

      {item.leads > 0 &&
        <text
          x={width / 2}
          y={height / 2}
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize="12"
          fill={item.engagementScore > 5 ? "#CEDCDD" : "#095256"}
        >
          {item.leads}
        </text>
      }

      {hasMarketingEmail &&
        <rect
          x={0}
          y={height - 3}
          width={width}
          height={3}
          style={{
            fill: '#FFA500',
          }}
        />
      }

      {hasTransactionalEmail &&
        <rect
          x={0}
          y={height - (hasMarketingEmail ? 6 : 3)}
          width={width}
          height={3}
          style={{
            fill: '#ADD8E6',
          }}
        />
      }
    </g>
  )
})

const Engagement = () => {

  const { supabase } = useAuth()

  const [products, setProducts] = useState()
  const [metrics, setMetrics] = useState([])
  const [categories, setCategories] = useState([])
  const [subjects, setSubjects] = useState([])
  const [weeks, setWeeks] = useState([])
  const [totals, setTotals] = useState()
  const [lists, setLists] = useState([])
  const [engagement, setEngagement] = useState()
  const [filters, setFilters] = useState({
    plan: 'paid',
    categoryId: '',
    subjectId: '',
  })
  const [sortModel, setSortModel] = useState([
    {
      field: 'engagementEMA',
      sort: 'asc',
    },
  ]);
  const [userSelection, setUserSelection] = useState([])
  const [emailList, setEmailList] = useState('')
  const [recentEmails, setRecentEmails] = useState([])
  const [view, setView] = useState('ACCOUNT')
  const [excludeRecent, setExcludeRecent] = useState(true)
  const [anchorEl, setAnchorEl] = useState(false)

  useEffect(() => {
    if (!supabase) return
    // getLists()
    getRecentEmails()
    getCategories()
    getSubjects()
    getEngagement()
  }, [supabase])

  useEffect(() => {
    if (!supabase) return
    getMetrics()
  }, [supabase, filters.categoryId, filters.subjectId])

  useEffect(() => {
    if (!engagement || !metrics) return

    const totals = []
    const engagementObject = {}
    const emptyRow = {
      reviews: 0,
      leads: 0,
      profileViews: 0,
      engagementScore: 0,
    }

    let weeks = uniqBy(engagement, 'w').map(r => r.w);
    weeks.sort((a, b) => moment(a).diff(moment(b)))
    weeks = weeks.slice(1)
    setWeeks(weeks)

    engagement.forEach(row => {
      const r = {
        productId: row.id,
        week: row.w,
        emails: row.em,
        reviews: row.r || 0,
        leads: row.l || 0,
        profileViews: row.v || 0,
        engagementScore: row.e || 0,
      }
      engagementObject[r.productId] = engagementObject[r.productId] ? [...engagementObject[r.productId], r] : [r]
    })

    const products = Immutable.asMutable(metrics)
      .map(p => {
        const productWeeks = weeks.map(week => engagementObject[p.id]?.find(w => w.week === week) || { ...emptyRow, week })
        const _weeks = productWeeks.map((week, index) => {
          return ({
            ...week,
            engagementEMA: index >= 13 ? calculateEMA(productWeeks.slice(0, index + 1), 13, 'engagementScore') : 0,
            profileViewsEMA: index >= 13 ? calculateEMA(productWeeks.slice(0, index + 1), 13, 'profileViews') : 0,
          })
        })
        return ({
          ...p,
          userCount: p.users ? uniqBy(p.users, 'user_id').length : 0,
          engagementEMA: _weeks[_weeks.length - 1].engagementEMA,
          profileViewsEMA: _weeks[_weeks.length - 1].profileViewsEMA,
          weeks: _weeks,
        })
      })
      .filter(p => {
        if (filters.plan !== 'all') {
          const plan = p.plan?.toLowerCase()
          const isPaid = plan?.includes('pro') || plan?.includes('starter')
          const isClamied = p.userCount > 0

          if (filters.plan === 'paid' && isPaid) return true
          if (filters.plan === 'free' && !isPaid && isClamied) return true
          if (filters.plan === 'unclaimed' && !isClamied) return true

          return false
        }
        return true
      })

    if (sortModel[0]) {
      products.sort((a, b) => {
        if (['primary_category', 'name'].includes(sortModel[0].field)) {
          return sortModel[0].sort === 'asc' ? a[sortModel[0].field]?.localeCompare(b[sortModel[0].field]) : b[sortModel[0].field]?.localeCompare(a[sortModel[0].field])
        }
        else if (['expiry', 'latest_lead', 'created_at'].includes(sortModel[0].field)) {
          return sortModel[0].sort === 'asc' ? moment(a[sortModel[0].field]).diff(moment(b[sortModel[0].field])) : moment(b[sortModel[0].field]).diff(moment(a[sortModel[0].field]))
        }
        else if (['users'].includes(sortModel[0].field)) {
          return sortModel[0].sort === 'asc' ? moment(a[sortModel[0].field]).diff(moment(b[sortModel[0].field])) : moment(b[sortModel[0].field]).diff(moment(a[sortModel[0].field]))
        }
        return sortModel[0].sort === 'asc' ? a[sortModel[0].field] - b[sortModel[0].field] : b[sortModel[0].field] - a[sortModel[0].field]
      })
    }

    const averages = totals.map(week => {
      const obj = {}
      Object.keys(week).forEach(key => {
        obj[key] = week[key] / products.length
      })
      return obj
    })

    setProducts(products)

    setTotals([
      {
        productId: 'Leads',
        productName: 'Leads',
        key: 'leads',
        weeks: totals,
        showValue: true,
      },
      {
        productId: 'Reviews',
        productName: 'Reviews',
        key: 'reviews',
        weeks: totals,
        showValue: true,
      },
      {
        productId: 'Engagement',
        productName: 'Engagement x4',
        key: 'engagementScore',
        weeks: averages,
      },
    ])
  }, [engagement, metrics, filters, sortModel])

  // const getLists = async () => {
  //   await fetch('https://api.brevo.com/v3/contacts/folders/16/lists', {
  //     headers: {
  //       'api-key': SIB_API_KEY,
  //     },
  //   })
  //     .then(response => response.json())
  //     .then(response => setLists(response.lists))
  // }

  const getRecentEmails = async () => {
    const { data } = await supabase
      .from('email_logs')
      .select('email, tags, event, template_id')
      .in('template_id', [244, 245, 246, 250])
      // .eq('event', 'request')
      .gt('date', moment().subtract(1, 'week').startOf('day').toISOString())

    data && setRecentEmails(data.map(d => d.email))
  }

  const getMetrics = async () => {
    const { data } = await supabase.rpc('get_product_metrics', {
      plan: null,
      category_id: filters.categoryId || null,
      subject_id: filters.subjectId || null,
    });
    setMetrics(data)
  }

  const getEngagement = async () => {
    const { data } = await supabase
      .from('vendor_engagement_view')
      .select()

    const stats = data
      .map((row => {
        const r = {
          id: parseInt(row.product_id),
          w: moment(row.week).format('YYYY-MM-DD'),
          r: parseInt(row.reviews),
          l: parseInt(row.leads),
          e: parseInt(row.engagement_score),
          v: parseInt(row.profile_views),
          em: row.emails,
        }

        if (!r.r) delete r.r
        if (!r.l) delete r.l
        if (!r.e) delete r.e
        if (!r.v) delete r.v
        if (!r.em) delete r.em

        return r
      }))
      .filter((row => !!row.r || !!row.l || !!row.e || !!row.em || !!row.v))

    setEngagement(stats)
  }

  const getCategories = async () => {
    const { data } = await supabase
      .from('categories')
      .select('id, title')
      .order('title', 'asc')
    setCategories(data)
  }

  const getSubjects = async () => {
    const { data } = await supabase
      .from('subjects')
      .select('id, title')
      .order('title', 'asc')
    setSubjects(data)
  }

  const handleUserSelectChange = user => {
    setUserSelection(userSelection => {
      if (!!userSelection.find(_user => user.user_id === _user.user_id)) {
        return userSelection.filter(_user => user.user_id !== _user.user_id)
      }
      return [...userSelection, user]
    })
  }

  const accountColumns = [
    {
      field: 'id',
      headerName: '',
      width: 40,
      sortable: false,
      filterable: false,
      renderCell: ({ value, row }) => {
        return (
          <Stack>
            <Stack onClick={e => setAnchorEl(e.currentTarget)} id={`product-name-${row.id}`}>
              <Checkbox
                size="small"
                checked={row.users?.reduce((isChecked, user) => {
                  return isChecked || !!userSelection.find(_user => user.user_id === _user.user_id) || (excludeRecent && recentEmails.includes(user.email))
                }, false)}
                disabled
                sx={{
                  '.MuiCheckbox-root.Mui-disabled': {
                    color: '#e82997 !important'
                  }
                }}
              />
            </Stack>
            <Popover
              open={anchorEl && anchorEl.getAttribute('id') === `product-name-${row.id}`}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              onClose={() => setAnchorEl()}
            >
              <Paper sx={{ p: 2 }}>
                <Stack>
                  {row.users?.map(user => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={!!userSelection.find(_user => user.user_id === _user.user_id)}
                            onChange={() => handleUserSelectChange({ ...user, ...row })}
                            disabled={excludeRecent && recentEmails.includes(user.email)}
                          />}
                        label={`${user.email} (${user.first_name || ''} ${user.last_name || ''}${user.job_title ? `, ${user.job_title})` : ')'} ${user.user_type}`}
                      />
                    )
                  })}
                </Stack>
              </Paper>
            </Popover>
          </Stack>
        )
      },
    },
    {
      field: 'name',
      headerName: 'Product',
      sortable: true,
      width: 160,
      renderCell: ({ value, row }) => {
        return (
          <Stack>
            <a target="_blank" style={{ fontWeight: 'normal' }} href={`/products/${row.slug}/profile`}>{value}</a>
          </Stack>
        )
      },
    },
    {
      field: 'primary_category',
      headerName: 'Primary Category',
      flex: 140,
    },
    {
      field: 'created_at',
      headerName: 'Created',
      flex: 120,
      renderCell: ({ value }) => value ? moment(value).format('D MMM YY') : null,
    },
    {
      field: 'plan',
      headerName: 'Plan',
      flex: 100,
    },
    {
      field: 'expiry',
      headerName: 'Expiry',
      flex: 120,
      renderCell: ({ value }) => value ? moment(value).format('D MMM YY') : null,
    },
    {
      field: 'userCount',
      headerName: 'Users',
      flex: 80,
    },
    {
      field: 'published',
      headerName: 'Published',
      flex: 80,
      renderCell: ({ value }) => value ? <TickIcon fontSize="small" /> : <CrossIcon fontSize="small" color="warning" />,
    },
  ];

  const metricsColumns = [
    {
      field: 'id',
      headerName: '',
      width: 40,
      sortable: false,
      filterable: false,
      renderCell: ({ value, row }) => {
        return (
          <Stack>
            <Stack onClick={e => setAnchorEl(e.currentTarget)} id={`product-name-${row.id}`}>
              <Checkbox
                size="small"
                checked={row.users?.reduce((isChecked, user) => {
                  return isChecked || !!userSelection.find(_user => user.user_id === _user.user_id) || recentEmails.includes(user.email)
                }, false)}
                disabled
                sx={{
                  '.MuiCheckbox-root.Mui-disabled': {
                    color: '#e82997 !important'
                  }
                }}
              />
            </Stack>
            <Popover
              open={anchorEl && anchorEl.getAttribute('id') === `product-name-${row.id}`}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              onClose={() => setAnchorEl()}
            >
              <Paper sx={{ p: 2 }}>
                <Stack>
                  {row.users?.map(user => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={!!userSelection.find(_user => user.user_id === _user.user_id)}
                            onChange={() => handleUserSelectChange({ ...user, ...row })}
                            disabled={recentEmails.includes(user.email)}
                          />}
                        label={`${user.email} (${user.first_name || ''} ${user.last_name || ''}${user.job_title ? `, ${user.job_title})` : ')'} ${user.user_type}`}
                      />
                    )
                  })}
                </Stack>
              </Paper>
            </Popover>
          </Stack>
        )
      },
    },
    {
      field: 'name',
      headerName: 'Product',
      sortable: true,
      width: 160,
      renderCell: ({ value, row }) => {
        return (
          <Stack>
            <a target="_blank" style={{ fontWeight: 'normal' }} href={`/products/${row.slug}/profile`}>{value}</a>
          </Stack>
        )
      },
    },
    {
      field: 'rating',
      headerName: 'Rating',
      flex: 80,
    },
    {
      field: 'total_reviews',
      headerName: 'Reviews',
      flex: 80,
    },
    {
      field: 'reviews',
      headerName: '12m Reviews',
      flex: 80,
    },
    {
      field: 'total_leads',
      headerName: 'Leads',
      flex: 80,
    },
    {
      field: 'leads',
      headerName: '12m Leads',
      flex: 80,
    },
    {
      field: 'balance',
      headerName: 'Balance',
      flex: 80,
    },
    {
      field: 'number_of_credits',
      headerName: 'Credits',
      flex: 80,
    },
    {
      field: 'free_leads',
      headerName: 'Free leads',
      flex: 80,
    },
    {
      field: 'paid_leads',
      headerName: 'Paid leads',
      flex: 80,
    },
    {
      field: 'latest_lead',
      headerName: 'Last lead',
      flex: 100,
      renderCell: ({ value }) => value ? moment(value).format('D MMM YY') : null,
    },
    {
      field: 'engagementEMA',
      headerName: 'Engagement',
      flex: 120,
      sortable: true,
    },
    {
      field: 'profileViewsEMA',
      headerName: 'Traffic',
      flex: 120,
      sortable: true,
    },
  ];

  const engagementColumns = [
    {
      field: 'id',
      headerName: '',
      width: 40,
      sortable: false,
      filterable: false,
      renderCell: ({ value, row }) => {
        return (
          <Stack>
            <Stack onClick={e => setAnchorEl(e.currentTarget)} id={`product-name-${row.id}`}>
              <Checkbox
                size="small"
                checked={row.users?.reduce((isChecked, user) => {
                  return isChecked || !!userSelection.find(_user => user.user_id === _user.user_id) || recentEmails.includes(user.email)
                }, false)}
                disabled
              />
            </Stack>
            <Popover
              open={anchorEl && anchorEl.getAttribute('id') === `product-name-${row.id}`}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              onClose={() => setAnchorEl()}
            >
              <Paper sx={{ p: 2 }}>
                <Stack>
                  {row.users?.map(user => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={!!userSelection.find(_user => user.user_id === _user.user_id)}
                            onChange={() => handleUserSelectChange({ ...user, ...row })}
                            disabled={recentEmails.includes(user.email)}
                          />}
                        label={`${user.email} (${user.first_name || ''} ${user.last_name || ''}${user.job_title ? `, ${user.job_title})` : ')'} ${user.user_type}`}
                      />
                    )
                  })}
                </Stack>
              </Paper>
            </Popover>
          </Stack>
        )
      },
    },
    {
      field: 'name',
      headerName: 'Product',
      flex: 160,
      sortable: false,
      renderCell: ({ value, row }) => {
        return (
          <Stack>
            <a target="_blank" style={{ fontWeight: 'normal' }} href={`/products/${row.slug}/profile`}>{row.name}</a>
          </Stack>
        )
      },
    },
    {
      // field: 'engagementEMADupe',
      headerName: 'engagement',
      sortable: false,
      filterable: false,
      flex: 1200,
      renderHeader: ({ params }) => (
        <svg width={weeks?.length * width} height={height} >
          {weeks?.map((week, index) => {
            const month = moment(week).format('M')
            const prevMonth = index > 0 && moment(weeks[index - 1]).format('M')

            return (
              <g
                key={week}
                transform={`translate(${width * index - 8},0)`}
              >
                <text
                  x={width / 2}
                  y={height / 2 + 2}
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fontSize="12"
                  fill={"#095256"}
                >
                  {index > 0 && month !== prevMonth && moment(week).format('MMM')}
                </text>
              </g>
            )
          })}
        </svg>
      ),
      renderCell: ({ row }) => (
        <svg width={weeks?.length * width} height={height} style={{ background: '#fff' }}>
          {row.weeks?.map((item, index) => {

            const hasTooltip = !!item.emails;

            return (
              hasTooltip ?
                <NoMaxWidthTooltip
                  key={item.week}
                  placement="right"
                  title={
                    <Stack>
                      {item.emails?.map(({ email, subject }) => (
                        <div>
                          {email}, {subject}
                        </div>
                      ))}
                    </Stack>
                  }
                >
                  <WeekContent item={item} index={index} />
                </NoMaxWidthTooltip>

                : <WeekContent key={item.week} item={item} index={index} />
            )
          })}
        </svg>
      ),
    },
    {
      field: 'engagementEMA',
      headerName: 'Avg',
      flex: 100,
      sortable: false,
    },
  ]

  const trafficColumns = [
    {
      field: 'id',
      headerName: '',
      width: 40,
      sortable: false,
      filterable: false,
      renderCell: ({ value, row }) => {
        return (
          <Stack>
            <Stack onClick={e => setAnchorEl(e.currentTarget)} id={`product-name-${row.id}`}>
              <Checkbox
                size="small"
                checked={row.users?.reduce((isChecked, user) => {
                  return isChecked || !!userSelection.find(_user => user.user_id === _user.user_id) || recentEmails.includes(user.email)
                }, false)}
                disabled
              />
            </Stack>
            <Popover
              open={anchorEl && anchorEl.getAttribute('id') === `product-name-${row.id}`}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              onClose={() => setAnchorEl()}
            >
              <Paper sx={{ p: 2 }}>
                <Stack>
                  {row.users?.map(user => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={!!userSelection.find(_user => user.user_id === _user.user_id)}
                            onChange={() => handleUserSelectChange({ ...user, ...row })}
                            disabled={recentEmails.includes(user.email)}
                          />}
                        label={`${user.email} (${user.first_name || ''} ${user.last_name || ''}${user.job_title ? `, ${user.job_title})` : ')'} ${user.user_type}`}
                      />
                    )
                  })}
                </Stack>
              </Paper>
            </Popover>
          </Stack>
        )
      },
    },
    {
      field: 'name',
      headerName: 'Product',
      flex: 160,
      sortable: false,
      renderCell: ({ value, row }) => {
        return (
          <Stack>
            <a target="_blank" style={{ fontWeight: 'normal' }} href={`/products/${row.slug}/profile`}>{row.name}</a>
          </Stack>
        )
      },
    },
    {
      // field: 'engagementEMADupe',
      headerName: 'profileViews',
      sortable: false,
      filterable: false,
      flex: 1200,
      renderHeader: ({ params }) => (
        <svg width={weeks?.length * width} height={height} >
          {weeks?.map((week, index) => {
            const month = moment(week).format('M')
            const prevMonth = index > 0 && moment(weeks[index - 1]).format('M')

            return (
              <g
                key={week}
                transform={`translate(${width * index - 8},0)`}
              >
                <text
                  x={width / 2}
                  y={height / 2 + 2}
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fontSize="12"
                  fill={"#095256"}
                >
                  {index > 0 && month !== prevMonth && moment(week).format('MMM')}
                </text>
              </g>
            )
          })}
        </svg>
      ),
      renderCell: ({ row }) => (
        <svg width={weeks?.length * width} height={height} style={{ background: '#fff' }}>
          {row.weeks?.map((item, index) => {

            const hasTooltip = !!item.emails;

            return (
              hasTooltip ?
                <NoMaxWidthTooltip
                  key={item.week}
                  placement="right"
                  title={
                    <Stack>
                      {item.emails?.map(({ email, subject }) => (
                        <div>
                          {email}, {subject}
                        </div>
                      ))}
                    </Stack>
                  }
                >
                  <TrafficWeekContent item={item} index={index} />
                </NoMaxWidthTooltip>

                : <TrafficWeekContent key={item.week} item={item} index={index} />
            )
          })}
        </svg>
      ),
    },
    {
      field: 'profileViewsEMA',
      headerName: 'Avg',
      flex: 100,
      sortable: false,
    },
  ]

  const columns = view === 'PERFORMANCE' ? metricsColumns :
    view === 'ENGAGEMENT' ? engagementColumns :
      view === 'TRAFFIC' ? trafficColumns :
        accountColumns

  const selectEmailList = async listId => {
    setEmailList(listId)
  }

  // const sendEmail = async () => {
  //   const users = uniqBy(userSelection, 'email')

  //   for (const user of users) {
  //     const options = {
  //       method: 'POST',
  //       headers: {
  //         accept: 'application/json',
  //         'content-type': 'application/json',
  //         'api-key': SIB_API_KEY,
  //       },
  //       body: JSON.stringify({
  //         attributes: {
  //           FIRSTNAME: user.first_name,
  //           LASTNAME: user.last_name,
  //           JOB_TITLE: user.job_title,
  //           PRODUCT_NAME: user.name,
  //           SLUG: user.slug,
  //           ORGANISATION_TYPE: 'Supplier',
  //           TOTAL_LEADS: user.total_leads,
  //           SUGGESTED_PRICE_PER_LEAD: user.suggested_price_per_lead,
  //           SUGGESTED_PRICE_PER_LEAD_SAVINGS: currency(user.suggested_price_per_lead, { precision: 0 }).multiply(25).multiply(0.25).format({ symbol: '£' }),
  //         },
  //         updateEnabled: true,
  //         email: user.email,
  //         listIds: [parseInt(emailList)],
  //       })
  //     };

  //     await fetch('https://api.brevo.com/v3/contacts', options)
  //       .then(response => response.json())
  //       .then(response => console.log(response))
  //       .catch(err => console.error(err));
  //   }

  //   setUserSelection([])
  // }

  if (!products) return <Spinner />

  return (
    <Stack spacing={4}>
      <Stack>
        <Stack direction="row" justifyContent="space-between">
          <FormControl>
            <RadioGroup
              row
              value={view}
              onChange={e => setView(e.target.value)}
            >
              <FormControlLabel value="ACCOUNT" control={<Radio />} label="Account" />
              <FormControlLabel value="PERFORMANCE" control={<Radio />} label="Performance" />
              <FormControlLabel value="ENGAGEMENT" control={<Radio />} label="Engagement" />
              <FormControlLabel value="TRAFFIC" control={<Radio />} label="Traffic" />
            </RadioGroup>
          </FormControl>

          <FormControl>
            <InputLabel>Plan</InputLabel>
            <Select
              value={filters.plan}
              label="Plan"
              onChange={e => setFilters(filters => ({
                ...filters,
                plan: e.target.value,
              }))}
              sx={{ marginBottom: 0 }}
            >
              <MenuItem value='all'>All</MenuItem>
              <MenuItem value='paid'>Paid</MenuItem>
              <MenuItem value='free'>Free</MenuItem>
              <MenuItem value='unclaimed'>Unclaimed</MenuItem>
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel>Category</InputLabel>
            <Select
              value={filters.categoryId}
              label="Category"
              onChange={e => setFilters(filters => ({
                ...filters,
                categoryId: e.target.value,
              }))}
              sx={{ marginBottom: 0 }}
            >
              <MenuItem value="">All</MenuItem>
              {categories.map(cat => (
                <MenuItem value={cat.id}>{cat.title}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel>Subject</InputLabel>
            <Select
              value={filters.subjectId}
              label="Category"
              onChange={e => setFilters(filters => ({
                ...filters,
                subjectId: e.target.value,
              }))}
              sx={{ marginBottom: 0 }}
            >
              <MenuItem value="">All</MenuItem>
              {subjects.map(cat => (
                <MenuItem value={cat.id}>{cat.title}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <Stack direction="row" spacing={2} alignItems="center">
            <FormControl>
              <InputLabel>Email list</InputLabel>
              <Select
                value={emailList}
                label="Email to send"
                onChange={e => selectEmailList(e.target.value)}
                sx={{ marginBottom: 0 }}
              >
                {lists.map(list => (
                  <MenuItem value={list.id}>{list.name}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              variant="contained"
              // onClick={sendEmail}
            >
              Send
            </Button>
          </Stack>

        </Stack>

        <Stack direction="row" justifyContent="flex-end">
          <FormControl>
            <FormControlLabel
              label="Disable recently emailed"
              control={
                <Checkbox
                  checked={excludeRecent}
                  onChange={() => setExcludeRecent(!excludeRecent)}
                />}
              sx={{ mr: 0 }}
            />
          </FormControl>
        </Stack>
      </Stack>

      <Stack sx={{ height: 'calc(100vh - 10rem)' }}>
        <DataGridPro
          rows={products}
          columns={columns}
          disableRowSelectionOnClick
          sortModel={columns[sortModel.field] !== undefined ? sortModel : []}
          onSortModelChange={(newSortModel) => view !== 'ENGAGEMENT' && setSortModel(newSortModel)}
          sortingMode="server"
          initialState={{
            pagination: {
              paginationModel: { pageSize: 2000, page: 0 },
            },
          }}
          slots={{
            toolbar: () => (
              <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarExport />
              </GridToolbarContainer>
            )
          }}
        />
      </Stack>

      {/* <Stack spacing={1}>
        {totals.map(({weeks, productName, key, showValue}) => {
          return (
            <Stack key={productName}>
              <svg width={weeks?.length * width + marginLeft} height={height} style={{background: '#fff'}}>
                <text
                  x={0}
                  y={15}
                  fontSize="12"
                  fill="#095256"
                >
                  {productName}
                </text>

                {weeks?.map((item, index) => {
                  return (
                    <g
                      key={item.week}
                      transform={`translate(${width * index + marginLeft} ,0)`}
                    >
                      <rect
                        x={0}
                        y={0}
                        width={width}
                        height={height}
                        style={{
                          fill: '#095256',
                          fillOpacity: item[key] / (key ===  'engagementScore' ? 2 : 60),
                        }}
                      />
                      {item[key] > 0 && showValue &&
                        <text
                          x={width/2 - 4}
                          y={width/2 + 4}
                          fontSize="12"
                          fill={item[key] > 5 ? "#CEDCDD" : "#095256"}
                        >
                            {item[key]}
                        </text>
                      }
                    </g>
                  )
                })}
              </svg>
            </Stack>
          )
        })}
      </Stack> */}
    </Stack>
  );
}

export default Engagement;


const calculateEMA = (dataRaw, period, key) => {
  let data = dataRaw.map(item => item[key] || 0)
  const k = 2 / (period + 1)
  let emaData = []
  emaData[0] = data[0]
  for (let i = 1; i < data.length; i++) {
    let newPoint = (data[i] * k) + (emaData[i - 1] * (1 - k))
    emaData.push(newPoint)
  }
  let currentEma = [...emaData].pop()
  return Math.round(currentEma)
}
