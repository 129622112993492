import React, { useState, useEffect, Suspense, lazy } from 'react';
import {
  Container, Col, Card, CardBody,
} from 'reactstrap';
import { withRouter, Route, Redirect, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoadingSpinner from '../../components/Loader';
import * as actions from './actions';
import * as brandActions from '../BrandAdmin/actions';
import Dashboard from '../Dashboard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReviewsIframe from './ReviewsIframe';
import servicesIcon3 from "../../assets/images/services-icon/01.png";
import useAuth from "../../pages/Authentication/useAuth";
import _ from 'lodash';
import { withTracking } from 'react-tracking';
import usePrevious from '../../helpers/usePrevious';
import Profile from './Profile';

const InviteUpload = lazy(() => import('./InviteUpload'));
const Invitations = lazy(() => import('./Invitations'));
const InvitationPreview = lazy(() => import('./InvitationPreview'));
const Analytics = lazy(() => import('./Analytics'));
const ReviewCollection = lazy(() => import('./ReviewCollection'));
const ReviewCollectionAutomatic = lazy(() => import('./ReviewCollectionAutomatic'));
const ReviewExport = lazy(() => import('./ReviewExport'));
const Admin = lazy(() => import('./Admin'));
const AdminEmails = lazy(() => import('./AdminEmails'));
const Widgets = lazy(() => import('./Widgets'));
const Badges = lazy(() => import('./Badges'));
const Awards = lazy(() => import('./Awards'));
const Evidence = lazy(() => import('./Evidence'));
const ImpactMetrics = lazy(() => import('./ImpactMetrics'));
const Exposure = lazy(() => import('./Exposure'));
const EmailWidgets = lazy(() => import('./EmailWidgets'));
const SurveyPreview = lazy(() => import('./SurveyPreview'));
const Reviews = lazy(() => import('./Reviews'));
const Notifications = lazy(() => import('./Notifications'));
const UserManagement = lazy(() => import('./UserManagement'));
const Categories = lazy(() => import('./Categories'));

const DeviceAdmin = ({
  devicePending,
  reviewsPending,
  invitesPending,
  devices,
  device,
  match,
  dispatchConfigRequest,
  dispatchDeviceRequest,
  dispatchInvitesRequest,
  dispatchSelectDevice,
  dispatchReviewsRequest,
  dispatchBrandRequest,
  tracking,
}) => {

  const { currentUser } = useAuth();
  const prevDevice = usePrevious(device);

  useEffect(() => {
    dispatchConfigRequest();
  
    if (!devices) {
      dispatchDeviceRequest();
      dispatchBrandRequest();
    }
  }, []);

  useEffect(() => {
    const hasMatchedDevices = devices?.filter(p => p.deviceMatch).length > 0;

    if (!hasMatchedDevices && (devices || match.params.slug) && (!prevDevice || match.params.slug !== prevDevice.slug)) {
      const slug = match.params?.slug || device?.slug || devices[0]?.slug;

      if (slug) {
        !devicePending && dispatchSelectDevice(slug);
      }
    }
  }, [match.params.slug, device, devices]);

  useEffect(() => {
    if (device && prevDevice?.slug === device.slug) {
      tracking.trackEvent({ event: 'update-device' });
    }
  }, [device]);

  if (!devices || devicePending) return <LoadingSpinner />;
    
  if (devices && devices.filter(p => p.deviceMatch).length > 0) {
    return (
      <Col md={8}>
        <Card className="mini-stat bg-primary text-white mt-4">
          <CardBody>
            <div className="mb-4">
              <div className="float-left mini-stat-img mr-4">
                <img src={servicesIcon3} alt="" />
              </div>
              <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                You have matching devices
              </h5>
              <h4 className="font-weight-medium font-size-18">
                Your email address matches our records for {devices.filter(p => p.deviceMatch).map(p => p.name).join(', ')}.
              </h4>
              <h4 className="font-weight-medium font-size-18">
                Please contact <a className="text-white" href="mailto:hello@edtechimpact.com">hello@edtechimpact.com</a> to be added as an administrator of this device listing.
              </h4>
            </div>
          </CardBody>
        </Card>
      </Col>
    )
  }

  if (devices.length < 2 && !device) return <Redirect to="/devices/add-listing/profile" />;
    
  if (match.params.slug && !devices.find(p => p.slug === match.params.slug)) {
    return <Redirect to='/' />;
  }
    
  if (!match.params.slug && devices.length === 2) return <Redirect to={`/devices/${devices[0].slug}/profile`} />;
  
  if (devices.length > 1 && !device) return <Redirect to={`/devices/${match.params.slug || devices[0].slug}/profile`} />;

  return (
    <StyledDeviceAdmin fluid>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
      />

      <Suspense fallback={<LoadingSpinner />}>
        <Switch>
          <AdminRoute
            path="/devices/:slug/admin"
            exact
            render={() => <Admin />}
          />
          <AdminRoute
            path="/devices/:slug/admin-emails"
            exact
            render={() => <AdminEmails />}
          />

          <Route
            path="/devices/:slug/dashboard"
            exact
            render={() => <Dashboard />}
          />
          <Route
            path="/devices/:slug/analytics"
            exact
            render={() => <Analytics device={device} />}
          />
          <Route
            path="/devices/:slug/reviews/manual-collection"
            exact
            render={() => <ReviewCollection />}
          />
          <Route
            path="/devices/:slug/reviews/automatic-collection"
            exact
            render={() => <ReviewCollectionAutomatic />}
          />
          <Route
            path="/devices/:slug/reviews/export"
            exact
            render={() => <ReviewExport />}
          />
          <Route
            path="/devices/:slug/reviews/survey"
            exact
            render={() => <SurveyPreview device={device} />}
          />
          <Route
            path="/devices/:slug/profile"
            exact
            render={() => <Profile />}
          />
          <Route
            path="/devices/:slug/categories"
            exact
            render={() => <Categories />}
          />
          <Route
            path="/devices/:slug/impact-metrics"
            exact
            render={() => <ImpactMetrics />}
          />
          <Route
            path="/devices/:slug/exposure"
            exact
            render={() => <Exposure />}
          />
          <Route
            path="/devices/:slug/evidence"
            exact
            render={() => <Evidence />}
          />
          <Route
            path="/devices/:slug/widgets"
            exact
            render={() => <Widgets device={device} />}
          />
          <Route
            path="/devices/:slug/reviews-page"
            exact
            render={() => <ReviewsIframe device={device} />}
          />
          <Route
            path="/devices/:slug/marketing-assets"
            exact
            render={() => <Badges device={device} />}
          />
          <Route
            path="/devices/:slug/awards"
            exact
            render={() => <Awards device={device} />}
          />
          <Route
            path="/devices/:slug/email-widgets"
            exact
            render={() => <EmailWidgets device={device} />}
          />
          <Route
            path="/devices/:slug/reviews/your-reviews"
            exact
            render={() => <Reviews />}
          />
          <Route
            path="/devices/:slug/reviews/invitations"
            exact
            render={() => <Invitations />}
          />
          <Route
            path="/devices/:slug/reviews/invite-email"
            exact
            render={() => <InvitationPreview />}
          />
          <Route
            path="/devices/:slug/reviews/upload-invites"
            exact
            render={() => <InviteUpload />}
          />
          <Route
            path="/devices/:slug/admin/notifications"
            exact
            render={() => <Notifications />}
          />
          <Route
            path="/devices/:slug/admin/users"
            exact
            render={() => <UserManagement />}
          />
        </Switch>
      </Suspense>
    </StyledDeviceAdmin>
  );
}


const AdminRoute = props => {
  const { currentUser } = useAuth();
  if (!currentUser || !currentUser.isAdmin) return null;
  return <Route {...props} />;
}

const StyledDeviceAdmin = styled(Container)`
  position: relative;
  margin-bottom: 3rem;
`;

const mapStateToProps = state => {
  const { device, devices, devicePending, invitesPending, devicesPending, updatePending, reviewsPending } = state.Device;
  return { device, devices, devicePending, invitesPending, devicesPending, updatePending, reviewsPending };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dispatchDeviceRequest: actions.deviceRequestAction,
      dispatchSelectDevice: actions.selectDevice,
      dispatchToggleFilter: actions.devicesToggleFilterAction,
      dispatchToggleSubject: actions.devicesToggleSubjectAction,
      dispatchToggleFeature: actions.devicesToggleFeatureAction,
      dispatchToggleType: actions.devicesToggleTypeAction,
      dispatchToggleCheckbox: actions.devicesToggleCheckboxAction,
      dispatchSubmit: actions.updateDeviceAction,
      dispatchPlanSelect: actions.devicesSelectPlanAction,
      dispatchReviewChange: actions.reviewChange,
      dispatchDetailsChange: actions.widgetDetailsChange,
      dispatchResetState: actions.resetState,
      dispatchReviewsRequest: actions.reviewsRequestAction,
      dispatchConfigRequest: actions.configRequestAction,
      dispatchInvitesRequest: actions.invitesRequestAction,
      dispatchBrandRequest: brandActions.brandRequestAction,
    },
    dispatch,
  );

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
  withTracking(),
)(DeviceAdmin);
