import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga'
import { seamlessImmutableReconciler, seamlessImmutableTransformCreator } from 'redux-persist-seamless-immutable'

import rootReducer from './reducers';
import rootSaga from './sagas';

import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const history = createBrowserHistory();

const transformerConfig = {
  whitelistPerReducer: [],
  blacklistPerReducer: [],
}

const persistedReducer = persistReducer({
  key: 'root',
  whitelist: ['App'],
  storage,
  stateReconciler: seamlessImmutableReconciler,
  transforms: [seamlessImmutableTransformCreator(transformerConfig)],
}, rootReducer(history));

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = (preloadedState) => {
  const store = createStore(
    persistedReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(
      sagaMiddleware,
      routerMiddleware(history),
    )),
  );
  sagaMiddleware.run(rootSaga);
  return store;
};

export const store = configureStore();
export const persistor = persistStore(store);
