import Immutable from 'seamless-immutable';
import * as actionTypes from './constants';

export const initialState = Immutable({
  resellers: null,
  resellersPending: false,
  reseller: null,
  resellerPending: false,
  createPending: false,
});

function resellerReducer(state = initialState, action) {
  const emptyReseller = {slug: 'add-listing', name: '+ Add Listing', plan: 'free'};

  switch (action.type) {
    case actionTypes.RESET_STATE:
      return initialState;

    case actionTypes.CONFIG_REQUEST_SUCCESS:
      return state
        .set('config', action.payload.config)
        .set('planConfig', action.payload.planConfig);


    case actionTypes.UPDATE_CONFIG:
      return state.set('updatePending', true);
    case actionTypes.UPDATE_CONFIG_SUCCESS:
      return state
        .set('config', action.payload);

    case actionTypes.UPDATE_CONFIG_COMPLETE:
      return state.set('updatePending', false);

    case actionTypes.UPDATE_RESELLER:
      return state.set('updatePending', true);
    case actionTypes.COMPLETE_UPDATE_RESELLER:
      return state.set('updatePending', false);

    case actionTypes.UPLOAD_EVIDENCE:
      return state.set('updatePending', true);

    case actionTypes.DELETE_EVIDENCE:
      return state.set('updatePending', true);

    case actionTypes.RESELLER_REQUEST:
      return state.set('resellersPending', true);
    case actionTypes.RESELLER_REQUEST_SUCCESS:
      if (state.resellers && state.resellers.length > 1) {
        return state.set('resellers', action.payload);
      } else {
        return state.set('resellers', [...action.payload, emptyReseller]);
      }

    case actionTypes.RESELLER_REQUEST_COMPLETE:
      return state.set('resellersPending', false);

    case actionTypes.CREATE_RESELLER:
      return state.set('createPending', true);
    case actionTypes.CREATE_RESELLER_SUCCESS:
      return state.set('resellers', state.resellers ? [...state.resellers.slice(0, state.resellers.length-1), action.reseller, emptyReseller] : [action.reseller, emptyReseller]);
    case actionTypes.CREATE_RESELLER_COMPLETE:
      return state.set('createPending', false);
    
    case actionTypes.EXPORT_REVIEWS:
      return state.set('updatePending', true);
    case actionTypes.EXPORT_ALL_REVIEWS:
      return state.set('updatePending', true);
    case actionTypes.EXPORT_ALL_RESELLERS:
      return state.set('updatePending', true);
    case actionTypes.EXPORT_COMPLETE:
      return state.set('updatePending', false);

    case actionTypes.INVITES_REQUEST:
      return state.set('invitesPending', true);
    case actionTypes.INVITES_REQUEST_SUCCESS:
      return state.set('invites', action.payload);
    case actionTypes.INVITES_REQUEST_COMPLETE:
      return state.set('invitesPending', false);

    case actionTypes.INVITES_UPLOAD:
      return state.set('invitesPending', true);
    case actionTypes.INVITES_UPLOAD_SUCCESS:
      return state.set('invites', action.payload);
    case actionTypes.INVITES_UPLOAD_COMPLETE:
      return state.set('invitesPending', false);
    
    case actionTypes.REVIEWS_REQUEST:
      return state.set('reviewsPending', true);
    case actionTypes.REVIEWS_REQUEST_SUCCESS:
      return state.set('reviews', action.reviews);
    case actionTypes.REVIEWS_REQUEST_COMPLETE:
      return state.set('reviewsPending', false);

    case actionTypes.REVIEW_REPLY:
      return state.set('updatePending', true);
    case actionTypes.REVIEW_REPLY_SUCCESS:
      const reviewIndex = state.reviews.data.findIndex(r => r.TYPE === action.payload.TYPE);
      return state.setIn(['reviews', 'data', reviewIndex], action.payload);
    case actionTypes.REVIEW_REPLY_COMPLETE:
      return state.set('updatePending', false);

    case actionTypes.SELECT_RESELLER:
      return state.set('resellerPending', true);
    case actionTypes.SELECT_RESELLER_SUCCESS:
      return state.set('reseller', action.reseller);
    case actionTypes.SELECT_RESELLER_COMPLETE:
      return state.set('resellerPending', false);
   
    default:
      return state;
  }
}

export default resellerReducer;
