import { Chip, Stack, Typography } from "@mui/material";
import SellIcon from '@mui/icons-material/Sell';
import DateTimeTooltip from "@ei/components/date-time-tooltip";

const LeadSnippet = ({ lead }) => {
  const { email, name, type, institution_type, institution_name, country, approved_by, approved_at, created_at, status, vendor_status, deleted } = lead

  return (
    <Stack direction="column" spacing={2} mb={4}>
      <Stack direction="row" spacing={1}>
        <SellIcon fontSize="small" />
        <Typography mb={0} fontWeight="bold" sx={{ textTransform: "capitalize" }}>{type} Lead</Typography>
        <Chip size="small" label={email} />
      </Stack>
      <Stack direction="column" sx={{ alignItems: "flex-start" }}>
        <DateTimeTooltip value={created_at} typographySx={{ marginBottom: 0 }} />
        <Typography mb={0}><strong>From: </strong>{name} ({institution_type} — {institution_name}{country ? `, ${country}` : ''})</Typography>
        <Typography mb={0}><strong>Vendor status: </strong>{!!approved_at && vendor_status ? vendor_status : "N/A"}</Typography>
        <Typography mb={0}><strong>Buyer notification email status: </strong>{!!approved_at && status ? status : "N/A"}</Typography>
        <Typography mb={0}><strong>Approved: </strong>{!!approved_at ? "Yes" : "No"}</Typography>
        <Typography mb={0}><strong>Rejected: </strong>{vendor_status === "rejected" && deleted ? "Yes" : "No"}</Typography>
        <Typography mb={0}><strong>Deleted: </strong>{deleted ? "Yes" : "No"}</Typography>
      </Stack>
    </Stack>
  )
}

export default LeadSnippet