import { takeLatest, takeEvery, all, call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as actionTypes from './constants';
import * as actions from './actions';
import {
  selectDevice,
  selectDevices,
} from './selectors';
import { URLToFormData } from '../../utils/helpers';
import ApiService from '../../services/apiService';
import { saveAs } from 'file-saver';
import { toast } from "react-toastify";
import axios from 'axios';
import moment from 'moment';

const scrollToTop = () => {
  document.body.scrollTop = document.documentElement.scrollTop = 0;
}

export function* configRequest() {
  try {
    const config = yield call([ApiService, 'getConfig']);
    yield put(actions.configRequestSuccessAction(config));
  } catch (err) {
    console.log(err);
  } finally {
    yield put(actions.configRequestCompleteAction());
  }
}

export function* updateConfig(action) {
  scrollToTop();
  try {
    const config = yield call([ApiService, 'updateConfig'], action.payload);
    yield put(actions.updateConfigSuccessAction(config));
    toast.success("Saved!");
  } catch (err) {
    toast.error("Error.");
    console.log(err);
  } finally {
    yield put(actions.updateConfigCompleteAction());
  }
}

export function* deviceRequest() {
  try {
    const result = yield call([ApiService, 'getUserItems'], 'device');
    yield put(actions.deviceRequestSuccessAction(result));
  } catch (err) {
    throw err;
  } finally {
    yield put(actions.deviceRequestCompleteAction());
  }
}

export function* selectDeviceSaga(action) {
  try {
    let device;
    if (action.slug === 'add-listing') {
      device = {slug: 'add-listing', name: '+ Add Listing', plan: 'free'};
    } else {
      device = yield call([ApiService, 'getUserItem'], action.slug, 'device');
    }
    yield put(actions.selectDeviceSuccess(device));
  } catch (error) {
    console.log(error);
  } finally {
    yield put(actions.selectDeviceComplete());
  }
}

export function* exportReviews() {
  try {
    const device = yield select(selectDevice);
    const reviewsExport = yield call([ApiService, 'reviewsExport'], {device: device.slug});
    const blob = new Blob([reviewsExport], {type: "text/plain;charset=utf-8"});
    saveAs(blob, "reviews.csv");
    toast.success("Export successful!");
  } catch (err) {
    toast.error("Error.");
    throw err;
  } finally {
    yield put(actions.exportComplete());
  }
}

export function* exportAllReviews() {
  try {
    const reviewsExport = yield call([ApiService, 'allReviewsExport']);
    const blob = new Blob([reviewsExport], {type: "text/plain;charset=utf-8"});
    saveAs(blob, "all-reviews.csv");
    toast.success("Export successful!");
  } catch (err) {
    toast.error("Error.");
    throw err;
  } finally {
    yield put(actions.exportComplete());
  }
}

export function* exportAllDevices() {
  try {
    const reviewsExport = yield call([ApiService, 'allDevicesExport']);
    const blob = new Blob([reviewsExport], {type: "text/plain;charset=utf-8"});
    saveAs(blob, "all-devices.csv");
    toast.success("Export successful!");
  } catch (err) {
    toast.error("Error.");
    throw err;
  } finally {
    yield put(actions.exportComplete());
  }
}

export function* createDevice(action) {
  scrollToTop();
  try {
    const device = yield call([ApiService, 'createItem'], action.device, 'device');

    if (device.data && device.data.error) {
      toast.error(device.data.error.description || "Error. Not created.");

    } else {
      yield call(updateDevice, {
        ...action,
        device,
      });

      yield put(actions.createDeviceSuccessAction({device}));
      yield put(push(`/devices/${device.slug}/profile`));
      toast.success("Listing created!");
    }

  } catch (err) {
    console.log(err);
    toast.error(err.description || "Error. Not created.");
  } finally {
    yield put(actions.createDeviceCompleteAction());
  }
}

export function* uploadEvidence(action) {
  try {
    if (action.payload.length) {
      const devices = yield select(selectDevices);
      const device = yield select(selectDevice);
      const { slug } = device;
      const files = action.payload.map(study => study.file.name);
      const signedUrls = yield call([ApiService, 'postEvidence'], {files, slug});
      
      let i = 0;
      for (const study of action.payload) {
        const signedUrl = signedUrls[i];
        const formData = new FormData();
        Object.entries(signedUrl.fields).forEach(([k, v]) => {
          formData.append(k, v);
        });
        formData.append("file", study.file);
        yield call(axios.post, signedUrl.url, formData);
        i++;
      }

      const newCaseStudies = action.payload.map((study, index) => ({
        link: {
          type: 'file',
          location: signedUrls[index].fields.key.split('/')[signedUrls[index].fields.key.split('/').length - 1],
        },
        schoolName: study.title,
        date: moment(study.date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        location: '',
        type: study.type,
      }));

      const newDevice = {
        ID: device.ID,
        TYPE: device.TYPE,
        slug: device.slug,
        caseStudies: device.caseStudies ? [...device.caseStudies, ...newCaseStudies] : [...newCaseStudies],
      }

      const result = yield call([ApiService, 'updateDevice'], { device: newDevice });

      const updatedDevices = devices.map(p => {
        if (p.slug === result.slug) return result;
        return p;
      });
      yield put(actions.deviceRequestSuccessAction(updatedDevices));
      yield put(actions.selectDeviceSuccess(result));
    }

    toast.success("Saved!");
    
  } catch (err) {
    console.log(err);
    toast.error("Error. Not saved.");
  } finally {
    yield put(actions.completeUpdateDevice());
  }
}

export function* deleteEvidence(action) {
  try {
    const devices = yield select(selectDevices);
    const { slug } = yield select(selectDevice);
    const result = yield call([ApiService, 'deleteEvidence'], {slug, id: action.payload});
    const updatedDevices = devices.map(p => {
      if (p.slug === result.slug) return result;
      return p;
    });
    yield put(actions.deviceRequestSuccessAction(updatedDevices));
    yield put(actions.selectDeviceSuccess(result));
    toast.success("Deleted!");
    
  } catch (err) {
    console.log(err);
    toast.error("Error. Not deleted.");
  } finally {
    yield put(actions.completeUpdateDevice());
  }
}

export function* updateDevice(action) {
  scrollToTop();
  try {
    const { screenshots, logo, device } = action;

    const { slug } = action.device;
    const newDevice = device;

    if (screenshots) {
      const form = new FormData();
      let i = 0;

      for (const image of screenshots) {
        i++;
        const file = yield call(URLToFormData, [image.source]);
        form.append(`file${i}`, file);
      }
      newDevice.images = yield call([ApiService, 'postMedia'], {form, slug, type: 'image', target: 'screenshots'});
    }

    if (logo && logo.source) {
      const form = new FormData();
      const file = yield call(URLToFormData, [logo.source]);
      form.append('file', file);
      newDevice.logo = yield call([ApiService, 'postMedia'], {form, slug, type: 'image', target: 'logo'});
    }
  
    const result = yield call([ApiService, 'updateItem'], newDevice, 'device');
    yield put(actions.selectDeviceSuccess(result));
    toast.success("Saved!");
    
  } catch (err) {
    toast.error("Error. Not saved.");
    throw err;
  } finally {
    yield put(actions.completeUpdateDevice());
  }
}

export function* reviewsRequest(action) {
  scrollToTop();

  const device = action.payload.slug;
  const page = action.payload.page;
  const featured = action.payload.featured;
  const reviewPreference = action.payload.reviewPreference;
  const perPage = action.payload.perPage || 10;

  try {
    const result = yield call([ApiService, 'reviews'], {
      device,
      page,
      featured,
      reviewPreference,
      perPage,
    });
    yield put(actions.reviewsRequestSuccessAction(result));
  } catch (err) {
    throw err;
  } finally {
    yield put(actions.reviewsRequestCompleteAction());
  }
}

export function* invitesRequest(action) {
  scrollToTop();
  const { page=1, perPage=100, slug } = action.payload;

  try {
    const result = yield call([ApiService, 'getInvites'], {
      slug,
      page,
      perPage,
    });
    yield put(actions.invitesRequestSuccessAction(result));
  } catch (err) {
    throw err;
  } finally {
    yield put(actions.invitesRequestCompleteAction());
  }
}

export function* invitesUpload(action) {
  scrollToTop();
  try {
    const { slug } = yield select(selectDevice);
    const result = yield call([ApiService, 'createInvites'], {
      slug, invites: action.payload.invites, sendDate: action.payload.sendDate,
    });
    yield put(actions.invitesUploadSuccessAction(result));
    toast.success("Success!");
    yield put(push(`/devices/${slug}/reviews/invitations`));
  } catch (err) {
    toast.error("Error.");
    console.log(err);
  } finally {
    yield put(actions.invitesUploadCompleteAction());
  }
}

export function* reviewReply(action) {
  try {
    const result = yield call([ApiService, 'replyToReview'], action.payload);
    yield put(actions.reviewReplySuccessAction({
      ...action.payload.review,
      reply: action.payload.reply,
    }));
    toast.success("Success!");
  } catch (err) {
    toast.error("Error.");
    console.log(err);
  } finally {
    yield put(actions.reviewReplyCompleteAction());
  }
}

export default function* watch() {
  yield takeLatest(actionTypes.DEVICE_REQUEST, deviceRequest);
  yield takeLatest(actionTypes.CONFIG_REQUEST, configRequest);
  yield takeLatest(actionTypes.CREATE_DEVICE, createDevice);
  yield takeLatest(actionTypes.UPDATE_DEVICE, updateDevice);
  yield takeLatest(actionTypes.SELECT_DEVICE, selectDeviceSaga);
  yield takeLatest(actionTypes.EXPORT_REVIEWS, exportReviews);
  yield takeLatest(actionTypes.EXPORT_ALL_REVIEWS, exportAllReviews);
  yield takeLatest(actionTypes.EXPORT_ALL_DEVICES, exportAllDevices);
  yield takeLatest(actionTypes.REVIEWS_REQUEST, reviewsRequest);
  yield takeLatest(actionTypes.INVITES_REQUEST, invitesRequest);
  yield takeLatest(actionTypes.INVITES_UPLOAD, invitesUpload);
  yield takeLatest(actionTypes.UPLOAD_EVIDENCE, uploadEvidence);
  yield takeLatest(actionTypes.DELETE_EVIDENCE, deleteEvidence);
  yield takeLatest(actionTypes.UPDATE_CONFIG, updateConfig);
  yield takeLatest(actionTypes.REVIEW_REPLY, reviewReply);
}
