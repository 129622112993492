import React, { useState } from "react";
import { Row, Col, Card, CardBody, Alert } from "reactstrap";

import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import Loader from '../../components/Loader';

// action
import { Auth, Logger, JS } from 'aws-amplify';

// import images
import logoSm from "../../assets/images/logo-sm.png";

const ForgotPasswordPage = ({ match }) => {
  const [codeSent, setCodeSent] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [error, setError] = useState(false);

  const handleValidateSubmit = async (event, values) => {
    event.preventDefault();
    setIsSendingCode(true);
    try {
      await Auth.forgotPassword(values.email.toLowerCase());
      error && setError(false);
      setCodeSent(true);
    } catch (error) {
      console.log(error);
      if (error.name === 'UserNotFoundException') {
        error.message = () => <>Login details not found. To create a login and manage your product please <Link to="/register">register here</Link>.</>;
      }
      setError(error.message);
    } finally {
      setIsSendingCode(false);
    }
  }

   const handleConfirmClick = async (event, values) => {
    event.preventDefault();
    setIsConfirming(true);
    try {
      await Auth.forgotPasswordSubmit(
        match.params.email,
        match.params.code,
        values.password,
      );
      error && setError(false);
      setConfirmed(true);
    } catch (error) {
      console.log(error);
      setError(error.message);
    } finally {
      setIsConfirming(false);
    }
  }

  const isLoading = isConfirming || isSendingCode;
  const { email, code } = match.params;
  const defaultValues = { email, code };

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-5">
        <div className="container">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="position-relative">
                <Card className="overflow-hidden">
                  <CardBody className="p-4">
                    <h3 className="text-center">Forgot Password</h3>
                    <p className="text-center">
                      Enter your email below and we will send a link to reset your password.
                    </p>

                    <div className="p-3">
                      {error && (
                        <Alert color="danger" style={{ marginTop: "24px" }}>
                          {error}
                        </Alert>
                      )}
                      {codeSent && (
                        <Alert color="success" style={{ marginTop: "24px" }}>
                          Message sent. Please check your email for the link.
                        </Alert>
                      )}

                      {confirmed && (
                        <Alert color="success" style={{ marginTop: "24px" }}>
                          Password changed. You can now <Link to="/">login</Link>.
                        </Alert>
                      )}

                      <AvForm
                        disabled={isLoading}
                        model={defaultValues}
                        className="form-horizontal"
                        onValidSubmit={code ? handleConfirmClick : handleValidateSubmit}
                      >
                        {!email &&
                          <div className="form-group">
                            <AvField
                              name="email"
                              label="Email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              required
                            />
                          </div>
                        }
                        
                        {code &&
                          <div className="form-group">
                            <AvField
                              name="password"
                              label="New Password"
                              className="form-control"
                              placeholder="Enter password"
                              type="password"
                              required
                            />
                          </div>
                        }
                        <Row className="form-group">
                          <Col>
                            {isLoading ? <Loader /> :
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              type="submit"
                            >
                              {code ? 'Reset' : 'Send'}
                            </button>}
                          </Col>
                        </Row>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Go back to{" "}
                    <Link
                      to="/"
                      className="font-weight-medium text-primary"
                    >
                      Login
                    </Link>{" "}
                  </p>
                  <p className="mb-0">
                    © {new Date().getFullYear()} Crafted with{" "}
                    <i className="mdi mdi-heart text-danger"></i> by
                    EdTech Impact
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withRouter(ForgotPasswordPage);
