import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoadingSpinner from '../../components/Loader';
import { LoadingScreen } from '../ProductAdmin/Profile';
import * as actions from './actions';
import Engagement from 'components/Engagement';
import { Stack } from '@mui/material';

const VendorEngagementPage = ({ updatePending, isAdmin }) => {

  if (!isAdmin) return null
  
  return (
    <StyledProductAdmin fluid>
      {updatePending && <LoadingScreen><LoadingSpinner /></LoadingScreen>}

      <Stack spacing={4}>
        <Engagement />
      </Stack>


    </StyledProductAdmin>
  );
}

const StyledProductAdmin = styled.div`
  font-weight: normal;
  padding-top: 1rem;
`;

const mapStateToProps = state => {
  const { updatePending, product } = state.Product;
  return { updatePending, product };
};

export default compose(
  connect(
    mapStateToProps,
  ),
  withRouter,
)(VendorEngagementPage);
